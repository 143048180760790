export function symbolUtils(symbol: string) {
  const _symbol = symbol.toLowerCase();
  let __symbol = '';
  if (_symbol.indexOf('eth') > -1) {
    __symbol = 'eth';
  } else if (_symbol.indexOf('usdc') > -1) {
    __symbol = 'usdc';
  }

  return __symbol;
}
