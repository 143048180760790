import { CHAINKEY } from 'config/constants/chain_config';
import { LiquidityBridgeTokens } from './LiquidityBridgeTokens';

// ## arbitrum
// ModuleEnabler deployed to: 0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200
// module deployed to: 0x651BE2029a390bae194f3268D6fA3A034476e884
// router deployed to: 0xcd399c703ad32f6790c41d617D078b66DD0A4C02
// AVaultForAaveFactory deployed to: 0xBCe212EA0474F464488e963E793010205175f123
// aaETH deployed to: 0x603B5383d1F701C120D4A8E24F2DAccEF30b80b4
// aaUSDC deployed to: 0x99c192D657D3827A8BDe853aEcDa24cD41D53eb9
// AddressesProvider deployed to: 0xbEF7b01e1113B4946dAF6C1e8d01f3A9d4577197
// UOExecutorL2 deployed to: 0x5Af88505CF2cE57bb5e36816d7853A221F6Fc981
// LayerZeroUA deployed to: 0xB1543fA05fB09f6164A46F65656587a2d40ed35B

// ## optimism
// ModuleEnabler deployed to: 0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200
// module deployed to: 0xcd399c703ad32f6790c41d617D078b66DD0A4C02
// router deployed to: 0xB1543fA05fB09f6164A46F65656587a2d40ed35B
// AVaultForAaveFactory deployed to: 0xBCe212EA0474F464488e963E793010205175f123
// aaUSDC deployed to: 0x603B5383d1F701C120D4A8E24F2DAccEF30b80b4
// aaETH deployed to: 0x99c192d657d3827a8bde853aecda24cd41d53eb9
// AddressesProvider deployed to: 0x5Eca1457EF6531c6A0A4a9a4054789aCAe303dB6
// UOExecutorL2 deployed to: 0xB89AadF84f118D59D5770e6FEe9cF1b55244Ea8B
// LayerZeroUA deployed to: 0x2739c4ca9dE9824Ba5bA82157D1B9b37dDFf18B2

export const routerContractAddress = {
  [CHAINKEY.ARBITRUM_MAINNET]: '0xcd399c703ad32f6790c41d617D078b66DD0A4C02',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0xB1543fA05fB09f6164A46F65656587a2d40ed35B',
};
export const ModuleEnablerAddress = {
  [CHAINKEY.ARBITRUM_MAINNET]: '0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0x7fF612bAE2CE449Ce7cE544dfdfe37ffF2605200',
};

export const sigValidationModuleAddress = {
  [CHAINKEY.ARBITRUM_MAINNET]: '0x651BE2029a390bae194f3268D6fA3A034476e884',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0xcd399c703ad32f6790c41d617D078b66DD0A4C02',
};

export const uOExecutorL2Address = {
  [CHAINKEY.ARBITRUM_MAINNET]: '0x5Af88505CF2cE57bb5e36816d7853A221F6Fc981',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0xB89AadF84f118D59D5770e6FEe9cF1b55244Ea8B',
};

export const LayerZeroUAAddress = {
  [CHAINKEY.ARBITRUM_MAINNET]: '0xA65e15Eff98BB57c15935948A0dCA190651F1EF8',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0x5A6f79a1344dF47BF843281f5A458219B0e50457',
};
export const lzEndpointAddress = {
  [CHAINKEY.ARBITRUM_MAINNET]: '0x3c2269811836af69497e5f486a85d7316753cf62',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0x3c2269811836af69497e5f486a85d7316753cf62',
};

export const stargateRouterAddress = {
  // leave StargateConfig as '' if you want to deploy a router with deployments
  [CHAINKEY.ETH_MAINNET]: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
  [CHAINKEY.OPTIMISTIC_MAINNET]: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
  [CHAINKEY.BSC_MAINNET]: '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8',
  [CHAINKEY.POLYGON_MAINNET]: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
  [CHAINKEY.FANTOM_MAINNET]: '0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
  [CHAINKEY.ARBITRUM_MAINNET]: '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
  [CHAINKEY.AVALANCH_MAINNET]: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
  // TESTNETS
  [CHAINKEY.ETH_RINKEBY]: '0x82A0F5F531F9ce0df1DF5619f74a0d3fA31FF561',
  [CHAINKEY.BSC_TESTNET]: '0xbB0f1be1E9CE9cB27EA5b0c3a85B7cc3381d8176',
  // [CHAINKEY.Fuji]: "0x13093E05Eb890dfA6DacecBdE51d24DabAb2Faa1",
  // [CHAINKEY.Mumbai]: "0x817436a076060D158204d955E5403b6Ed0A5fac0",
  [CHAINKEY.ARBITRUM_TESTNET]: '0x6701D9802aDF674E524053bd44AA83ef253efc41',
  [CHAINKEY.OPTIMISTIC_TESTNET]: '0xCC68641528B948642bDE1729805d6cf1DECB0B00',
  [CHAINKEY.FANTOM_TESTNET]: '0xa73b0a56B29aD790595763e71505FCa2c1abb77f',
};

// WETH、USDC、USDT、[ATOKEN...]
export const BalancesAddresses = {
  [CHAINKEY.ARBITRUM_MAINNET]: {
    address: [
      LiquidityBridgeTokens.ETH[CHAINKEY.ARBITRUM_MAINNET],
      LiquidityBridgeTokens.USDC[CHAINKEY.ARBITRUM_MAINNET],
      LiquidityBridgeTokens.USDT[CHAINKEY.ARBITRUM_MAINNET],
    ],
    feeUSD: 0.5,
    gasLimit: 5,
  },
};
export const BalancesAmount = {
  [CHAINKEY.ARBITRUM_MAINNET]: {
    feeUSD: '0.1',
  },
  [CHAINKEY.OPTIMISTIC_MAINNET]: {
    feeUSD: '0.1',
  },
};
