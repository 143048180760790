import { CHAINKEY } from 'config/constants/chain_config';
import { routerContractAddress } from 'config/vault/address';
import { splitSignature } from 'ethers/lib/utils';
import { RPCApi } from 'server/RPCApi';
import { getMulticallAddress } from 'utils/addressHelpers';
import ABI from './abi/approve.json';
export async function getUserApprove({
  library,
  fromtokenaddress,
  sigValidationModuleAddress,
  account,
  fromchain,
  fromchainId,
  amountIn,
}: {
  library: any;
  fromtokenaddress: string;
  sigValidationModuleAddress: string;
  account: string;
  fromchain: CHAINKEY;
  fromchainId: number;
  amountIn: string;
}) {
  const rpcApi = new RPCApi({
    chainkey: fromchain,
  });
  const EIP712Domain = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
  ];
  const Permit = [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ];
  const [[name], [_nonce], [timestamp]] = await rpcApi.multicall(ABI, [
    {
      address: fromtokenaddress,
      name: 'name',
    },
    {
      address: sigValidationModuleAddress,
      name: 'nonces',
      params: [account],
    },
    {
      address: getMulticallAddress(fromchain),
      name: 'getCurrentBlockTimestamp',
    },
  ]);

  // console.log({ name: name, fromtokenaddress });
  const domain = {
    name: name,
    version: '1',
    chainId: fromchainId,
    verifyingContract: fromtokenaddress,
  };
  // const nonce = _nonce.toNumber();
  const deadline = timestamp.toNumber();

  const message = {
    owner: account,
    spender: routerContractAddress[fromchain],
    value: amountIn,
    nonce: _nonce.toHexString(),
    deadline: deadline + 60 * 60 * 10,
  };
  // console.log({
  //   types: {
  //     EIP712Domain,
  //     Permit,
  //   },
  //   domain,
  //   primaryType: 'Permit',
  //   message,
  // });
  const data = JSON.stringify({
    types: {
      EIP712Domain,
      Permit,
    },
    domain,
    primaryType: 'Permit',
    message,
  });
  const signature = await library.send('eth_signTypedData_v4', [account, data]).then(splitSignature);
  const { r, s, v } = signature;
  return {
    deadline: deadline + 60 * 60 * 10,
    v,
    r,
    s,
  };
}
