export const fetchHistoryMultiGet = async ({ account }) => {
  // console.log(111122, process.env.REACT_APP_GRAPH_API_PROFILE);
  const res = await fetch(process.env.REACT_APP_GRAPH_API_PROFILE + '/api/v1/multi/history?account=' + account, {
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  });
  const body = await res.json();
  return body.data;
};
