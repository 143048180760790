export const getTransactionListDetail = async ({ account, fromtx }) => {
  // console.log(111122, process.env.REACT_APP_GRAPH_API_PROFILE);
  const res = await fetch(
    `${process.env.REACT_APP_GRAPH_API_PROFILE}/api/v1/multi/history/detail?account=${account}&&fromtx=${fromtx}`,
    {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    },
  );
  const body = await res.json();
  return body.data;
};
