import { CHAINKEY } from 'config/constants/chain_config';
import {
  AutoRenewIcon,
  Button,
  connectorLocalStorageKey,
  ConnectorNames,
  Input,
  useMatchBreakpoints,
  useModal,
  useWalletModal,
} from '@my/ui';
import { useWeb3React } from '@web3-react/core';
import ArrowDown, { ArrowDownClickIcon } from 'components/svg/arrow_down';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IMultiTokenItem } from 'state/types';
import { useVault } from 'state/vault/hooks';
import { IVault } from 'state/vault/types';
import { getChainImage, getDappImage, getImageUrlFromToken } from 'utils';
import ArrowRight from 'components/svg/arrow_right';
import { chain_key_localstorage, getLocalStorage, setLocalStorage } from 'utils/localStorage';
import { useGlobalState } from 'state/global/hooks';
import { chosedTokenLocalStorageKey, chosedTokenStorageKey, ITradeEventType, ITradeType } from 'views/Trade/constants';
import SizedImg from 'views/Trade/components/sizedImg';
import ChoseModal from 'views/Trade/components/ChoseModal';
// import { LineStyled } from 'views/Trade/components/LineStyled';
// import LineType from 'views/Trade/components/LineType';
import { VaultMultiTradeStyled } from '../styled';
import { routePath } from 'config/constants/meta';
import { useTradeRouter } from 'views/Trade/hooks/get/getHooks';
import { setupNetwork } from 'config/wallet';
import { setChainKeyState } from 'state/global';
import { useAppDispatch } from 'state';
import { useContract } from 'wallet/getContract';
import { routerContractAddress } from 'config/vault/address';
import { tabText } from 'views/Trade/constants';
import { fetchVaultABIAmountMultiABI, routerContractABI } from 'config/vault/abi';
import { useGetChainAndToken } from 'views/Trade/hooks/useGetChainAndToken';
import { onPressCallDeposit } from 'views/Trade/hooks/onPressCallDeposit';
import { getDepositGasFee } from 'views/Trade/hooks/get/useDepositGasFee';
import { usePrice } from 'state/price/hooks';
import ShowStatusModal, { IShowStatusModal } from 'views/TransactionList/VaultMultiTransactionList/ShowStatusModal';
import { getUTCTime } from 'utils/getUTCTime';
import { updateHistory } from 'views/Trade/hooks/post/updateHistory';
import { useGetIsApprove } from 'views/Trade/hooks/get/getIsApprove';
import { onPressCallApprove } from 'views/Trade/hooks/onPressCallApprove';
import BigNumber from 'bignumber.js';
import { ERC20_ABI } from 'config/abi/erc20';
import useToast from 'hooks/useToast';
import { fetchMultiVaultFarmUserDataAsync, fetchVaultMultiTokenBalanceAsync } from 'state/vault/reducer';
import { useSafeContractState } from 'state/safeContract/hooks';
import { LineStyled } from 'views/Trade/components/LineStyled';
import LineType from 'views/Trade/components/LineType';
import { BIG_ZERO } from 'utils/bigNumber';
import useAuth from 'hooks/useAuth';

const VaultMultiTradeDeposit = () => {
  const { chainkey } = useGlobalState();
  const { account, library } = useWeb3React();
  const [gasfee, setGasFee] = useState('-');
  const [bridgeItem, setBridgeItem] = useState<IShowStatusModal>();
  const [showChainList, setShowChainList] = useState(false);
  const [showTokenList, setShowTokenList] = useState(false);
  const [value, setValue] = useState<string>('');
  const [fromchain, setFromChain] = useState<CHAINKEY>();
  const [tochain, settochain] = useState<CHAINKEY>();
  const [fromtoken, setFromToken] = useState<IMultiTokenItem>(getLocalStorage(chosedTokenStorageKey));
  const [toToken, setToToken] = useState<IMultiTokenItem>();
  const [choseModalTitle, setChoseModalTitle] = useState<string>('');
  const { multiTokenConfig, multiToken, chosedData, tradeConfig, accountBalanceInfo } = useVault();
  const { contract_address, vaultChain } = useParams<{ vaultChain: string; contract_address: string }>();
  const routerContract = useContract(routerContractAddress[fromchain], routerContractABI[fromchain]);
  const erc20Contract = useContract(fromtoken?.address, ERC20_ABI);

  const [collapsed, setCollapsed] = useState(false);
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { priceVsBusdMapMulti } = usePrice();
  const [isApprove, setIsApprove] = useState<boolean>(false);
  const { toastSuccess, toastError, toastWarning } = useToast();
  const [pendingTx, setPendingTx] = useState(false);
  useEffect(() => {
    setFromChain(chainkey);
  }, [chainkey]);
  useEffect(() => {
    if ([isXs, isSm, isMd].some(Boolean)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isXs, isSm, isMd]);

  const chosedVault: IVault = useMemo(() => {
    if (!chosedData) {
      return;
    }
    let _vaults: IVault[] = [];
    for (const chain of Object.keys(chosedData)) {
      const chainObj = chosedData[chain];
      if (chainObj.chain === vaultChain) {
        for (const dapp of chainObj.dapp) {
          _vaults = _vaults.concat(dapp.contract);
        }
      }
    }
    return _vaults.filter((v) => v.contractAddress === contract_address)[0];
  }, [chosedData, contract_address, vaultChain]);
  const {
    chainList,
    fromtokenList,
    toTokenList,
    tochain: _tochain,
    fromchain: _fromchain,
    toToken: _totoken,
    fromtoken: _fromtoken,
  } = useGetChainAndToken({
    multiToken,
    fromchain: fromchain,
    tochain: tochain,
    multiTokenConfig,
    account,
    chosedVault: chosedVault,
    accountBalanceInfo: accountBalanceInfo,
    vaultIsTo: true,
  });
  const wantAddressContract = useContract(chosedVault?.vault?.wantaddress, ERC20_ABI);
  const vaultContract = useContract(chosedVault?.contractAddress, fetchVaultABIAmountMultiABI);
  // const fromTokenContract = useContract(fromtoken?.address, ERC20_ABI);

  const {
    obj: { swap, bridge, tradetype },
  } = useTradeRouter({
    fromchainkey: fromchain,
    tochainkey: tochain,
    dappname: chosedVault?.dappname,
    setFromChain,
    settochain,
    tradeEventType: ITradeEventType.Event_1, // 1 deposit 2  avault withdraw  3 erc20 withdraw
  });
  useMemo(() => {
    if (_fromtoken && _totoken && _tochain && _fromchain) {
      // console.log({ _totoken, _tochain, fromchain });
      setFromToken(_fromtoken);
      setToToken(_totoken);
      settochain(_tochain);
      if (_fromchain !== fromchain) {
        setFromChain(_fromchain);
      }
    }
    // eslint-disable-next-line
  }, [_fromtoken, _totoken, _tochain, _fromchain]);
  const { swapConfig, bridgeConfig } = useMemo(() => {
    if (!tradeConfig || !Object.keys(tradeConfig).length || !swap || !bridge) {
      return {};
    }
    // console.log(tradeConfig, Object.keys(tradeConfig).length, swap, bridge);
    // console.log({ tradeConfig });
    const swapConfig = tradeConfig.swap[swap];
    const bridgeConfig = tradeConfig.bridge[bridge];
    return { swapConfig, bridgeConfig };
  }, [tradeConfig, swap, bridge]);
  const dispatch = useAppDispatch();
  const handleSelectMax = useCallback(() => {
    if (account && fromtoken) {
      setValue(fromtoken.balances[account.toLowerCase()]?.number || '0');
    }
  }, [setValue, fromtoken, account]);
  const endValue = useMemo(() => {
    if (!value || !fromtoken?.symbol) {
      return '';
    }
    // const size = fromtoken.symbol.indexOf('USD') > -1 ? 0.6 : 0.99;
    // const bg = new BigNumber(value).times(size);

    if (fromchain === tochain) {
      return value;
    }
    let bg = new BigNumber(value).minus(new BigNumber(value).times(0.0006));
    bg = bg.gt(BIG_ZERO) ? bg : BIG_ZERO;
    return Number(`${bg.toFixed(4, BigNumber.ROUND_DOWN)}`).toLocaleString('en-US', {
      maximumFractionDigits: 4,
    });
  }, [value, fromtoken?.symbol, fromchain, tochain]);
  useMemo(async () => {
    if (tochain && account && fromchain && toToken && value) {
      const gas = await getDepositGasFee({ tochain, account, fromchain, toToken, priceVsBusdMapMulti });
      setGasFee(gas);
    }
  }, [fromchain, tochain, toToken, account, value, priceVsBusdMapMulti]);
  const setBridgeItemCal = useCallback((item, isFlash?) => {
    if (!isFlash) {
      return;
    }
    setBridgeItem(item);
  }, []);
  const [onPresetModal] = useModal(
    <ShowStatusModal
      collapsed={collapsed}
      status={bridgeItem?.status}
      tradetype={bridgeItem?.tradetype as ITradeType}
      startvalue={bridgeItem?.startvalue}
      fromtoken={bridgeItem?.fromtoken}
      fromchain={fromchain as CHAINKEY}
      fromtokensymbol={bridgeItem?.fromtokensymbol}
      totokenaddress={bridgeItem?.totokenaddress}
      totokensymbol={bridgeItem?.totokensymbol}
      toaddressusdc={bridgeItem?.toaddressusdc}
      toaddressusdcsymbol={bridgeItem?.toaddressusdcsymbol}
      tochain={tochain as CHAINKEY}
      todappname={chosedVault?.dappname}
      label="Deposit"
      endvalue={bridgeItem?.endvalue}
      swapname={bridgeItem?.swapname}
      swapendvalue={bridgeItem?.swapendvalue}
      bridgekey={bridgeItem?.bridgekey}
      bridgename={bridgeItem?.bridgename}
      bridgeendvalue={bridgeItem?.bridgeendvalue}
      fromtx={bridgeItem?.fromtx}
      totx={bridgeItem?.totx}
      gastotalfee={bridgeItem?.gastotalfee}
      arrivaltotaltime={bridgeItem?.arrivaltotaltime}
      vaultaddress={bridgeItem?.vaultaddress}
      showConfirm={true}
      setBridgeItemCal={setBridgeItemCal}
    />,
    true,
    true,
    'ShowStatusModal111',
  );
  const isNotSetUp = useMemo(() => {
    return chainkey !== fromchain;

    // if (chainList && chainList.length && chainList.includes(chainkey)) {
    //   return false;
    // }
    // return true;
    // return (
    //   (!isApprove && chainkey === fromchain && fromchain !== tochain) ||
    //   (!isApprove && fromchain !== tochain && tochain === CHAINKEY.OPTIMISTIC_MAINNET)
    // );
  }, [chainkey, fromchain]);
  const { safeAddress } = useSafeContractState();
  const { login, logout } = useAuth({ chainkey });
  const { onPresentConnectModal } = useWalletModal(login, logout);
  const onPressDeposit = useCallback(async () => {
    setPendingTx(true);
    if (!account) {
      onPresentConnectModal();
      setPendingTx(false);
      return;
    }
    if (isNotSetUp) {
      const _chain = fromchain === tochain && tochain === CHAINKEY.OPTIMISTIC_MAINNET ? tochain : fromchain;
      const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
      const hasSetUp = await setupNetwork(connectorId, _chain);
      if (hasSetUp) {
        setLocalStorage(chain_key_localstorage, _chain);
        dispatch(setChainKeyState(_chain));
        setFromChain(_chain);
      }
      setPendingTx(false);
      return;
    }
    if (!isApprove) {
      // localchain
      const res = await onPressCallApprove({
        library: library,
        routerContract: routerContract,
        fromchain: fromchain,
        fromtoken: fromtoken,
        toToken: toToken,
        tochain: tochain,
        value: value,
        account,
        wantAddressContract: wantAddressContract,
        erc20Contract: erc20Contract,
      });
      if (res?.txHash) {
        setIsApprove(true);
      }
      setPendingTx(false);
      return;
    }
    if (value) {
      if (new BigNumber(value).eq('0')) {
        toastError('Error', 'Wops, you entered 0 ');
        setPendingTx(false);
        return;
      }
      if (new BigNumber(value).gt(fromtoken.balances[account.toLowerCase()].number)) {
        toastError('Error', 'Insufficient balance');
        setPendingTx(false);
        return;
      }
      if (isApprove) {
        const result = await onPressCallDeposit({
          library: library,
          routerContract: routerContract,
          fromchain: fromchain,
          fromtoken: fromtoken,
          toToken: toToken,
          tochain: tochain,
          value: value,
          account,
          vaultContract,
          isApprove: isApprove,
          multiToken: multiToken,
          safeAddress,
          wantaddress: chosedVault.vault.wantaddress,
        });
        if (result && result.isSuccess) {
          const { isCrossChain, txHash } = result;
          const time = getUTCTime();
          const item = {
            ...bridgeItem,
            createatutctime: time,
            updateutctime: time,
            vaulttype: chosedVault.vaulttype,
            startvalue: value,
            status: isCrossChain ? 0 : 999,
            token0address: chosedVault.vault.token0address,
            token1address: chosedVault.vault.token1address,
            fromtx: txHash,
            totx: fromchain === tochain ? txHash : '',
            historytype: 1, // 1 deposit  2  withdraw
          };
          setValue('');
          setBridgeItem(item);
          updateHistory({ item, account });
          onPresetModal();
          dispatch(
            fetchVaultMultiTokenBalanceAsync({
              account,
              multiTokenConfig,
              safeAddress,
            }),
          );
          dispatch(
            fetchMultiVaultFarmUserDataAsync({
              account,
              vaults: chosedData,
              safeAddress,
              priceVsBusdMapMulti,
            }),
          );
          toastSuccess(`Deposit!`, `Your ${toToken.symbol} deposit!`);
        } else {
          toastError('Error', result?.message || `Your ${toToken.symbol} deposit failed!`);
        }
      }
    } else {
      toastWarning('Error', 'Please Enter amount');
    }
    setPendingTx(false);
  }, [
    chosedData,
    priceVsBusdMapMulti,
    erc20Contract,
    safeAddress,
    isNotSetUp,
    multiToken,
    isApprove,
    account,
    fromtoken,
    value,
    dispatch,
    routerContract,
    wantAddressContract,
    vaultContract,
    fromchain,
    tochain,
    library,
    toToken,
    chosedVault,
    bridgeItem,
    onPresetModal,
    multiTokenConfig,
    toastError,
    toastSuccess,
    toastWarning,
    onPresentConnectModal,
  ]);

  const isGetApprove = useGetIsApprove({
    fromchain: fromchain,
    tochain: tochain,
    routeraddress: routerContractAddress,
    wantAddress: chosedVault?.vault?.wantaddress,
    fromTokenAddress: fromtoken?.address,
    account,
    contractAddress: toToken?.address,
    setIsApprove: setIsApprove,
  });
  const onPressSetChosedToken = useCallback(
    (val: IMultiTokenItem) => {
      setLocalStorage(chosedTokenLocalStorageKey, {
        [fromchain]: val.symbol,
      });
      setFromToken(val);
    },
    [fromchain],
  );

  const onPressSetFromChain = useCallback((val) => {
    setLocalStorage(chosedTokenStorageKey, val);
    setFromChain(val);
  }, []);
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setValue(e.currentTarget.value.replace(/,/g, '.'));
      }
    },
    [setValue],
  );
  const btnDisAbled = useMemo(() => {
    let bol = false;
    // console.log({ isGetApprove, isApprove });
    if (!isGetApprove || (isGetApprove && !isApprove)) {
      // console.log({ bol });
      return bol;
    } else {
      bol = !account || (!value && !isNotSetUp);
    }
    return bol;
  }, [account, value, isApprove, isGetApprove, isNotSetUp]);
  // console.log({ btnDisAbled });
  if (
    // !account ||
    !chosedVault ||
    !chainList ||
    !toTokenList ||
    !toToken ||
    !fromtoken ||
    !swapConfig ||
    !bridgeConfig
  ) {
    return null;
  }
  return (
    <VaultMultiTradeStyled>
      <div className="trade">
        <div className="trade_inner">
          <div className="tab_header">
            <p className="on">{tabText[0]}</p>
            <Link to={routePath.vault_trade_withdraw.pathPre + '/' + chosedVault.chainkey + '/' + contract_address}>
              {tabText[1]}
            </Link>
          </div>
          <div className="tab_body">
            <div className="tab_body_chain">
              <div className="fl">
                <h4>Transfer from</h4>
                <div
                  className="flex cursor"
                  onClick={() => {
                    setChoseModalTitle('Chain');
                    setShowChainList(true);
                  }}
                >
                  <SizedImg src={getChainImage(fromchain)} alt={fromchain} />
                  <p>{fromchain}</p>
                  <ArrowDownClickIcon />
                </div>
              </div>
              {collapsed ? null : (
                <div className="side">
                  <ArrowRight />
                </div>
              )}
              <div className="fr">
                <h4>Transfer to</h4>
                <div className="flex">
                  <SizedImg src={getChainImage(chosedVault.chainkey)} alt={chosedVault.chainkey} />
                  <p>{chosedVault.chainkey}</p>
                </div>
              </div>
              {collapsed ? <ArrowDown className="arrow_down_" /> : null}
            </div>
            <div className="form">
              <div className="top">
                <div className="text">
                  <h5>You Deposit</h5>
                  <div className="fr">
                    <p>
                      Balance: {!account ? '0' : (fromtoken?.balances || {})[account.toLowerCase()]?.localNumber || '0'}
                      {fromtoken.symbol || ''}
                    </p>

                    <Button variant="text" onClick={handleSelectMax}>
                      Max
                    </Button>
                  </div>
                </div>
                <div className="input">
                  <div
                    className="flex"
                    // className={`flex ${fromchain !== tochain ? 'cursor' : ''}`}
                    // onClick={() => {
                    //   if (fromchain !== tochain) {
                    //     setChoseModalTitle('Token');
                    //     setShowTokenList(true);
                    //   }
                    // }}
                  >
                    <SizedImg src={getImageUrlFromToken(fromtoken.address, fromchain)} alt={fromtoken.address} />
                    <p>{fromtoken.symbol}</p>
                    {/* {fromchain !== tochain ? <ArrowDownClickIcon /> : null} */}
                  </div>
                  <Input
                    placeholder="0.00"
                    pattern={`^[0-9]*[.,]?[0-9]{0,8}$`}
                    inputMode="decimal"
                    value={value}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="text">
                  <h5>You Receive</h5>
                  <div className="fr ">
                    <SizedImg src={getDappImage(chosedVault?.dappname)} alt={toToken.address} />
                    {/* <SizedImg src={getChainImage(tochain)} alt={tochain} /> */}
                    {/* <p>{tochain}</p> */}
                    <p>{chosedVault?.dappname}</p>
                  </div>
                </div>
                <div className="input">
                  <div className="flex">
                    <SizedImg src={getImageUrlFromToken(toToken.address, chosedVault.chainkey)} alt={toToken.address} />
                    <p>{toToken.symbol}</p>
                  </div>
                  <h4>≈{endValue}</h4>
                </div>
              </div>
              <ArrowDown />
            </div>
            <Button
              className="big_btn"
              disabled={btnDisAbled}
              onClick={onPressDeposit}
              endIcon={pendingTx ? <AutoRenewIcon spin color="#fff" /> : null}
            >
              {!account
                ? 'Connect Wallet'
                : !isNotSetUp
                ? isApprove
                  ? 'Deposit'
                  : 'Approve'
                : `Switch wallet to ${fromchain}`}
            </Button>
          </div>
          {showChainList || showTokenList ? (
            <ChoseModal
              accountAddress={account}
              chosedToken={fromtoken}
              showChainList={showChainList}
              setShowChainList={setShowChainList}
              chainList={chainList}
              setChosedChain={onPressSetFromChain}
              showTokenList={showTokenList}
              setShowTokenList={setShowTokenList}
              tokenList={fromtokenList}
              // tokenList={toTokenList}
              setChosedToken={onPressSetChosedToken}
              chosedChain={fromchain}
              title={choseModalTitle}
            />
          ) : null}
        </div>
        {account ? (
          value ? null : (
            <p className="warn">Please select the parameters for your desired transfer and enter an amount.</p>
          )
        ) : (
          <p className="warn">First, you need to connect wallet!</p>
        )}
      </div>
      {value && account ? (
        <LineStyled className="line" backgroundColor="#161528">
          <div className="card">
            <LineType
              collapsed={collapsed}
              value={value}
              toTokenList={toTokenList}
              fromchain={fromchain}
              fromtoken={fromtoken}
              // chosedVault={chosedVault}
              swapConfig={swapConfig}
              bridgeConfig={bridgeConfig}
              tradetype={tradetype}
              label={'Deposit'}
              totokenaddress={toToken.address}
              totokensymbol={toToken.symbol}
              tochain={tochain}
              gasfee={gasfee}
              bridgeItem={bridgeItem}
              setBridgeItemCal={setBridgeItemCal}
              todappname={chosedVault.dappname}
            />
          </div>
        </LineStyled>
      ) : null}
    </VaultMultiTradeStyled>
  );
};
export default memo(VaultMultiTradeDeposit);
