import { CHAINKEY } from 'config/constants/chain_config';
import { AutoRenewIcon, Button, Input, useMatchBreakpoints, useModal } from '@my/ui';
import { useWeb3React } from '@web3-react/core';
import ArrowDown, { ArrowDownClickIcon } from 'components/svg/arrow_down';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMultiTokenItem } from 'state/types';
import { useVault } from 'state/vault/hooks';
import { getChainImage, getImageUrlFromToken } from 'utils';
import { setLocalStorage } from 'utils/localStorage';
import { ContractAccountInfo } from 'components/SideMenu/UserWidget/Asset';
import { useGlobalState } from 'state/global/hooks';
import { useAppDispatch } from 'state';
import ArrowRight from 'components/svg/arrow_right';
import { useTradeRouter } from 'views/Trade/hooks/get/getHooks';
import { chosedTokenLocalStorageKey, chosedTokenStorageKey, ITradeEventType, ITradeType } from 'views/Trade/constants';
import { VaultMultiTradeStyled } from '../styled';
import SizedImg from 'views/Trade/components/sizedImg';
import ChoseModal from 'views/Trade/components/ChoseModal';
import { LineStyled } from 'views/Trade/components/LineStyled';
import LineType from 'views/Trade/components/LineType';
import { useGetChainAndToken } from 'views/Trade/hooks/useGetChainAndToken';
import { onPressCallWithdrawERC20 } from 'views/Trade/hooks/onPressCallWithdraw';
import { usePrice } from 'state/price/hooks';
import { useSafeContractState } from 'state/safeContract/hooks';
import { BalancesAmount, LayerZeroUAAddress, lzEndpointAddress } from 'config/vault/address';
import ShowStatusModal, { IShowStatusModal } from 'views/TransactionList/VaultMultiTransactionList/ShowStatusModal';
import { getUTCTime } from 'utils/getUTCTime';
import { fetchMultiVaultFarmUserDataAsync, fetchVaultMultiTokenBalanceAsync } from 'state/vault/reducer';
import { updateHistory } from 'views/Trade/hooks/post/updateHistory';
import useToast from 'hooks/useToast';
import BigNumber from 'bignumber.js';
import { BIG_ZERO } from 'utils/bigNumber';
import { symbolUtils } from 'utils/symbolFormmat';
import { useContract } from 'wallet/getContract';
import { LayerZeroUAABI, lzEndpointABI } from 'config/vault/abi';

const TradeWithdraw = () => {
  const { account, library } = useWeb3React();
  const [showChainList, setShowChainList] = useState(false);
  const [showTokenList, setShowTokenList] = useState(false);
  const [value, setValue] = useState<string>('');
  const {
    safeContractAddress,
    fromchain: fromchainParams,
    fromtoken: fromtokenParams,
  } = useParams<{ safeContractAddress: string; fromchain: string; fromtoken: string }>();
  const { priceVsBusdMapMulti } = usePrice();
  const { chainkey } = useGlobalState();
  const [fromchain, setFromChain] = useState<CHAINKEY>(fromchainParams as CHAINKEY);
  const [fromtoken, setFromToken] = useState<IMultiTokenItem>();
  const [toToken, setToToken] = useState<IMultiTokenItem>();
  const [choseModalTitle, setChoseModalTitle] = useState<string>('');
  const { multiTokenConfig, chosedData, multiToken, tradeConfig } = useVault();
  const [tochain, settochain] = useState<CHAINKEY>();
  const [collapsed, setCollapsed] = useState(false);
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { safeAddress } = useSafeContractState();
  const [bridgeItem, setBridgeItem] = useState<IShowStatusModal>();
  const dispatch = useAppDispatch();
  const [pendingTx, setPendingTx] = useState(false);
  // console.log({ fromtokenParams });
  useEffect(() => {
    setFromChain(fromchainParams as CHAINKEY);
    settochain(chainkey);
  }, [fromchain, tochain, fromchainParams, chainkey]);
  useEffect(() => {
    if ([isXs, isSm, isMd].some(Boolean)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isXs, isSm, isMd]);
  const { toastSuccess, toastError, toastWarning } = useToast();

  const {
    chainList,
    toTokenList,
    fromtokenList,
    fromtoken: _fromtoken,
    toToken: _toToken,
  } = useGetChainAndToken({
    multiToken,
    tochain: tochain,
    fromchain: fromchain,
    multiTokenConfig,
    account,
    chosedTokenAddress: fromtokenParams,
  });
  // console.log({ BalancesAmount, fromchain });
  const gasfee = BalancesAmount[fromchain].feeUSD;
  // console.log({ fromchain, tochain });
  const {
    obj: { swap, bridge, tradetype },
  } = useTradeRouter({
    fromchainkey: fromchain,
    tochainkey: tochain,
    dappname: fromtokenParams,
    setFromChain,
    settochain,
    tradeEventType: ITradeEventType.Event_3, // 1 deposit 2  avault withdraw  3 erc20 withdraw
  });
  useEffect(() => {
    if (_toToken && _fromtoken) {
      setFromToken(_fromtoken);
      setToToken(_toToken);
    }
  }, [_fromtoken, _toToken]);
  const setBridgeItemCal = useCallback((item, isFlash?) => {
    if (!isFlash) {
      return;
    }
    setBridgeItem(item);
  }, []);
  const balanceKey = useMemo(() => {
    if (fromchain && tochain && account && safeAddress && safeAddress[account] && safeAddress[account][fromchain]) {
      return safeAddress[account][fromchain].toLocaleLowerCase();
    }
    return '';
  }, [account, fromchain, safeAddress, tochain]);

  const endValue = useMemo(() => {
    if (!value || !fromtoken?.symbol) {
      return '';
    }
    // const _b = fromchain === tochain ? 0 : BalancesAmount[fromchain].feeUSD;
    // let bg = new BigNumber(value).times(0.99).minus(_b);
    // let bg = new BigNumber(value).times(0.99);
    if (fromchain === tochain) {
      return value;
    }
    const fromtokensymbol = symbolUtils(fromtoken.symbol);
    const fromtokenUSD = priceVsBusdMapMulti[fromtokensymbol];
    const gasTokenAmount = new BigNumber('0.13')
      .dividedBy(fromtokenUSD)
      // .times(BIG_TEN.pow(fromtoken.decimals))
      .toFixed(4);

    let bg = new BigNumber(value).minus(new BigNumber(value).times(0.001).plus(gasTokenAmount));
    bg = bg.gt(BIG_ZERO) ? bg : BIG_ZERO;
    return Number(`${bg.toFixed(4, BigNumber.ROUND_DOWN)}`).toLocaleString('en-US', {
      maximumFractionDigits: 4,
    });
  }, [value, fromchain, tochain, fromtoken?.symbol, priceVsBusdMapMulti]);
  const [onPresetModal] = useModal(
    <ShowStatusModal
      collapsed={collapsed}
      status={bridgeItem?.status}
      tradetype={bridgeItem?.tradetype as ITradeType}
      startvalue={bridgeItem?.startvalue}
      fromtoken={bridgeItem?.fromtoken}
      fromchain={fromchain as CHAINKEY}
      fromtokensymbol={bridgeItem?.fromtokensymbol}
      totokenaddress={bridgeItem?.totokenaddress}
      totokensymbol={bridgeItem?.totokensymbol}
      toaddressusdc={bridgeItem?.toaddressusdc}
      toaddressusdcsymbol={bridgeItem?.toaddressusdcsymbol}
      tochain={tochain as CHAINKEY}
      todappname={bridgeItem?.todappname}
      label="Withdraw"
      endvalue={bridgeItem?.endvalue}
      swapname={bridgeItem?.swapname}
      swapendvalue={bridgeItem?.swapendvalue}
      bridgekey={bridgeItem?.bridgekey}
      bridgename={bridgeItem?.bridgename}
      bridgeendvalue={bridgeItem?.bridgeendvalue}
      fromtx={bridgeItem?.fromtx}
      totx={bridgeItem?.totx}
      gastotalfee={bridgeItem?.gastotalfee}
      arrivaltotaltime={bridgeItem?.arrivaltotaltime}
      vaultaddress={bridgeItem?.vaultaddress}
      showConfirm={true}
      setBridgeItemCal={setBridgeItemCal}
      chainkeytx={bridgeItem?.chainkeytx}
      chainkey={bridgeItem?.chainkey as CHAINKEY}
    />,
    true,
    true,
    'ShowStatusModal222',
  );
  const { swapConfig, bridgeConfig } = useMemo(() => {
    // console.log({ tradeConfig }, swap, bridge);
    if (!tradeConfig || !Object.keys(tradeConfig).length || !swap || !bridge) {
      return {};
    }
    const swapConfig = tradeConfig.swap[swap];
    const bridgeConfig = tradeConfig.bridge[bridge];
    return { swapConfig, bridgeConfig };
  }, [tradeConfig, swap, bridge]);
  const historytype = useMemo(() => {
    if (!account) {
      return 0;
    }
    // parent
    if (balanceKey === account.toLowerCase()) {
      return 2; // parent withdraw
    } else {
      // child
      if (fromchain === tochain) {
        if (chainkey === fromchain) {
          return 3; // child local withdraw   end tx
        } else {
          return 4; // transaction tx  -> layerzero api -> dst tx(end tx)
        }
      } else {
        if (chainkey === fromchain) {
          return 5; // transaction tx -> hop api -> tochain tx(end tx)
        } else {
          return 6; // transaction tx -> layerzero api -> dst tx(fromchain tx) -> hop api -> tochain tx(end tx)
        }
      }
    }
  }, [account, balanceKey, chainkey, fromchain, tochain]);
  // const erc20Contract = useContract(fromtoken?.address, ERC20_ABI);
  const LayerZeroUAContract = useContract(LayerZeroUAAddress[chainkey], LayerZeroUAABI);
  const lzEndpointContract = useContract(lzEndpointAddress[chainkey], lzEndpointABI);
  const handleSelectMax = useCallback(() => {
    setValue(fromtoken.balances[balanceKey]?.number || '0');
  }, [setValue, fromtoken, balanceKey]);
  const onPressWithdraw = useCallback(async () => {
    setPendingTx(true);
    if (value && endValue !== '0') {
      const result = await onPressCallWithdrawERC20({
        chainkey,
        safeAddress,
        fromchain,
        fromtoken,
        toToken,
        tochain,
        value,
        library,
        account,
        priceVsBusdMapMulti,
        fromtokenList: fromtokenList,
        LayerZeroUAContract,
        lzEndpointContract,
      });
      if (result && result.isSuccess) {
        const { txHash } = result;
        const time = getUTCTime();
        let fromtx = txHash;
        let chainkeytx = '';
        let totx = '';
        let status = 999;
        if (historytype === 2 || historytype === 3) {
          fromtx = txHash;
          totx = txHash;
        } else {
          fromtx = '';
          status = 0;
        }
        if (historytype === 4 || historytype === 6) {
          chainkeytx = txHash;
        }
        if (historytype === 5) {
          fromtx = txHash;
        }
        const item = {
          ...bridgeItem,
          createatutctime: time,
          updateutctime: time,
          status: status,
          startvalue: value,
          fromtx: fromtx,
          totx: totx,
          historytype: historytype, // 1 deposit  2  withdraw
          chainkey: chainkey,
          chainkeytx: chainkeytx,
        };
        setValue('');
        setBridgeItem(item);
        updateHistory({ item, account });
        onPresetModal();
        dispatch(
          fetchVaultMultiTokenBalanceAsync({
            account,
            multiTokenConfig,
            safeAddress,
          }),
        );
        dispatch(
          fetchMultiVaultFarmUserDataAsync({
            account,
            vaults: chosedData,
            safeAddress,
            priceVsBusdMapMulti,
          }),
        );
        toastSuccess(`Withdraw!`, `Your ${toToken.symbol} withdrawing!`);
      } else {
        toastError('Error', result?.message || `Your ${toToken.symbol} deposit failed!`);
      }
    } else {
      toastWarning('Error', 'Please Enter amount or amount is too lower');
    }
    setPendingTx(false);
  }, [
    chainkey,
    historytype,
    chosedData,
    endValue,
    tochain,
    dispatch,
    account,
    fromchain,
    fromtoken,
    library,
    toToken,
    value,
    priceVsBusdMapMulti,
    safeAddress,
    onPresetModal,
    bridgeItem,
    multiTokenConfig,
    toastError,
    toastSuccess,
    toastWarning,
    fromtokenList,
    lzEndpointContract,
    LayerZeroUAContract,
  ]);

  const onPressSetToToken = useCallback(
    (val: IMultiTokenItem) => {
      setLocalStorage(chosedTokenLocalStorageKey, {
        [tochain]: val.symbol,
      });
      setToToken(val);
    },
    [tochain],
  );

  const onPressSettochain = useCallback((val) => {
    setLocalStorage(chosedTokenStorageKey, val);
    settochain(val);
  }, []);
  // console.log({ chainList, fromtoken, toToken, toTokenList, swapConfig, bridgeConfig });
  if (!chainList || !fromtoken || !toToken || !toTokenList || !swapConfig || !bridgeConfig) {
    return null;
  }

  return (
    <VaultMultiTradeStyled className="trade_withdraw">
      <div className="trade">
        <div className="trade_inner">
          {safeAddress ? (
            <ContractAccountInfo
              safeAddress={safeAddress}
              account={account}
              chosedChain={fromchain}
              safeContractAddress={safeContractAddress}
              className="contract_account_info"
            />
          ) : null}
          <div className="trade_inner_inner">
            <h3>Withdraw</h3>
            <div className="tab_body">
              <div className="tab_body_chain">
                <div className="fl">
                  <h4>Transfer from</h4>
                  <div className="flex">
                    <SizedImg src={getChainImage(fromchain)} alt={fromchain} />
                    <p>{fromchain}</p>
                  </div>
                </div>
                {collapsed ? null : (
                  <div className="side">
                    <ArrowRight />
                  </div>
                )}
                <div className="fr">
                  <h4>Transfer to</h4>
                  <div
                    className="flex cursor"
                    onClick={() => {
                      setChoseModalTitle('Chain');
                      setShowChainList(true);
                    }}
                  >
                    <SizedImg src={getChainImage(tochain)} alt={tochain} />
                    <p>{tochain}</p>
                    <ArrowDownClickIcon />
                  </div>
                </div>
                {collapsed ? <ArrowDown className="arrow_down_" /> : null}
              </div>
              <div className="form">
                <div className="top">
                  <div className="text">
                    <h5>You Withdraw</h5>
                    <div className="fr">
                      <p>
                        Balance: {(fromtoken?.balances || {})[balanceKey]?.localNumber || '0'}
                        {fromtoken?.symbol || ''}
                      </p>

                      <Button variant="text" onClick={handleSelectMax}>
                        Max
                      </Button>
                    </div>
                  </div>
                  <div className="input">
                    <div className="flex">
                      <SizedImg src={getImageUrlFromToken(fromtoken?.address, fromchain)} alt={fromtoken.address} />
                      <p>{fromtoken.symbol}</p>
                    </div>
                    <Input placeholder="0.00" value={value} onChange={(e) => setValue(e.target.value)} />
                  </div>
                </div>
                <div className="bottom">
                  <div className="text">
                    <h5>You Receive</h5>
                  </div>
                  <div className="input">
                    <div
                      className="flex"
                      // className="flex cursor"
                      // onClick={() => {
                      //   setChoseModalTitle('Token');
                      //   setShowTokenList(true);
                      // }}
                    >
                      <SizedImg src={getImageUrlFromToken(toToken.address, tochain)} alt={toToken.address} />
                      <p>{toToken.symbol}</p>
                      {/* <ArrowDownClickIcon /> */}
                    </div>
                    <h4>≈{endValue}</h4>
                  </div>
                </div>
                <ArrowDown />
              </div>
              <Button
                className="big_btn"
                disabled={!value && chainkey === tochain}
                onClick={onPressWithdraw}
                endIcon={pendingTx ? <AutoRenewIcon spin color="#fff" /> : null}
              >
                Withdraw
              </Button>
            </div>
            {showChainList || showTokenList ? (
              <ChoseModal
                accountAddress={safeContractAddress}
                chosedToken={toToken}
                showChainList={showChainList}
                setShowChainList={setShowChainList}
                chainList={chainList}
                setChosedChain={onPressSettochain}
                showTokenList={showTokenList}
                setShowTokenList={setShowTokenList}
                tokenList={toTokenList}
                setChosedToken={onPressSetToToken}
                chosedChain={tochain}
                title={choseModalTitle}
              />
            ) : null}
          </div>
        </div>
        {account ? (
          value ? null : (
            <p className="warn">Please select the parameters for your desired transfer and enter an amount.</p>
          )
        ) : (
          <p className="warn">First, you need to connect wallet!</p>
        )}
      </div>
      {value && account ? (
        <LineStyled className="line" backgroundColor="#161528">
          <div className="card">
            <LineType
              collapsed={collapsed}
              value={value}
              toTokenList={toTokenList}
              fromchain={fromchain}
              fromtoken={fromtoken}
              // chosedVault={chosedVault}
              swapConfig={swapConfig}
              bridgeConfig={bridgeConfig}
              tradetype={tradetype}
              label={'Withdraw'}
              tochain={tochain}
              totokenaddress={toToken.address}
              totokensymbol={toToken.symbol}
              gasfee={gasfee}
              bridgeItem={bridgeItem}
              setBridgeItemCal={setBridgeItemCal}
              // todappname={todappname}
            />
          </div>
        </LineStyled>
      ) : null}
    </VaultMultiTradeStyled>
  );
};
export default memo(TradeWithdraw);
