import { CHAINKEY } from 'config/constants/chain_config';
export enum IDAppName {
  kaco = 'Kacoswap',
  starlay = 'Starlay',
  arthswap = 'Arthswap',
}
export enum ISwapLink {
  kaco = 'https://shiden.kaco.finance',
  starlay = 'https://starlay.finance/app',
  arthswap = 'https://app.arthswap.org/#/swap',
}

export enum IABIType {
  AVaultPCS = 'AVaultPCS',
  AVaultForArthswapFarm = 'AVaultForArthswapFarm',
  AVaultForStarlay = 'AVaultForStarlay',
  AVaultForAAVE = 'AVaultForAAVE',
}

// export const VaultType = ['All', 'Event', 'Arthswap', 'Starlay'];
export const VaultType = [
  {
    name: 'All',
    key: 0,
  },
  {
    name: 'Stablecoin',
    key: 3,
  },
  {
    name: 'Single',
    key: 1,
  },
  {
    name: 'Lp token',
    key: 2,
  },
];
export enum IVaultType {
  all = 0,
  single = 1,
  lp_token = 2,
  stablecoin = 3,
}
export enum IEventType {
  normal = 0,
  active = 1,
  addNew = 2,
}
export interface IVaultConfigItem {
  vaulttype: IVaultType; // 1 stablecoin 2 single 3 lp_token
  eventtype: IEventType; // 0 normal  1 active   2 addNew
  contractAddress: string;
  chainkey: CHAINKEY;
  dappname: IDAppName;
  abitype: IABIType;
  swaplink: string;
  farm?: IVaultFarm;
  vault?: IVaultComp;
}
export interface IVaultFarm {
  // abi
  pid: number;
  lpSymbol: string;
  lpAddresses: string;
  token: string;
  quoteToken: string;
  tokenDecimals: number;
  quoteTokenDecimals: number;
  // lpAddressDecimals: number;
  // tokenAmountMc?: string;
  // quoteTokenAmountMc?: string;
  // tokenAmountTotal?: string;
  // quoteTokenAmountTotal?: string;
  // lpTotalInQuoteToken?: string;
  // lpTotalSupply?: string;
  // tokenPriceVsQuote?: string;
  // poolWeight?: string;
  // multiplier?: string;
  // calculate
  // apr?: string;
  // apy?: string;
  // farmApr?: string;
  // baseApy?: string;
  // feeApr?: string;
  // rewardApy?: string;
  // lpRewardsApr?: string;
  // liquidity?: string;
  // lpTokenPrice?: string;
  userData?: Record<string, IVaultUserData>;
}
export interface IVaultUserData {
  index?: number;
  vaultAccount?: string;
  account: string;
  allowance: string;
  stakingTokenBalance: string;
  // stakedBalance: string;
  // pendingReward: string;
  avaultaddressBalance: string;
}
export interface IVaultComp {
  // abi
  wantaddresssymbol: string;
  vaultsymbol: string;
  name: string;
  avaaddress: string;
  // dappname: IDAppName;
  wantaddress: string;
  wantaddressdecimals?: number;
  earnedaddress: string;
  earnedaddresssymbol: string;
  wantLockedTotal?: string;
  scale?: string;
  totalSupply?: string;
  decimals: number;
  isbalance?: boolean;
  tvl?: string;
  // calculate
  lpToCLpRate?: string;
  masterChef?: string;
  token0address?: string; // if single Token  token0 =  single Token
  token1address?: string;
  total_apr?: string;
  total_apy?: string;
  base_apr?: string;
  base_apy?: string;
  reward_apr?: string;
  reward_apy?: string;
}
export interface IVault extends IVaultConfigItem {
  vault: IVaultComp;
  farm: IVaultFarm;
  isLoading: boolean;
}
