import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'state';
import { usePrice } from 'state/price/hooks';
import { useSafeContractState } from 'state/safeContract/hooks';
import { useVault } from 'state/vault/hooks';
import { fetchMultiVaultFarmUserDataAsync, fetchVaultMultiTokenBalanceAsync } from 'state/vault/reducer';
import { getTransactionListDetail } from './get/getTransactionListDetail';

export const useGetToHx = ({
  status,
  fromtx,
  chainkeytx,
  totx,
}: {
  status: string;
  fromtx: string;
  chainkeytx: string;
  totx: string;
}) => {
  const { account } = useWeb3React();
  const [_totx, setTotx] = useState('');
  const [_status, setStatus] = useState('');
  const [_fromtx, setFromTx] = useState('');
  const { safeAddress } = useSafeContractState();
  const dispatch = useAppDispatch();
  const { multiTokenConfig, chosedData } = useVault();
  const { priceVsBusdMapMulti } = usePrice();
  useEffect(() => {
    let interval = null;

    if ((fromtx || chainkeytx) && status !== '1000' && !totx) {
      interval = setInterval(async () => {
        const _detail = await getTransactionListDetail({
          account,
          fromtx: fromtx || chainkeytx,
        });
        if (_detail && _detail.fromtx) {
          if (_detail.fromtx && _detail.fromtx === 'FAILED') {
            setFromTx('');
            setStatus('1000');
            clearInterval(interval);
          } else {
            setFromTx(_detail.fromtx);
          }
        }
        if (_detail && _detail.totx) {
          setTotx(_detail.totx);
          setStatus('999');
          if (dispatch && account && multiTokenConfig) {
            dispatch(
              fetchVaultMultiTokenBalanceAsync({
                account,
                safeAddress,
                multiTokenConfig,
              }),
            );
            dispatch(
              fetchMultiVaultFarmUserDataAsync({
                account,
                vaults: chosedData,
                safeAddress,
                priceVsBusdMapMulti,
              }),
            );
          }
          clearInterval(interval);
        }
      }, 10000);
    }
    return () => (interval ? clearInterval(interval) : null);
  }, [
    chosedData,
    status,
    chainkeytx,
    priceVsBusdMapMulti,
    fromtx,
    account,
    totx,
    dispatch,
    multiTokenConfig,
    safeAddress,
  ]);
  return { _totx, _fromtx, _status };
};
