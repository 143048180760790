import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHAINKEY } from 'config/constants/chain_config';
import { VaultMatadateType, VaultState } from 'state/types';
import vaultExtraReducers from './reducer';
import { IVault } from './types';
import { getVaultDataByChosedData } from './utils';

export const initialState: VaultState = {
  chosedChainAndDAppInit: true,
  multiToken: null,
  multiTokenConfig: null,
  chosedChainAndDApp: [
    {
      chain: CHAINKEY.ASTR_MAINNET,
      // 'Starlay',
      dapp: ['Arthswap'],
      // dapp: ['Starlay', 'Arthswap'],
    },
  ],
  vaultsConfig: null,
  chosedData: null,
  tvlTotal: '',
  isUserLoaded: false,
  userDataLoaded: false,
  tradeConfig: null,
  accountBalanceInfo: null,
  fetchVaultMultiTokenBalanceAsyncLoading: false,
  fetchVaultMultiTokenAsyncLoading: false,
};

export const vaultSlice = createSlice({
  name: 'Vault',
  initialState,
  reducers: {
    changeStateValue: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state[key] = value;
    },
    changeChosedChainAndDAppValue: (state, action) => {
      // todo
      const value = action.payload.value;
      const chosedChainAndDApp = state.chosedChainAndDApp;
      const allVault = state.vaultsConfig;
      if (JSON.stringify(value) !== JSON.stringify(chosedChainAndDApp)) {
        const data: VaultMatadateType = getVaultDataByChosedData({
          chosedChainAndDApp: value,
          allVault,
          // init: true,
        });
        state.chosedChainAndDApp = value;
        state.chosedData = data;
      }
      state.chosedChainAndDAppInit = false;
    },
    changeLoading: (state) => {
      state.userDataLoaded = false;
    },
    changeVaultItemLoading: (
      state,
      action: PayloadAction<{
        index: number;
        chainkeyFromSource: string;
        dappFromSource: string;
      }>,
    ) => {
      try {
        const { index, chainkeyFromSource, dappFromSource } = action.payload;
        state.chosedData[chainkeyFromSource].dapp[dappFromSource].contract[index].isLoading = true;
      } catch (e) {
        console.log(e);
      }
    },
    changeVaultLoading: (state) => {
      const chosedChain = state.chosedChainAndDApp;
      if (state.chosedData) {
        for (const chainItem of chosedChain) {
          const dapp = state.chosedData[chainItem.chain].dapp;
          for (let dappItem = 0; dappItem < dapp.length; dappItem++) {
            state.chosedData[chainItem.chain].dapp[dappItem].contract.map((v: IVault) => {
              if (v?.farm?.userData?.account) {
                return {
                  ...v,
                };
              } else {
                return {
                  ...v,
                  isLoading: true,
                };
              }
            });
          }
        }
      }
    },
  },
  extraReducers: vaultExtraReducers,
});
// Actions
export const {
  changeStateValue,
  changeLoading,
  changeChosedChainAndDAppValue,
  changeVaultItemLoading,
  changeVaultLoading,
} = vaultSlice.actions;

export default vaultSlice.reducer;
