import React, { lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import GlobalStyle from './style/Global';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import { ToastListener } from './contexts/ToastsContext';
import history from './routerHistory';
import SideMenu from './components/SideMenu';
import PageLoader from 'components/Loader/PageLoader';
import { routePath } from 'config/constants/meta';
import {
  useMultiToken,
  usePollTokenBalance,
  usePollVaultData,
  useVault,
  useVaultUserDataMulti,
} from 'state/vault/hooks';
import { usePollPrice } from 'state/price/hooks';
import VaultMultiTransactionList from 'views/TransactionList/VaultMultiTransactionList/VaultMultiTransactionList';
import useEagerConnect from 'hooks/useEagerConnect';
import { useSafeContract } from 'state/safeContract/hooks';
import { useGlobal } from 'state/global/hooks';
import VaultMultiTradeDeposit from 'views/Trade/views/VaultMultiTradeDeposit/VaultMultiTradeDeposit';
import VaultMultiTradeWithdraw from 'views/Trade/views/VaultMultiTradeWithdraw/VaultMultiTradeWithdraw';
import TradeWithdraw from 'views/Trade/views/TradeWithdraw/TradeWithdraw';
import { useWeb3React } from '@web3-react/core';
import VaultMultiTransactionListDetail from 'views/TransactionList/VaultMultiTransactionListDetail/VaultMultiTransactionListDetail';
// import { update33333History } from 'views/calculateAddress';

const Home = lazy(() => import('./views/Home'));
const NotFound = lazy(() => import('./views/NotFound'));
const VaultMulti = lazy(() => import('./views/VaultMulti/index'));

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  const { account } = useWeb3React();
  useSafeContract();
  usePollPrice();
  useEagerConnect();
  // // useFetchProfile();
  // usePollCoreFarmData();
  usePollVaultData();
  useGlobal();
  // console.log(222);
  useMultiToken();
  const { multiTokenConfig, chosedData } = useVault();
  usePollTokenBalance({ multiTokenConfig, account });
  useVaultUserDataMulti(chosedData, account);
  // useEffect(() => {
  //   update33333History();
  // }, []);
  return (
    <Router history={history}>
      <GlobalStyle />
      <SideMenu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path={routePath.vault.path} exact>
              <VaultMulti />
            </Route>
            <Route path={routePath.vault_trade_deposit.path} exact>
              <VaultMultiTradeDeposit />
            </Route>
            <Route path={routePath.vault_trade_withdraw.path} exact>
              <VaultMultiTradeWithdraw />
            </Route>
            <Route path={routePath.trade_withdraw.path} exact>
              <TradeWithdraw />
            </Route>
            <Route path={routePath.vault_transaction_list.path} exact>
              <VaultMultiTransactionList />
            </Route>
            <Route path={routePath.vault_transaction_list_detail.path} exact>
              <VaultMultiTransactionListDetail />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </SideMenu>
      <ToastListener />
    </Router>
  );
};

export default React.memo(App);
