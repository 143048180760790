import styled from 'styled-components';

export const VaultMultiTradeStyled = styled.div`
  .cursor {
    cursor: pointer;
  }
  margin-bottom: 40px;
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    align-items: start;
    justify-content: center;
    margin-bottom: 120px;
    flex-wrap: wrap;
    div.trade,
    div.line {
      margin-top: 27px;
      ${({ theme }) => theme.mediaQueries.md} {
        margin-top: 60px;
      }
    }
  }
  svg {
    width: 18px;
    fill: #fff;
    ${({ theme }) => theme.mediaQueries.md} {
      width: 22px;
    }
  }
  &.trade_withdraw {
    .trade {
      .trade_inner {
        position: relative;
        background-color: #222049;
        box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
        border-radius: 12px;
        // padding: 24px 16px 30px;
        ${({ theme }) => theme.mediaQueries.md} {
          padding: 1px;
        }
      }
      .contract_account_info {
        padding: 20px;
      }
      .trade_inner_inner {
        background-color: #161528;
        border: 1px solid #232237;
        box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
        border-radius: 12px;
        padding: 24px 20px;
        h3 {
          font-size: 20px;
          font-weight: 800;
          ${({ theme }) => theme.mediaQueries.md} {
            font-size: 30px;
          }
        }
      }
    }
  }
  .trade {
    padding: 20px 16px 0;
    ${({ theme }) => theme.mediaQueries.md} {
      width: 660px;
      padding: 0;
    }
    ${({ theme }) => theme.mediaQueries.xxl} {
      width: 490px;
      margin: 0 10px;
    }
    .trade_inner {
      position: relative;
      background-color: #161528;
      border: 1px solid #232237;
      box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
      border-radius: 12px;
      padding: 24px 16px 30px;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 24px 20px 30px;
      }
    }
    .tab_header {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #010011;
      border: 1px solid #232237;
      border-radius: 12px;
      padding: 4px;
      cursor: pointer;
      width: 79%;
      margin: 0 auto;
      p,
      a {
        width: 50%;
        font-size: 16px;
        color: #6a6991;
        line-height: 36px;
        text-align: center;
        &:hover {
          color: #fff;
        }
        &.on {
          background-color: #1a1935;
          border-radius: 8px;
          color: #fff;
        }
      }
    }
    .from_balance {
      margin-bottom: 8px;
      background: #010011;
      pa'd'display: padd;
      padding: 16px;
      border-radius: 12px;
      h6 {
        font-size: 14px;
        padding-bottom: 10px;
      }
      h5 {
        display: inline-block;
        background: #1a1935;
        border-radius: 12px;
        padding: 6px 12px;
        &:nth-child(2) {
          margin-right: 12px;
        }
        &.on {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    .tab_body_chain {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      background-image: linear-gradient(90deg, #a428d0 0%, #20d4a9 100%);
      border-radius: 12px;
      padding: 0 16px 16px;
      margin-top: 24px;
      margin-bottom: 20px;
      position: relative;

      .arrow_down_ {
        top: 60%;
        rect {
          fill: rgba(24, 23, 51, 0.2);
        }
      }
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 18px;
      }
      h4 {
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 12px;
        padding-top: 20px;
        ${({ theme }) => theme.mediaQueries.md} {
          padding-top: 0;
        }
      }
      .fl,
      .fr {
        width: 100%;
        ${({ theme }) => theme.mediaQueries.md} {
          width: auto;
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: start;
          background: rgba(24, 23, 51, 0.2);
          padding: 10px 22px 10px 12px;
          border-radius: 12px;
          position: relative;
          ${({ theme }) => theme.mediaQueries.md} {
            width: 160px;
          }
          p {
            padding-right: 8px;
          }
          svg {
            width: 20px;
            position: absolute;
            top: 15px;
            right: 10px;
          }
        }
        ${({ theme }) => theme.mediaQueries.md} {
          svg {
            width: 32px;
          }
        }
      }
    }
    .side {
      margin: 25px 8px 0;
      width: 28px;
      padding: 2px 0;
      background: rgba(24, 23, 51, 0.2);
      border-radius: 8px;
      svg {
        display: block;
        margin: 0 auto;
        width: 18px;
        fill: #fff;
      }
    }
  }
  .form {
    background: #010011;
    border-radius: 12px;
    margin-bottom: 30px;
    position: relative;
    .top {
      border-bottom: 1px solid #161528;
    }
    .bottom {
      border-top: 1px solid #161528;
    }
    .top,
    .bottom {
      padding: 16px;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 20px;
      }
    }
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    flex-wrap: wrap;
    h5 {
      font-size: 14px;
      &.h5_bg {
        background-image: radial-gradient(circle at 50% 0%, #3e255b 0%, #161528 100%);
        border: 1px solid #2e2d50;
        padding: 3px 6px;
        border-radius: 12px;
      }
      img {
        width: 20px;
        vertical-align: middle;
        margin-left: 4px;
        margin-right: 0;
      }
    }
    .fr {
      display: flex;
      align-items: center;
      justify-content: end;
      font-weight: 500;
      font-size: 12px;
      color: #6a6991;
      img {
        width: 16px;
      }

      &.fr_bg {
        background-image: radial-gradient(circle at 50% 0%, #3e255b 0%, #161528 100%);
        border: 1px solid #2e2d50;
        padding: 3px;
        border-radius: 12px;
        img:last-child {
          margin-right: 0;
          margin-left: 8px;
        }
      }
      button {
        padding-right: 0;
        padding-left: 4px;
      }
    }
  }
  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    overflow: hidden;
    h4 {
      font-weight: 600;
      max-width: 60%;
    }
    input {
      border: none;
      width: 60%;
      text-align: end;
      height: 28px;
      font-size: 18px;
      padding-right: 0;
      border-radius: 0;
      background-color: transparent;
    }
    h4 {
      font-size: 18px;
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: start;
      p {
        font-size: 18px;
        font-weight: 800;
      }
    }
  }
  .img {
    width: 28px;
    margin-right: 8px;
    border-radius: 50%;
  }
  .big_btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 700;
  }
  .warn {
    margin-top: 20px;
    background: rgba(238, 159, 69, 0.2);
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    font-size: 12px;
    color: #ee9f45;
    line-height: 16px;
    padding: 18px 5%;
    text-align: center;
  }
`;
export const ChosedModalStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #161528;
  z-index: 9;
  padding: 20px 12px;
  border-radius: 12px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 20px;
  }
  h3 {
    font-size: 20px;
    padding-bottom: 18px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 24px;
      padding-bottom: 20px;
    }
    svg {
      width: 28px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
  .input {
    margin-bottom: 14px;
    height: 48px;
    margin-top: 0;
    ${({ theme }) => theme.mediaQueries.md} {
      margin-bottom: 20px;
    }
  }
  .list {
    height: 76%;
    overflow-y: scroll;
    .item {
      font-size: 14px;
      border-radius: 12px;
      padding: 10px 16px;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s ease;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 15px 20px;
        margin: 8px 0;
      }

      &.chain {
        justify-content: start;
      }
      &.on {
        background-image: linear-gradient(90deg, #a428d0 0%, #20d4a9 100%);
      }
      &:hover {
        background-color: #010011;
      }
      .fl {
        display: flex;
        align-items: center;
        justify-content: start;
      }
      .img {
        margin-right: 14px;
        ${({ theme }) => theme.mediaQueries.md} {
          margin-right: 24px;
        }
      }
      p {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
`;
