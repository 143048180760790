import React from 'react';
import { SvgProps } from './types';

const ArrowLeftIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <path d="M640 847.488a64 64 0 0 0 45.248-109.248L459.008 512l226.24-226.272a64 64 0 1 0-90.496-90.496l-271.488 271.52a64 64 0 0 0 0 90.496l271.488 271.488c12.512 12.512 28.864 18.752 45.248 18.752z"></path>
    </svg>
  );
};

export default ArrowLeftIcon;
