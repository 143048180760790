import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { useDispatch } from 'react-redux';

import global from './global';
import vault from './vault';
import priceReducer from './price';
import safeContract from './safeContract';
import vaultHistory from './vaultHistory';

import { updateVersion } from './global/actions';

const PERSISTED_KEYS: string[] = ['price'];
for (let j = 1; j < 10; j++) {
  if (window.localStorage.getItem(`version${j}_price`)) {
    for (let i = 0; i < PERSISTED_KEYS.length; i++) {
      window.localStorage.removeItem(`version${j}_${PERSISTED_KEYS[i]}`);
    }
  }
}

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    price: priceReducer,
    vault,
    safeContract,
    global,
    vaultHistory,
  },
  // middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS, namespace: 'v1' })],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat(save({ states: PERSISTED_KEYS, namespace: 'v0.02' })),
  preloadedState: load({ states: PERSISTED_KEYS, namespace: 'v0.02' }),
});

store.dispatch(updateVersion());

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch();

export default store;
