import { Button, ErrorIcon } from '@my/ui';
import LoadingIcon from 'components/svg/loading';
import SuccessIcon from 'components/svg/SuccessIcon';
import { FC, memo, useCallback, useMemo } from 'react';
import { ModalStyled } from './styled';
import IconLink from 'components/svg/IconLink';
import { ILineCompProps, LineComp } from 'views/Trade/components/LineType';
import { LineStyled } from 'views/Trade/components/LineStyled';
import { IVaultType } from 'state/vault/types';
import styled from 'styled-components';
import { getLink } from 'utils/getLink';
import { useGetToHx } from '../hooks/useGetToHx';
import { CHAINKEY } from 'config/constants/chain_config';
export interface IShowStatusModal extends ILineCompProps {
  collapsed: boolean;
  status: number; //  0 start  1 step   2 st...   999 end
  onDismiss?: any;
  fromtx: string;
  from_tx?: string;
  totx: string;
  gastotalfee: string;
  arrivaltotaltime: string;
  vaultaddress: string;
  createatutctime?: string;
  updateutctime?: string;
  vaulttype?: IVaultType;
  token0address?: string;
  token1address?: string;
  showConfirm?: boolean;
  historytype?: number;
  setBridgeItemCal?: any;
  chainkey?: CHAINKEY;
  chainkeytx?: string;
}
const ShowStatusModal: FC<IShowStatusModal> = ({
  collapsed,
  status,
  tradetype,
  startvalue,
  fromtoken,
  fromchain,
  fromtokensymbol,
  totokenaddress,
  totokensymbol,
  vaultaddress,
  toaddressusdc,
  toaddressusdcsymbol,
  tochain,
  todappname,
  label,
  endvalue,
  swapname,
  swapendvalue,
  bridgekey,
  bridgename,
  bridgeendvalue,
  onDismiss,
  fromtx,
  totx,
  gastotalfee,
  arrivaltotaltime,
  showConfirm,
  setBridgeItemCal,
  chainkey,
  chainkeytx,
  fromdappname,
}) => {
  // console.log({ chainkeytx, fromtx });
  const ComfirmOnPress = useCallback(() => {
    onDismiss();
    if (setBridgeItemCal) {
      setBridgeItemCal(undefined, true);
    }
  }, [onDismiss, setBridgeItemCal]);
  const { _totx, _fromtx, _status } = useGetToHx({
    status: `${status}`,
    fromtx,
    chainkeytx,
    totx,
  });
  // useEffect(() => {
  //   const client = createClient('mainnet');
  //   client.getMessagesBySrcTxHash(fromtx).then((result) => {
  //     console.log(result.messages);
  //   });
  // }, []);
  const statusLocal = useMemo(() => {
    if (_status) {
      return Number(_status);
    }
    return Number(status);
  }, [status, _status]);
  return (
    <ModalStyled title="" headerPadding="4px 30px 0" onDismiss={onDismiss}>
      {statusLocal === 999 || fromchain === tochain ? (
        <SuccessIcon className="svg" />
      ) : statusLocal === 1000 || (fromtx || _fromtx) === 'FAILED' ? (
        <ErrorIcon className="svg error" />
      ) : (
        <LoadingIcon className="svg" />
      )}
      <h3 className="title">Real-Time Processing</h3>
      <LineStyled className={`line ${statusLocal !== 999 ? 'bg_line_grey' : ''}`} backgroundColor="#010011">
        <LineComp
          collapsed={collapsed}
          tradetype={tradetype}
          startvalue={startvalue}
          fromtoken={fromtoken}
          fromchain={fromchain}
          fromtokensymbol={fromtokensymbol}
          totokenaddress={totokenaddress}
          totokensymbol={totokensymbol}
          toaddressusdc={toaddressusdc}
          toaddressusdcsymbol={toaddressusdcsymbol}
          tochain={tochain}
          todappname={todappname}
          label={label}
          endvalue={endvalue}
          swapname={swapname}
          swapendvalue={swapendvalue}
          bridgekey={bridgekey}
          bridgename={bridgename}
          bridgeendvalue={bridgeendvalue}
          fromdappname={fromdappname}
        />
        {collapsed ? (
          <TxList
            chainkey={chainkey}
            chainkeytx={chainkeytx}
            totx={_totx || totx}
            tochain={tochain}
            fromtx={fromtx || _fromtx}
            fromchain={fromchain}
          />
        ) : null}
      </LineStyled>
      <div className="flex">
        {collapsed ? null : (
          <TxList
            chainkey={chainkey}
            chainkeytx={chainkeytx}
            totx={_totx || totx}
            tochain={tochain}
            fromtx={fromtx || _fromtx}
            fromchain={fromchain}
          />
        )}
        <div className="detail">
          {/* <p>Gas Fee: ≈${gastotalfee}</p> */}
          {/* <p>Arrival time：~{arrivaltotaltime}m</p> */}
        </div>
      </div>
      {showConfirm ? <ButtonConfirm onClick={ComfirmOnPress}>Confirm</ButtonConfirm> : null}
      {statusLocal !== 999 ? (
        <p className="grey_text">This process will be displayed in my order list when it is closed or finished</p>
      ) : null}
    </ModalStyled>
  );
};
const ButtonConfirm = styled(Button)`
  padding: 14px 16px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 800;
`;
const TxList = ({ chainkey, chainkeytx, fromtx, fromchain, totx, tochain }) => {
  return (
    <div className="tx_list">
      {chainkeytx ? (
        <>
          <a href={getLink(chainkeytx, 'transaction', chainkey)} title={chainkey} target="_blank" rel="noreferrer">
            {chainkey} TX_LZ
            <IconLink />
          </a>
          <div className="line_"></div>
        </>
      ) : null}
      {fromtx ? (
        <a href={getLink(fromtx, 'transaction', fromchain)} title={fromchain} target="_blank" rel="noreferrer">
          {fromchain} TX
          <IconLink />
        </a>
      ) : (
        <p>
          {fromchain} TX <IconLink />
        </p>
      )}

      {fromchain === tochain ? null : <div className="line_"></div>}
      {fromchain === tochain ? null : totx ? (
        <a href={getLink(totx, 'transaction', tochain)} title={tochain} target="_blank" rel="noreferrer">
          {tochain} TX
          <IconLink />
        </a>
      ) : (
        <p>
          {tochain} TX <IconLink />
        </p>
      )}
    </div>
  );
};
export default memo(ShowStatusModal);
