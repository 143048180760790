import { CHAINKEY } from 'config/constants/chain_config';
import {
  AutoRenewIcon,
  Button,
  connectorLocalStorageKey,
  ConnectorNames,
  Input,
  useMatchBreakpoints,
  useModal,
  useWalletModal,
} from '@my/ui';
import { useWeb3React } from '@web3-react/core';
import ArrowDown, { ArrowDownClickIcon } from 'components/svg/arrow_down';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IMultiTokenItem } from 'state/types';
import { useVault } from 'state/vault/hooks';
import { IVault } from 'state/vault/types';
import { getChainImage, getDappImage, getImageUrlFromToken } from 'utils';
import ArrowRight from 'components/svg/arrow_right';
import { chain_key_localstorage, setLocalStorage } from 'utils/localStorage';
import { useGlobalState } from 'state/global/hooks';
import { chosedTokenLocalStorageKey, chosedTokenStorageKey, ITradeEventType, ITradeType } from 'views/Trade/constants';
import SizedImg from 'views/Trade/components/sizedImg';
import ChoseModal from 'views/Trade/components/ChoseModal';
import { LineStyled } from 'views/Trade/components/LineStyled';
import LineType from 'views/Trade/components/LineType';
import { VaultMultiTradeStyled } from '../styled';
import { useAppDispatch } from 'state';
import { routePath } from 'config/constants/meta';
import { useTradeRouter } from 'views/Trade/hooks/get/getHooks';
import { useGetChainAndToken } from 'views/Trade/hooks/useGetChainAndToken';
import { onPressCallWithdraw } from 'views/Trade/hooks/onPressCallWithdraw';
import { usePrice } from 'state/price/hooks';
import { useSafeContractState } from 'state/safeContract/hooks';
import { BalancesAmount, LayerZeroUAAddress, lzEndpointAddress } from 'config/vault/address';
import ShowStatusModal, { IShowStatusModal } from 'views/TransactionList/VaultMultiTransactionList/ShowStatusModal';
import { getUTCTime } from 'utils/getUTCTime';
import { updateHistory } from 'views/Trade/hooks/post/updateHistory';
import { useContract } from 'wallet/getContract';
import { fetchVaultABIAmountMultiABI, LayerZeroUAABI, lzEndpointABI } from 'config/vault/abi';
import { fetchMultiVaultFarmUserDataAsync, fetchVaultMultiTokenBalanceAsync } from 'state/vault/reducer';
import useToast from 'hooks/useToast';
import BigNumber from 'bignumber.js';
import { BIG_ZERO } from 'utils/bigNumber';
import { symbolUtils } from 'utils/symbolFormmat';
import { setupNetwork } from 'config/wallet';
import { setChainKeyState } from 'state/global';
import { subStringAccount } from 'hooks/useAccount';
import useAuth from 'hooks/useAuth';

const tabText = ['Deposit', 'Withdraw'];
const VaultMultiTradeWithdraw = () => {
  const [pendingTx, setPendingTx] = useState(false);
  const { toastSuccess, toastError, toastWarning } = useToast();
  const { chainkey } = useGlobalState();
  const { account, library } = useWeb3React();
  // const { library } = useWeb3React();
  // const account = '0x41BA3387E1a5a592E27B9EE33935957CE5F872C1';

  const [showChainList, setShowChainList] = useState(false);
  const [showTokenList, setShowTokenList] = useState(false);
  const [value, setValue] = useState<string>('');
  const [fromchain, setFromChain] = useState<CHAINKEY>();
  const [tochain, settochain] = useState<CHAINKEY>();
  const [fromtoken, setFromToken] = useState<IMultiTokenItem>();
  const [toToken, setToToken] = useState<IMultiTokenItem>();
  const [choseModalTitle, setChoseModalTitle] = useState<string>('');
  const { multiTokenConfig, multiToken, chosedData, tradeConfig, accountBalanceInfo } = useVault();
  const { contract_address, vaultChain } = useParams<{ vaultChain: string; contract_address: string }>();
  const { priceVsBusdMapMulti } = usePrice();
  const [collapsed, setCollapsed] = useState(false);
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { safeAddress } = useSafeContractState();
  const [bridgeItem, setBridgeItem] = useState<IShowStatusModal>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    settochain(chainkey);
  }, [chainkey]);
  useEffect(() => {
    if ([isXs, isSm, isMd].some(Boolean)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isXs, isSm, isMd]);
  const endValue = useMemo(() => {
    if (!value || !fromtoken?.symbol) {
      return '';
    }
    // const _b = fromchain === tochain ? 0 : BalancesAmount[fromchain].feeUSD;
    // const size = fromtoken.symbol.indexOf('USD') > -1 ? 0.6 : 0.99;
    if (fromchain === tochain) {
      return value;
    }
    const fromtokensymbol = symbolUtils(fromtoken.symbol);
    const fromtokenUSD = priceVsBusdMapMulti[fromtokensymbol];
    // const balancesUSD = priceVsBusdMapMulti
    const gasTokenAmount = new BigNumber('0.13')
      .dividedBy(fromtokenUSD)
      // .times(BIG_TEN.pow(fromtoken.decimals))
      .toFixed(4);

    let bg = new BigNumber(value).minus(new BigNumber(value).times(0.001).plus(gasTokenAmount));
    bg = bg.gt(BIG_ZERO) ? bg : BIG_ZERO;
    return Number(`${bg.toFixed(4, BigNumber.ROUND_DOWN)}`).toLocaleString('en-US', {
      maximumFractionDigits: 4,
    });
  }, [value, fromtoken?.symbol, fromchain, tochain, priceVsBusdMapMulti]);

  const chosedVault: IVault = useMemo(() => {
    if (!chosedData) {
      return;
    }
    let _vaults: IVault[] = [];
    for (const chain of Object.keys(chosedData)) {
      const chainObj = chosedData[chain];
      if (chainObj.chain === vaultChain) {
        for (const dapp of chainObj.dapp) {
          _vaults = _vaults.concat(dapp.contract);
        }
      }
    }
    return _vaults.filter((v) => v.contractAddress.toLowerCase() === contract_address.toLowerCase())[0];
  }, [chosedData, contract_address, vaultChain]);
  const setBridgeItemCal = useCallback((item) => setBridgeItem(item), []);
  const [onPresetModal] = useModal(
    <ShowStatusModal
      collapsed={collapsed}
      status={bridgeItem?.status}
      tradetype={bridgeItem?.tradetype as ITradeType}
      startvalue={bridgeItem?.startvalue}
      fromtoken={bridgeItem?.fromtoken}
      fromchain={bridgeItem?.fromchain as CHAINKEY}
      fromtokensymbol={bridgeItem?.fromtokensymbol}
      totokenaddress={bridgeItem?.totokenaddress}
      totokensymbol={bridgeItem?.totokensymbol}
      toaddressusdc={bridgeItem?.toaddressusdc}
      toaddressusdcsymbol={bridgeItem?.toaddressusdcsymbol}
      tochain={bridgeItem?.tochain as CHAINKEY}
      fromdappname={chosedVault?.dappname}
      label="Withdraw"
      endvalue={bridgeItem?.endvalue}
      swapname={bridgeItem?.swapname}
      swapendvalue={bridgeItem?.swapendvalue}
      bridgekey={bridgeItem?.bridgekey}
      bridgename={bridgeItem?.bridgename}
      bridgeendvalue={bridgeItem?.bridgeendvalue}
      fromtx={bridgeItem?.fromtx}
      totx={bridgeItem?.totx}
      gastotalfee={bridgeItem?.gastotalfee}
      arrivaltotaltime={bridgeItem?.arrivaltotaltime}
      vaultaddress={bridgeItem?.vaultaddress}
      chainkeytx={bridgeItem?.chainkeytx}
      chainkey={bridgeItem?.chainkey as CHAINKEY}
      showConfirm={true}
      setBridgeItemCal={setBridgeItemCal}
    />,
    true,
    true,
    'ShowStatusModal333',
  );
  const [balanceKey, setBalanceKey] = useState(account);
  useEffect(() => {
    if (fromchain && tochain && account && safeAddress && safeAddress[account] && safeAddress[account][fromchain]) {
      console.log(222);
      setBalanceKey(
        fromchain === tochain ? account.toLowerCase() : safeAddress[account][fromchain].toLocaleLowerCase(),
      );
    }
  }, [account, fromchain, safeAddress, tochain]);
  const setBalanceKeyOnpress = useCallback((account: string) => {
    setBalanceKey(account);
  }, []);
  const {
    chainList,
    fromtokenList,
    // fromtoken: _chosedToken,
    toToken: _totoken,
    tochain: _tochain,
    fromtoken: _fromtoken,
    fromchain: _fromchain,
    toTokenList,
  } = useGetChainAndToken({
    multiToken,
    fromchain: fromchain,
    tochain: tochain,
    multiTokenConfig,
    account,
    vaultIsTo: false,
    chosedVault: chosedVault,
    accountBalanceInfo: accountBalanceInfo,
    safeAddress: safeAddress,
  });
  const vaultContract = useContract(chosedVault?.contractAddress, fetchVaultABIAmountMultiABI);
  const LayerZeroUAContract = useContract(LayerZeroUAAddress[chainkey], LayerZeroUAABI);
  const lzEndpointContract = useContract(lzEndpointAddress[chainkey], lzEndpointABI);

  const {
    obj: { swap, bridge, tradetype },
  } = useTradeRouter({
    fromchainkey: fromchain,
    tochainkey: tochain,
    dappname: chosedVault?.dappname,
    setFromChain,
    settochain,
    tradeEventType: ITradeEventType.Event_2, // 1 deposit 2  avault withdraw  3 erc20 withdraw
  });
  useMemo(() => {
    if (_fromtoken && _tochain && _totoken && _fromchain) {
      // console.log({ _fromtoken });
      setFromToken(_fromtoken);
      setToToken(_totoken);
      setFromChain(_fromchain);
      settochain(_tochain);
    }
  }, [_fromtoken, _tochain, _totoken, _fromchain]);
  const { swapConfig, bridgeConfig } = useMemo(() => {
    if (!tradeConfig || !Object.keys(tradeConfig).length || !swap || !bridge) {
      return {};
    }
    // console.log({ tradeConfig });
    const swapConfig = tradeConfig.swap[swap];
    const bridgeConfig = tradeConfig.bridge[bridge];
    return { swapConfig, bridgeConfig };
  }, [tradeConfig, swap, bridge]);

  const handleSelectMax = useCallback(() => {
    // console.log({ balanceKey }, fromtoken.balances);
    setValue(fromtoken.balances[balanceKey]?.number || '0');
  }, [setValue, balanceKey, fromtoken?.balances]);
  const isNotSetUp = useMemo(() => {
    return (
      (fromchain === tochain && chainkey !== tochain && balanceKey === (account || '').toLowerCase()) ||
      (fromchain !== tochain && chainList && !chainList.includes(chainkey))
    );
    // return fromchain === tochain && chainkey !== tochain;
    // }, [chainkey, tochain, fromchain]);
  }, [chainkey, fromchain, tochain, chainList, balanceKey, account]);
  // console.log(
  //   isNotSetUp,
  //   fromchain === tochain && chainkey !== tochain,
  //   fromchain !== tochain && chainList && !chainList.includes(chainkey),
  // );

  const onPressSetToToken = useCallback(
    (val: IMultiTokenItem) => {
      setLocalStorage(chosedTokenLocalStorageKey, {
        [tochain]: val.symbol,
      });
      setToToken(val);
    },
    [tochain],
  );
  const { login, logout } = useAuth({ chainkey });
  const { onPresentConnectModal } = useWalletModal(login, logout);
  // const { safeAddress } = useSafeContractState();
  const historytype = useMemo(() => {
    if (!account) {
      return 0;
    }
    // parent
    if (balanceKey === account.toLowerCase()) {
      return 2; // parent withdraw
    } else {
      // child
      if (fromchain === tochain) {
        if (chainkey === fromchain) {
          return 3; // child local withdraw   end tx
        } else {
          return 4; // transaction tx  -> layerzero api -> dst tx(end tx)
        }
      } else {
        if (chainkey === fromchain) {
          return 5; // transaction tx -> hop api -> tochain tx(end tx)
        } else {
          return 6; // transaction tx -> layerzero api -> dst tx(fromchain tx) -> hop api -> tochain tx(end tx)
        }
      }
    }
  }, [account, balanceKey, chainkey, fromchain, tochain]);
  const onPressWithdraw = useCallback(async () => {
    setPendingTx(true);
    if (!account) {
      onPresentConnectModal();
      setPendingTx(false);
      return;
    }
    if (isNotSetUp) {
      const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
      // login(connectorId);
      const hasSetUp = await setupNetwork(connectorId, fromchain);
      if (hasSetUp) {
        setLocalStorage(chain_key_localstorage, fromchain);
        dispatch(setChainKeyState(fromchain));
      }
    } else if (
      value &&
      endValue !== '0'
      // new BigNumber(value).gt(fromtoken.balances[safeAddress[account][fromchain]].number)
    ) {
      const result = await onPressCallWithdraw({
        chainkey,
        fromchain,
        fromtoken,
        toToken,
        tochain,
        value,
        library,
        account,
        priceVsBusdMapMulti,
        safeAddress,
        vaultContract,
        LayerZeroUAContract,
        lzEndpointContract,
        fromtokenList: fromtokenList,
        balanceKey,
      });
      // const result = {
      //   isSuccess: true,
      //   txHash: '0x31851d540031eb4c07a2e2747a7263bbb9199ae1c669aa55bd356437a59f2348',
      //   message: '',
      // };
      // console.log({ historytype });
      if (result && result.isSuccess) {
        const { txHash } = result;
        const time = getUTCTime();
        let fromtx = txHash;
        let chainkeytx = '';
        let totx = '';
        let status = 999;
        if (historytype === 2 || historytype === 3) {
          fromtx = txHash;
          totx = txHash;
        } else {
          fromtx = '';
          status = 0;
        }
        if (historytype === 4 || historytype === 6) {
          chainkeytx = txHash;
        }
        if (historytype === 5) {
          fromtx = txHash;
        }
        const item = {
          ...bridgeItem,
          createatutctime: time,
          updateutctime: time,
          vaulttype: chosedVault.vaulttype,
          status: status,
          startvalue: value,
          token0address: chosedVault.vault.token0address,
          token1address: chosedVault.vault.token1address,
          fromtx: fromtx,
          totx: totx,
          historytype: historytype, // 1 deposit  2  withdraw
          chainkey: chainkey,
          chainkeytx: chainkeytx,
        };

        setValue('');
        setBridgeItem(item);
        updateHistory({ item, account });
        dispatch(
          fetchVaultMultiTokenBalanceAsync({
            account,
            multiTokenConfig,
            safeAddress,
          }),
        );
        dispatch(
          fetchMultiVaultFarmUserDataAsync({
            account,
            vaults: chosedData,
            safeAddress,
            priceVsBusdMapMulti,
          }),
        );

        toastSuccess(`Withdraw!`, `Your ${toToken.symbol} withdrawing!`);
        onPresetModal();
      } else {
        toastError('Error', result?.message || `Your ${toToken.symbol} deposit failed!`);
        setPendingTx(false);
      }
    } else {
      toastWarning('Error', 'Please Enter amount or amount is too lower');
    }
    setPendingTx(false);
  }, [
    historytype,
    chosedData,
    chainkey,
    endValue,
    tochain,
    dispatch,
    account,
    fromchain,
    fromtoken,
    library,
    toToken,
    value,
    priceVsBusdMapMulti,
    safeAddress,
    bridgeItem,
    chosedVault,
    onPresetModal,
    vaultContract,
    multiTokenConfig,
    toastError,
    toastSuccess,
    toastWarning,
    fromtokenList,
    isNotSetUp,
    balanceKey,
    onPresentConnectModal,
    LayerZeroUAContract,
    lzEndpointContract,
  ]);
  const onPressSettochain = useCallback((val) => {
    setLocalStorage(chosedTokenStorageKey, val);
    settochain(val);
  }, []);
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setValue(e.currentTarget.value.replace(/,/g, '.'));
      }
    },
    [setValue],
  );
  const btnDisAbled = useMemo(() => {
    if (!account) {
      return false;
    }
    const bol = !value && !isNotSetUp;
    return bol;
  }, [account, value, isNotSetUp]);
  // console.log({ chosedVault, chainList, fromtoken, safeAddress, fromtokenList, toToken, swapConfig, bridgeConfig });
  if (
    !chosedVault ||
    !chainList ||
    !fromtoken ||
    // !safeAddress ||
    !fromtokenList ||
    !toToken ||
    !swapConfig ||
    !bridgeConfig
  ) {
    return null;
  }
  return (
    <VaultMultiTradeStyled>
      <div className="trade">
        <div className="trade_inner">
          <div className="tab_header">
            <Link to={routePath.vault_trade_deposit.pathPre + '/' + chosedVault.chainkey + '/' + contract_address}>
              {tabText[0]}
            </Link>
            <p className="on">{tabText[1]}</p>
          </div>
          <div className="tab_body">
            <div className="tab_body_chain">
              <div className="fl">
                <h4>Transfer from</h4>
                <div className="flex">
                  <SizedImg src={getChainImage(fromchain)} alt={fromchain} />
                  <p>{fromchain}</p>
                </div>
              </div>
              {collapsed ? null : (
                <div className="side">
                  <ArrowRight />
                </div>
              )}
              <div className="fr">
                <h4>Transfer to</h4>
                <div
                  className="flex cursor"
                  onClick={() => {
                    setChoseModalTitle('Chain');
                    setShowChainList(true);
                  }}
                >
                  <SizedImg src={getChainImage(tochain)} alt={tochain} />
                  <p>{tochain}</p>
                  <ArrowDownClickIcon />
                </div>
              </div>
              {collapsed ? <ArrowDown className="arrow_down_" /> : null}
            </div>
            {fromchain === tochain && account && safeAddress && safeAddress[account][fromchain] ? (
              <div className="from_balance">
                <h6>From Account</h6>
                {[account.toLocaleLowerCase(), safeAddress[account][fromchain].toLocaleLowerCase()].map((v) => (
                  <h5 key={v} className={v === balanceKey ? 'on' : ''} onClick={() => setBalanceKeyOnpress(v)}>
                    {subStringAccount(v)}
                  </h5>
                ))}
              </div>
            ) : null}
            <div className="form">
              <div className="top">
                <div className="text">
                  <h5>
                    You Withdraw
                    {/* <SizedImg src={getDappImage(chosedVault.dappname)} alt={chosedVault.dappname} /> */}
                  </h5>
                  <div className="fr">
                    <SizedImg src={getDappImage(chosedVault.dappname)} alt={chosedVault.dappname} />
                    <p>
                      Balance:
                      {(fromtoken?.balances || {})[balanceKey]?.localNumber || '0'}
                      {/* Balance: {fromtoken.balances[balanceKey].localNumber || '0'} */}
                      {fromtoken?.symbol || ''}
                    </p>

                    <Button variant="text" onClick={handleSelectMax}>
                      Max
                    </Button>
                  </div>
                </div>
                <div className="input">
                  <div className="flex">
                    <SizedImg src={getImageUrlFromToken(fromtoken.address, fromchain)} alt={fromtoken.address} />
                    <p>{fromtoken.symbol}</p>
                  </div>
                  <Input
                    placeholder="0.00"
                    pattern={`^[0-9]*[.,]?[0-9]{0,8}$`}
                    inputMode="decimal"
                    value={value}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="bottom">
                <div className="text">
                  <h5>You Receive</h5>
                </div>
                <div className="input">
                  <div
                    className="flex "
                    // className="flex cursor"
                    // onClick={() => {
                    //   setChoseModalTitle('Token');
                    //   setShowTokenList(true);
                    // }}
                  >
                    <SizedImg src={getImageUrlFromToken(toToken.address, tochain)} alt={toToken.address} />
                    <p>{toToken.symbol}</p>
                    {/* <ArrowDownClickIcon /> */}
                  </div>
                  <h4>≈{endValue}</h4>
                </div>
              </div>
              <ArrowDown />
            </div>
            <Button
              className="big_btn"
              disabled={btnDisAbled}
              endIcon={pendingTx ? <AutoRenewIcon spin color="#fff" /> : null}
              onClick={onPressWithdraw}
            >
              {!account ? 'Connect Wallet' : isNotSetUp ? `Switch wallet to ${fromchain}` : 'Withdraw'}
            </Button>
          </div>
          {showChainList || showTokenList ? (
            <ChoseModal
              accountAddress={account}
              chosedToken={toToken}
              showChainList={showChainList}
              setShowChainList={setShowChainList}
              chainList={chainList}
              setChosedChain={onPressSettochain}
              showTokenList={showTokenList}
              setShowTokenList={setShowTokenList}
              tokenList={toTokenList}
              setChosedToken={onPressSetToToken}
              chosedChain={tochain}
              title={choseModalTitle}
            />
          ) : null}
        </div>
        {account ? (
          value ? null : (
            <p className="warn">Please select the parameters for your desired transfer and enter an amount.</p>
          )
        ) : (
          <p className="warn">First, you need to connect wallet!</p>
        )}
      </div>
      {value && account ? (
        <LineStyled className="line" backgroundColor="#161528">
          <div className="card">
            <LineType
              collapsed={collapsed}
              value={value}
              toTokenList={toTokenList}
              fromchain={fromchain}
              fromtoken={fromtoken}
              // chosedVault={chosedVault}
              swapConfig={swapConfig}
              bridgeConfig={bridgeConfig}
              tradetype={tradetype}
              label={'Withdraw'}
              tochain={tochain}
              totokenaddress={toToken.address}
              totokensymbol={toToken.symbol}
              gasfee={BalancesAmount[fromchain].feeUSD}
              bridgeItem={bridgeItem}
              setBridgeItemCal={setBridgeItemCal}
              fromdappname={chosedVault.dappname}
            />
          </div>
        </LineStyled>
      ) : null}
    </VaultMultiTradeStyled>
  );
};
export default memo(VaultMultiTradeWithdraw);
