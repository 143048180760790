import BigNumber from 'bignumber.js';
import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE } from 'config';
import { chainIdsList } from 'config/vault/chainIdsList';
import { wallet_config } from 'config/wallet';
import { ethers } from 'ethers';
import { BIG_TEN } from 'utils/bigNumber';
import { symbolUtils } from 'utils/symbolFormmat';
import { dstGasForCall } from '../onPressCallDeposit';
import { getCrossChainSwapFee, IGetFeeFunctionType } from './getCrossChainSwapFee';

export const getDepositGasFee = async ({ tochain, account, fromchain, toToken, priceVsBusdMapMulti }) => {
  const dstChainId = chainIdsList[tochain]; // Stargate/LayerZero chainId
  const symbol = wallet_config[fromchain].nativeCurrency.symbol;
  const fromtokensymbol = symbolUtils(symbol);
  const fromtokenUSD = priceVsBusdMapMulti[fromtokensymbol];
  const payload = ethers.utils.defaultAbiCoder.encode(['address'], [account]);
  const crossChainSwapFee = await getCrossChainSwapFee({
    fromchain,
    dstChainId,
    getFeeFunctionType: IGetFeeFunctionType.TYPE_SWAP_REMOTE,
    toAddress: toToken.address,
    payload,
    _dstGasForCall: dstGasForCall,
  });
  // console.log({ priceVsBusdMapMulti, symbol, fromtokenUSD });
  const fee = new BigNumber(crossChainSwapFee)
    .plus(new BigNumber(DEFAULT_GAS_PRICE[fromchain]).times(DEFAULT_GAS_LIMIT[fromchain]))
    .div(BIG_TEN.pow(18))
    .times(fromtokenUSD)
    .toNumber();
  // console.log(crossChainSwapFee, fee, priceVsBusdMapMulti, fromtokenUSD);

  return fee.toLocaleString('en-US', {
    maximumFractionDigits: 8,
  });
};
