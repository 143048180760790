import styled from 'styled-components';

export const LineStyled = styled.div<{ backgroundColor: string }>`
  // display: none !important;
  .img {
    width: 28px;
    margin-right: 8px;
  }
  .type .type_inner_big_top .type_inner_big_top_bg_layZero {
    div.chain_card {
      &:first-child {
        margin-right: 8px;
      }
    }
    .layZero {
      margin-right: 8px;
      .layZero_bg {
        margin-bottom: 5px;
        background-image: linear-gradient(90deg, #a428d0 0%, #20d4a9 100%);
        padding: 4px;
        border-radius: 50%;

        .layZero_bg_inner {
          border-radius: 50%;
          background-color: #010011;
          img {
            width: 24px;
            border-radius: 50%;
          }
        }
      }
      img {
        display: block;
        margin: 0 auto;
        &:nth-child(2) {
          width: 18px;
        }
      }
    }
  }
  &.bg_line_grey {
    .label_card {
      background-color: #222049;
      p {
        color: #6a6991;
      }
    }
    .chain_card:nth-child(2) {
      background-color: #222049;
      background-image: none;
      color: #6a6991;
      p {
        color: #6a6991;
      }
    }
    .bg div.bg_line {
      background-image: none;
      background-color: #2e2d50;
    }
    div.bg_arrow_left {
      background: linear-gradient(45deg, #2e2d50, #2e2d50 50%, transparent 50%, transparent 1px);

      // background: #2E2D50;
    }
  }

  &.line {
    width: 100%;
    padding: 20px 16px;
    // margin-top: 20px;
    // margin-bottom: 100px;

    ${({ theme }) => theme.mediaQueries.md} {
      padding: 0;
      min-width: 650px;
      max-width: 660px;
      margin: 0 10px;
    }
    .label_card {
      color: #fff;
    }
    .line_bg {
      background-color: ${({ backgroundColor }) => backgroundColor};
      border: 1px solid #232237;
      box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
      border-radius: 12px;
      padding: 16px;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 20px;
      }
    }
    .card_label {
      display: flex;
      align-items: start;
      justify-content: start;
      margin-top: 20px;
      color: #6a6991;
      &.small {
        margin-top: 0;
        justify-content: center;
        flex-wrap: wrap;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        border-radius: 12px;
        padding: 10px 12px;
        margin-right: 12px;
        margin-bottom: 10px;
        background-color: #1a1935;
        ${({ theme }) => theme.mediaQueries.md} {
          border: 1px solid #232237;
          background: ${({ backgroundColor }) => backgroundColor};
          box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
        }
      }
    }
    .bg {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      .bg_line {
        background-image: linear-gradient(140deg, #20d4a9 0%, #a428d0 79%);
        background-origin: border-box;
      }
      .bg_line_inner {
        width: 100%;
        background-color: ${({ backgroundColor }) => backgroundColor};
      }
    }
    .type_0 {
      text-align: left;
      position: relative;
      .bg {
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;
        .bg_line {
          width: 36%;
          border-bottom: 2px dashed ${({ backgroundColor }) => backgroundColor};
        }
        .bg_line_inner {
          height: 1px;
        }
      }
    }
    .type {
      text-align: left;
      position: relative;
      // padding-bottom: 40px;
      ${({ theme }) => theme.mediaQueries.md} {
        padding-bottom: 0;
      }
      .type_inner_big_bottom {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 10px 6%;
        justify-content: space-between;
        &.center {
          justify-content: center;
          // padding-top: 30px;
        }
        &.bottom2 {
          padding: 20px 2% 0;
          ${({ theme }) => theme.mediaQueries.sm} {
            padding: 40px 18% 0;
          }
        }
        ${({ theme }) => theme.mediaQueries.md} {
          padding: 0 50px;
        }
        .img {
          width: 16px;
        }
      }
      .type_inner_big_top {
        padding-bottom: 10px;
        position: relative;
        z-index: 2;
        // background-color: #161528;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-left: 5%;
        // padding-right: 5%;
        ${({ theme }) => theme.mediaQueries.md} {
          margin-bottom: 10px;
          display: inline-block;
        }
        .type_inner_big_top_bg {
          background-color: #161528;
          padding-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          ${({ theme }) => theme.mediaQueries.md} {
            display: inline-block;
            padding-bottom: 0;
            width: auto;
          }
          & > div {
            ${({ theme }) => theme.mediaQueries.md} {
              vertical-align: middle;
              display: inline-block;
            }
          }
        }
        // &:before {
        //   content: '';
        //   position: absolute;
        //   width: 90%;
        //   height: 100%;
        //   background-color: ${({ backgroundColor }) => backgroundColor};
        //   ${({ theme }) => theme.mediaQueries.md} {
        //     width: 80%;
        //   }
        //   ${({ theme }) => theme.mediaQueries.lg} {
        //     width: 76%;
        //   }
        //   ${({ theme }) => theme.mediaQueries.xl} {
        //     width: 68%;
        //   }
        //   ${({ theme }) => theme.mediaQueries.xxl} {
        //     width: 66%;
        //   }
        // }
        & > div {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          z-index: 2;
        }
        .chain_card {
          margin-right: 10px;
          width: 170px;
          ${({ theme }) => theme.mediaQueries.lg} {
            &:first-child {
              margin-right: 30px;
            }
            margin-right: 14px;
          }
        }
      }
      .bg {
        height: 70%;
        top: 50%;
        transform: translateY(-50%);
        left: 15%;
        width: 70%;
        &.center {
          top: 50px;
          height: 44%;
          .bg_arrow_top {
            top: 2px;
          }
        }
        ${({ theme }) => theme.mediaQueries.md} {
          width: 90%;
          max-width: 580px;
          left: 30px;
          height: 58%;
        }
        .bg_line {
          width: 100%;
          height: 100%;
          border: 2px dashed ${({ backgroundColor }) => backgroundColor};
          border-radius: 12px;
          border-top: none;
          overflow: hidden;
          ${({ theme }) => theme.mediaQueries.md} {
            border-top: 2px dashed ${({ backgroundColor }) => backgroundColor};
          }
        }
        .bg_line_inner {
          width: 100%;
          height: 100%;
        }
      }
    }
    .type_inner {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .bg_arrow_right {
      width: 12px;
      height: 12px;
      background: linear-gradient(45deg, #a428d0, #20d4a9 50%, transparent 50%, transparent 1px);
      transform: rotate(-135deg);
    }
    .bg_arrow_top {
      position: absolute;
      top: 25px;
      right: -4px;
      z-index: 9;
      width: 12px;
      height: 12px;
      background: linear-gradient(45deg, #a428d0, #20d4a9 50%, transparent 50%, transparent 1px);
      transform: rotate(135deg);
    }
    .bg_arrow_left {
      width: 12px;
      height: 12px;
      background: linear-gradient(45deg, #a428d0, #20d4a9 50%, transparent 50%, transparent 1px);
      transform: rotate(45deg);
      // margin-right: 30px;
      // &.marginleft20 {
      //   margin-right: 0;
      // margin-left: -20px;
      // }
    }

    .chain_card {
      min-width: 130px;
      background-image: linear-gradient(140deg, #224b56 0%, #3c2459 79%);
      border-radius: 16px;
      // display: flex;
      // align-items: center;
      // justify-content: start;
      padding: 8px;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 12px;
        min-width: 160px;
      }

      & > img,
      & > .fr {
        display: inline-block;
        vertical-align: middle;
      }
      h4 {
        font-size: 14px;
        font-weight: 800;
        margin-bottom: 2px;
        word-break: break-all;

        ${({ theme }) => theme.mediaQueries.md} {
          // font-size: 15px;
          margin-bottom: 4px;
        }
      }
      p {
        font-size: 12px;
      }
      img {
        width: 24px;
        margin-right: 8px;
        ${({ theme }) => theme.mediaQueries.md} {
          // margin-right: 12px;
          width: 36px;
        }
      }
      .fr {
        max-width: 60%;
        // min-width: 106px;
        img {
          width: 16px;
          vertical-align: middle;
          margin-left: 6px;
        }
        p {
          position: relative;
          img {
            position: absolute;
            top: -2px;
            right: -30px;
            border-radius: 50%;
          }
        }
      }
    }
    .label_card {
      // display: flex;
      // align-items: center;
      // justify-content: start;
      font-size: 12px;
      color: #6a6991;
      line-height: 16px;
      background: #010011;
      border: 1px solid #2e2d50;
      border-radius: 12px;
      padding: 7px 10px;
      // max-width: 160px;
      word-break: break-all;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 10px 16px;
      }
      img,
      div,
      p {
        display: inline-block;
        vertical-align: middle;
      }
      img:nth-child(2) {
        margin-left: -13px;
        box-shadow: 0 0 6px #000;
        border-radius: 50%;
      }
      & > div {
        max-width: 80px;
      }
    }
  }
`;
