import { ArrowRightClickIcon } from 'components/svg/arrow_right';
import { getBridgeImage, getImageUrlFromToken } from 'utils';
import LoadingIcon from 'components/svg/loading';
import SuccessIcon from 'components/svg/SuccessIcon';
import { ErrorIcon } from '@my/ui';

const SmallItem = ({ index, onPressModal, item }) => {
  console.log(item.fromtx);
  return (
    <tr key={index} onClick={() => onPressModal(index)}>
      <td className="card_tr">
        <div className="card_top">
          <div className="fl">
            <img src={getImageUrlFromToken(item.fromtoken, item.fromchain)} alt={item.fromtoken} />
            <h3>
              {item.startvalue}
              {item.fromtokensymbol}
            </h3>
            <p>On {item.fromchain}</p>
          </div>
          <ArrowRightClickIcon />
          <div className="fr">
            <img src={getImageUrlFromToken(item.toToken, item.tochain)} alt={item.toToken} />
            <h3>
              {/* {item.endvalue} -*/}
              {item.toaddressusdcsymbol}
            </h3>
            <p>On {item.tochain}</p>
          </div>
        </div>
        <div className="card_bottom">
          <div className="flex bridge">
            <img src={getBridgeImage(item.bridgekey)} alt={item.bridgekey} />
            <h4>{item.bridgename}</h4>
          </div>
          {item.fromtx === 'FAILED' || Number(item.status) === 1000 ? (
            <div className="flex card card_grey">
              <ErrorIcon width={16} className="grey_err_path" />
              <p>Error</p>
            </div>
          ) : item.fromchain !== item.tochain && Number(item.status) !== 999 ? (
            <div className="flex card card_green">
              <LoadingIcon width={20} />
              <p>Pending</p>
            </div>
          ) : (
            <div className="flex card card_grey">
              <SuccessIcon width={16} />
              <p>Completed</p>
            </div>
          )}
          {/* <div className="tx_list">
            <a
              href={getLink(item.fromtx || item.from_tx, 'transaction', item.fromchain)}
              title={item.fromchain}
              target="_blank"
              rel="noreferrer"
            >
              {subStringAccount(item.fromtx)}
              <IconLink />
            </a>
          </div> */}
        </div>
      </td>
    </tr>
  );
};
export default SmallItem;
