import { createAction } from '@reduxjs/toolkit';

export const updateVersion = createAction<void>('global/updateVersion');

// export const fetchGlobalPublicDataAsync = createAsyncThunk<[], {}>('global/fetchGlobalPublicDataAsync', async () => {
//   return null;
// });
const GlobalExtraReducers = (builder) => {
  // builder.addCase(fetchGlobalPublicDataAsync.fulfilled, (state, action) => {
  //   state.userDataLoaded = true;
  //   state.data = action.payload[0].map((v) => {
  //     return {
  //       ...v,
  //       isLoading: false,
  //     };
  //   });
  //   state.tvlTotal = action.payload[1];
  // });
};
export default GlobalExtraReducers;
