import { Button, useTooltip } from '@my/ui';
import IconLink from 'components/svg/IconLink';
import { CHAINKEY } from 'config/constants/chain_config';
import { routePath } from 'config/constants/meta';
import { subStringAccount } from 'hooks/useAccount';
import { useJumpPath } from 'hooks/useJumpPath';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalState } from 'state/global/hooks';
import { IMultiTokenItem } from 'state/types';
import { IVaultType } from 'state/vault/types';
import { getChainImage } from 'utils';
import { getLink } from 'utils/getLink';
import { useGetChainAndToken } from 'views/Trade/hooks/useGetChainAndToken';
import { VaultComp } from 'views/VaultMulti/components/MultiVaultTable/tableComponents/MultiRow';
import VaultWithdrawBtnIcon from '../imgs/VaultWithdrawBtn';
import { AssetListStyled, AssetStyled, ContractAccountInfoStyled } from './AssetStyled';

const Asset = ({ safeAddress, account, multiToken, multiTokenConfig, collapsed }) => {
  // console.log({ safeAddress, account, multiToken, multiTokenConfig, collapsed });
  const setMoreTooltipVisible = useRef<React.Dispatch<React.SetStateAction<boolean>>>();
  const {
    targetRef: AssetTargetRef,
    tooltip: AssetTooltip,
    tooltipVisible: AssetTooltipVisible,
    setTooltipVisible,
  } = useTooltip(
    <AssetList
      safeAddress={safeAddress}
      account={account}
      multiToken={multiToken}
      multiTokenConfig={multiTokenConfig}
      setTooltipVisible={setMoreTooltipVisible.current}
      collapsed={collapsed}
    />,
    {
      trigger: 'click',
      tootipStyle: {
        // position: 'fixed',
        backgroundColor: 'transparent',
        background: '#010011',
        border: '1px solid #2E2D50',
        boxShadow: '2px 12px 16px 0 rgba(0, 0, 0, 0.3)',
        padding: '20px',
        minWidth: '360px',
        marginTop: '7px',
      },
      // placement: 'bottom-end',
      hideArrow: false,
      arrowBackground: '#010011',
    },
  );
  useEffect(() => {
    setMoreTooltipVisible.current = setTooltipVisible;
  }, [setTooltipVisible]);
  if (!account) {
    return null;
  }
  return (
    <>
      {AssetTooltipVisible && AssetTooltip}
      {safeAddress ? <AssetStyled ref={AssetTargetRef}>My Assets</AssetStyled> : null}
    </>
  );
};
export const AssetList = ({ setTooltipVisible, safeAddress, account, multiToken, multiTokenConfig, collapsed }) => {
  const { chainkey } = useGlobalState();
  const [chosedChain, setChosedChain] = useState(chainkey);
  // const { multiTokenConfig, multiToken } = useVault();
  // const { account } = useWeb3React();
  // const { safeAddress } = useSafeContractState();
  // console.log(222);
  // useMultiToken();
  // usePollTokenBalance({ multiTokenConfig, account });

  const safeContractAddress = useMemo(() => {
    if (safeAddress && account && chosedChain && safeAddress[account] && safeAddress[account][chosedChain]) {
      return subStringAccount(safeAddress[account][chosedChain]);
    }
    return '';
  }, [safeAddress, account, chosedChain]);

  const { chainList, fromtokenList } = useGetChainAndToken({
    multiToken,
    fromchain: chosedChain,
    tochain: chosedChain,
    multiTokenConfig,
    account,
  });
  const onPressChain = useCallback((v: string) => {
    setChosedChain(v as CHAINKEY);
  }, []);
  if (!chainList || !safeAddress || !safeAddress[account]) {
    return null;
  }
  return (
    <AssetListStyled>
      <ul className="chain_list">
        {chainList.map((v, index) => (
          <li key={index} onClick={() => onPressChain(v)} className={chosedChain === v ? 'on' : ''}>
            <img src={getChainImage(v)} alt={v} />
            <p>{v}</p>
          </li>
        ))}
      </ul>
      {safeAddress && chainList.includes(chosedChain) ? (
        <ContractAccountInfo
          account={account}
          chosedChain={chosedChain}
          safeContractAddress={safeContractAddress}
          safeAddress={safeAddress}
        />
      ) : null}
      {safeAddress && chainList.includes(chosedChain) && fromtokenList.length > 0 ? (
        <ul className="token_list">
          {fromtokenList.map((v: IMultiTokenItem, index: number) => (
            <TokenListItemCompant
              account={account}
              key={index}
              v={v}
              chosedChain={chosedChain}
              setTooltipVisible={setTooltipVisible}
              safeAddress={safeAddress}
              collapsed={collapsed}
            />
          ))}
        </ul>
      ) : null}
    </AssetListStyled>
  );
};
export const ContractAccountInfo = ({
  chosedChain,
  safeContractAddress,
  className,
  safeAddress,
  account,
}: {
  chosedChain: CHAINKEY;
  safeContractAddress: string;
  className?: string;
  account: string;
  safeAddress: any;
}) => {
  if (!safeAddress || !safeAddress[account] || !safeAddress[account][chosedChain]) {
    return null;
  }
  const _safeAddress = safeAddress[account][chosedChain];
  return (
    <ContractAccountInfoStyled className={className}>
      <img src={getChainImage(chosedChain)} alt={chosedChain} />
      <div className="fl">
        <h5>{chosedChain} Contract Wallet</h5>
        {safeContractAddress ? (
          <a href={getLink(_safeAddress, 'address', chosedChain)} target="_blank" rel="noreferrer">
            <p>{subStringAccount(safeContractAddress)}</p>
            <IconLink />
          </a>
        ) : null}
      </div>
    </ContractAccountInfoStyled>
  );
};
const TokenListItemCompant = ({
  v,
  chosedChain,
  setTooltipVisible,
  safeAddress,
  collapsed,
  account,
}: {
  v: IMultiTokenItem;
  chosedChain: CHAINKEY;
  setTooltipVisible: any;
  safeAddress: Record<string, Record<string, string>>;
  collapsed: boolean;
  account: string;
}) => {
  const { jump } = useJumpPath(
    `${routePath.trade_withdraw.pathPre}/${safeAddress[account][chosedChain]}/${chosedChain}/${(
      v?.address || '-'
    ).toLowerCase()}`,
    () => {
      setTooltipVisible(false);
    },
  );

  return (
    <li>
      <div className="fl">
        <VaultComp
          vault={{
            wantaddress: v.address,
            wantaddresssymbol: v.symbol,
            vaulttype: IVaultType.single,
            chain: chosedChain,
          }}
          width={20}
          height={20}
        />
        <h4>{v.balances[safeAddress[account][chosedChain]].localNumber}</h4>
      </div>
      {collapsed ? (
        v.balances[safeAddress[account][chosedChain]].localNumber !== '0' ? (
          <div className="icon" onClick={jump}>
            <VaultWithdrawBtnIcon />
          </div>
        ) : (
          <div className="icon grey">
            <VaultWithdrawBtnIcon />
          </div>
        )
      ) : v.balances[safeAddress[account][chosedChain]].localNumber !== '0' ? (
        <Button variant="secondary" scale="sm" onClick={jump}>
          Withdraw
        </Button>
      ) : (
        <Button variant="text" disabled>
          Withdraw
        </Button>
      )}
    </li>
  );
};

export default memo(Asset);
