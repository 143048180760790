import { CHAINKEY } from 'config/constants/chain_config';
import AvaultRouter_ARBITRUM from './abi/AvaultRouter_ARBITRUM.json';
import AvaultRouter_OPTIMISM from './abi/AvaultRouter_OPTIMISM.json';
import SigValidationModule from './abi/SigValidationModule.json';
import UOExecutorL2 from './abi/UOExecutorL2.json';
import IStargateRouter from './abi/IStargateRouter.json';
import fetchVaultABIAmountMulti from './abi/fetchVaultABIAmountMulti.json';
import LayerZeroUA from './abi/LayerZeroUA.json';
import lzEndpoint from './abi/lzEndpoint.json';

export const routerContractABI = {
  [CHAINKEY.ARBITRUM_MAINNET]: AvaultRouter_ARBITRUM,
  [CHAINKEY.OPTIMISTIC_MAINNET]: AvaultRouter_OPTIMISM,
};
export const SigValidationModuleABI = SigValidationModule;
export const UOExecutorL2ABI = UOExecutorL2;

export const IStargateRouterABI = IStargateRouter;
export const fetchVaultABIAmountMultiABI = fetchVaultABIAmountMulti;
export const LayerZeroUAABI = LayerZeroUA;
export const lzEndpointABI = lzEndpoint;
