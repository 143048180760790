import { VaultMatadateType } from 'state/types';
import { CHAINKEY } from 'config/constants/chain_config';
import { RPCApi } from 'server/RPCApi';
import { IABIType, IVault } from '../types';
import { getFromaterParmas } from '../utils';
import { fetchVaultABIAmountMultiABI } from 'config/vault/abi';

export const fetchVaultABIBaseMulti = async (allVaultsData: VaultMatadateType): Promise<Record<string, any[]>> => {
  let obj = {};
  const result = await Promise.all(
    Object.keys(allVaultsData).map((chain) => {
      const chainkey = chain as CHAINKEY;
      const _chain = allVaultsData[chain];
      const _dapp = _chain.dapp;
      const { obj: _obj, vaultsData } = getFromaterParmas({ obj, dapps: _dapp, chain });
      obj = _obj;
      return fetchVaultABIBaseMultiItem(vaultsData, chainkey);
    }),
  );
  Object.keys(allVaultsData).map((chain: string, index: number) => {
    const chainObj = result[index];
    const _chain = allVaultsData[chain];
    const _dapp = _chain.dapp;
    let start = 0;
    let end = 0;
    for (let i = 0; i < _dapp.length; i++) {
      const dappname = _dapp[i].dappname;
      start = end;
      const { len } = getFromaterParmas({ obj, dapps: _dapp, chain });
      end = start + len[i][1];
      const keyArr = Object.keys(chainObj);
      for (let ei = 0; ei < keyArr.length; ei++) {
        const key = keyArr[ei];
        const preItem = obj[chain][dappname] && obj[chain][dappname] ? obj[chain][dappname] : {};
        obj[chain][dappname] = {
          ...preItem,
          [key]: chainObj[key].slice(start, end),
        };
      }
    }
    return obj;
  });
  return obj;
};
const fetchVaultABIBaseMultiItem = async (vaultsData: IVault[], chain: CHAINKEY): Promise<Record<string, any[]>> => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCallsArr = {
    // masterChef: [],
    name: [],
    symbol: [],
    // pid: [],
    wantaddress: [],
    // token0address: [],
    // token1address: [],
    earnedaddress: [],
    // avaaddress: [],
    // vaultDecimals: [],
  };
  // vaultCallsArr.masterChef = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'farmContractAddress',
  // }));
  vaultCallsArr.name = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'name',
  }));
  vaultCallsArr.symbol = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'symbol',
  }));
  // vaultCallsArr.pid = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'pid',
  // }));
  vaultCallsArr.wantaddress = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'wantaddress',
  }));
  // vaultCallsArr.token0address = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'token0address',
  // }));
  // vaultCallsArr.token1address = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'token1address',
  // }));
  // vaultCallsArr.earnedaddress = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'earnedaddress',
  // }));
  // vaultCallsArr.avaaddress = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'avaaddress',
  // }));
  // vaultCallsArr.vaultDecimals = vaultsData.map((v) => ({
  //   address: v.contractAddress,
  //   name: 'decimals',
  // }));
  const vaultCall = [...Object.values(vaultCallsArr)].flat(2);
  // console.log({ vaultCall });
  const result = await rpcApi.multicall(fetchVaultABIAmountMultiABI, vaultCall);
  const obj = {};
  const keyArr = Object.keys(vaultCallsArr);
  for (let i = 0; i < keyArr.length; i++) {
    const start = i * vaultsData.length;
    const end = start + vaultsData.length;
    obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0]);
    switch (`${keyArr[i]}`) {
      case 'pid':
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0].toNumber());
        break;
      case 'vaultDecimals':
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0].toString());
        break;
      default:
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0]);
    }
  }
  return obj;
};

export const fetchVaultABIBaseSingleToken = async (
  vaultsData: IVault[],
  chain: CHAINKEY,
): Promise<Record<string, any[]>> => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCallsArr = {
    name: [],
    symbol: [],
    wantaddress: [],
    earnedaddress: [],
    avaaddress: [],
    vaultDecimals: [],
  };

  vaultCallsArr.name = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'name',
  }));
  vaultCallsArr.symbol = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'symbol',
  }));

  vaultCallsArr.wantaddress = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'wantaddress',
  }));

  vaultCallsArr.earnedaddress = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'earnedaddress',
  }));
  vaultCallsArr.avaaddress = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'avaaddress',
  }));
  vaultCallsArr.vaultDecimals = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'decimals',
  }));
  const vaultCall = [...Object.values(vaultCallsArr)].flat(2);
  const result = await rpcApi.multicall(fetchVaultABIAmountMultiABI, vaultCall);
  const obj = {};
  const keyArr = Object.keys(vaultCallsArr);
  for (let i = 0; i < keyArr.length; i++) {
    const start = i * vaultsData.length;
    const end = start + vaultsData.length;
    obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0]);
    switch (`${keyArr[i]}`) {
      case 'pid':
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0].toNumber());
        break;
      case 'vaultDecimals':
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0].toString());
        break;
      default:
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0]);
    }
  }
  return obj;
};

export const fetchVaultABIAmountMulti = async (allVaultsData: VaultMatadateType): Promise<Record<string, any[]>> => {
  let obj = {};
  const result = await Promise.all(
    Object.keys(allVaultsData).map((chain) => {
      const chainkey = chain as CHAINKEY;
      const _chain = allVaultsData[chain];
      const _dapp = _chain.dapp;
      const { obj: _obj, vaultsData } = getFromaterParmas({ obj, dapps: _dapp, chain });
      obj = _obj;
      return fetchVaultABIAmountItem(vaultsData, chainkey);
    }),
  );
  Object.keys(allVaultsData).map((chain: string, index: number) => {
    const chainObj = result[index];
    const _chain = allVaultsData[chain];
    const _dapp = _chain.dapp;
    let start = 0;
    let end = 0;
    for (let i = 0; i < _dapp.length; i++) {
      const dappname = _dapp[i].dappname;
      start = end;
      const { len } = getFromaterParmas({ obj, dapps: _dapp, chain });
      end = start + len[i][1];
      const keyArr = Object.keys(chainObj);
      for (let ei = 0; ei < keyArr.length; ei++) {
        const key = keyArr[ei];
        const preItem = obj[chain][dappname] && obj[chain][dappname] ? obj[chain][dappname] : {};
        obj[chain][dappname] = {
          ...preItem,
          [key]: chainObj[key].slice(start, end),
        };
      }
    }
    return obj;
  });
  // for await (const chain of Object.keys(allVaultsData)) {
  //   const chainkey = chain as CHAINKEY;
  //   const _chain = allVaultsData[chain];
  //   const _dapp = _chain.dapp;
  //   const { obj: _obj, vaultsData, len } = getFromaterParmas({ obj, dapps: _dapp, chain });
  //   obj = _obj;
  //   const chainObj = await fetchVaultABIAmountItem(vaultsData, chainkey);
  //   let start = 0;
  //   let end = 0;
  //   for (let i = 0; i < _dapp.length; i++) {
  //     const dappname = _dapp[i].dappname;
  //     start = end;
  //     end = start + len[i][1];
  //     const keyArr = Object.keys(chainObj);
  //     for (let ei = 0; ei < keyArr.length; ei++) {
  //       const key = keyArr[ei];
  //       const preItem = obj[chain][dappname] && obj[chain][dappname] ? obj[chain][dappname] : {};
  //       obj[chain][dappname] = {
  //         ...preItem,
  //         [key]: chainObj[key].slice(start, end),
  //       };
  //     }
  //   }
  // }
  return obj;
};

const fetchVaultABIAmountItem = async (vaultsData: IVault[], chain: CHAINKEY) => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCallsArr = {
    wantLockedTotal: [],
    // scale: [],
    vaultTotalSupply: [],
  };
  // vaultCallsArr.scale = vaultsData.map((v) => {
  //   if (v.eventtype === IEventType.normal) {
  //     return {
  //       address: v.contractAddress,
  //       name: 'totalSupply',
  //     };
  //   } else {
  //     return {
  //       address: v.contractAddress,
  //       name: 'scale',
  //     };
  //   }
  // });
  vaultCallsArr.wantLockedTotal = vaultsData.map((v) => {
    if (v.abitype === IABIType.AVaultForStarlay || v.abitype === IABIType.AVaultForAAVE) {
      return {
        address: v.contractAddress,
        name: 'getNetAssetValue',
        params: ['0x794a61358D6845594F94dc1DB02A252b5b4814aD'], // perhaps different
      };
    }
    return {
      address: v.contractAddress,
      name: 'wantLockedTotal',
    };
  });
  vaultCallsArr.vaultTotalSupply = vaultsData.map((v) => ({
    address: v.contractAddress,
    name: 'totalSupply',
  }));

  const vaultCall = [...Object.values(vaultCallsArr)].flat(2);

  const result = await rpcApi.multicall(fetchVaultABIAmountMultiABI, vaultCall);
  const obj = {};
  const keyArr = Object.keys(vaultCallsArr);
  for (let i = 0; i < keyArr.length; i++) {
    const start = i * vaultsData.length;
    const end = start + vaultsData.length;
    obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0]);
    switch (`${keyArr[i]}`) {
      case 'wantLockedTotal':
      case 'scale':
      case 'vaultTotalSupply':
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0].toString());
        break;
      default:
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v) => v[0]);
    }
  }
  return obj;
};
