import BigNumber from 'bignumber.js';
import { CHAINKEY } from 'config/constants/chain_config';
import { UOExecutorL2ABI } from 'config/vault/abi';
import { LayerZeroUAAddress, sigValidationModuleAddress, uOExecutorL2Address } from 'config/vault/address';
import { wallet_config } from 'config/wallet';
import { Contract, ethers } from 'ethers';
import { IMultiTokenItem } from 'state/types';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import { symbolUtils } from 'utils/symbolFormmat';
import { callWithEstimateGas } from 'wallet/estimateGas';
// import { getUniswapRouterByFromChain } from './get/getUniswapRouter';
import { getUserOperation } from './get/getUserOperation';
import { Hop, Chain } from '@hop-protocol/sdk';
import { getSigner } from 'wallet/getContract';
import { replaceStr1 } from 'utils/strFrommat';
import { ZERO_ADDRESS } from 'state/safeContract/utils/utils';
import { chainIdsList } from 'config/vault/chainIdsList';
const hop = new Hop('mainnet');
export const onPressCallWithdraw = async ({
  chainkey,
  fromchain,
  fromtoken,
  toToken,
  tochain,
  value,
  library,
  account,
  priceVsBusdMapMulti,
  safeAddress,
  vaultContract,
  LayerZeroUAContract,
  lzEndpointContract,
  fromtokenList,
  balanceKey,
}: {
  chainkey: CHAINKEY;
  fromchain: CHAINKEY;
  fromtoken: IMultiTokenItem;
  toToken: IMultiTokenItem;
  tochain: CHAINKEY;
  value: string;
  account: string;
  library: any;
  priceVsBusdMapMulti: Record<string, string>;
  safeAddress: Record<string, Record<CHAINKEY, string>>;
  vaultContract: Contract;
  LayerZeroUAContract: Contract;
  lzEndpointContract: Contract;
  fromtokenList: IMultiTokenItem[];
  balanceKey: string;
}) => {
  const amountIn = new BigNumber(`${value}`).times(BIG_TEN.pow(fromtoken?.decimals || 18)).toFixed(0);
  try {
    if (fromchain !== tochain || balanceKey !== account.toLowerCase()) {
      const fromchainId = wallet_config[fromchain].chainIdInt;
      const tochainId = wallet_config[tochain].chainIdInt;
      // const toPath = await getUniswapRouterByFromChain({
      //   chainkey: tochain,
      //   value: value,
      //   account,
      //   fromtoken,
      //   toToken,
      //   isFrom: false,
      //   multiToken: multiToken,
      // });
      const _toPath = fromtokenList.filter(
        (v) => v.symbol.toLowerCase().indexOf(symbolUtils(fromtoken.symbol)) > -1 && v.address,
      )[0];
      const toPath = _toPath.address;
      // console.log({ toPath }, symbolUtils(fromtoken.symbol));
      // return;
      // const fromtokensymbol = symbolUtils(fromtoken.symbol);
      // const fromtokenUSD = priceVsBusdMapMulti[fromtokensymbol];
      // const balancesUSD = priceVsBusdMapMulti
      // const gasTokenAmount = new BigNumber(BalancesAmount[fromchain].feeUSD)
      //   .dividedBy(fromtokenUSD)
      //   .times(BIG_TEN.pow(fromtoken.decimals))
      //   .toFixed(0);
      // if (!new BigNumber(amountIn).gt(gasTokenAmount)) {
      //   return;
      // }
      const signer = getSigner(library, account);
      const bridge = hop.connect(signer).bridge(symbolUtils(toToken.symbol).toUpperCase());
      const source = new Chain(replaceStr1(fromchain));
      const destination = new Chain(replaceStr1(tochain));
      const { totalFee: _totalFee } = await bridge.getSendData(amountIn, source, destination);
      const totalFee = fromchain === tochain ? BIG_ZERO : _totalFee;
      // console.log({ amountIn, totalFee: totalFee.toString(), source, destination });
      // gasToken for path first
      // bytes memory _path, uint _amount, uint _destChainId, address _destAddress, uint _bonderFee
      if (!new BigNumber(amountIn).gt(totalFee.toString())) {
        return {
          isCrossChain: true,
          isSuccess: false,
          message: 'Amount is too lower',
        };
      }
      const tochainParams = {
        _path: toPath,
        _amount: amountIn,
        _destChainId: tochainId,
        _destAddress: account,
        _bonderFee: totalFee.toString(),
      };
      const avatar = safeAddress[account][fromchain];
      if (new BigNumber(value).gt(new BigNumber(fromtoken.balances[avatar]?.number || '0'))) {
        return {
          isCrossChain: true,
          isSuccess: false,
          message: 'Balance is too lower',
        };
      }
      const userOperation = await getUserOperation({
        abi: UOExecutorL2ABI,
        method: 'withdrawFromAVault',
        params: Object.values(tochainParams),
        tochainId: fromchainId,
        amountIn: '0',
        sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
        library,
        account,
        avatar,
        chainkey: fromchain,
        gasToken: ZERO_ADDRESS,
        // gasToken: fromtoken?.address || ZERO_ADDRESS,
        gasTokenAmount: '0',
        uOExecutorL2Address: uOExecutorL2Address[fromchain],
      });
      const dstChainId = chainIdsList[fromchain]; // Stargate/LayerZero chainId
      /**
       * @param _dstChainId: refer to https://layerzero.gitbook.io/docs/technical-reference/mainnet/supported-chain-ids
       * @param _payload: abi.encode(avatar, srcAddress, uo);
       * @param _zroPaymentAddress: set to address(0x0) if not paying in ZRO (LayerZero Token)
       * @param _adapterParams: refer to https://layerzero.gitbook.io/docs/evm-guides/advanced/relayer-adapter-parameters
       * @dev about the value: refer to how to estimate destination gas fee: https://layerzero.gitbook.io/docs/evm-guides/code-examples/estimating-message-fees#call-estimatefees-to-return-a-tuple-containing-the-cross-chain-message-fee.
       */
      // uint16 _dstChainId,
      // address _destAddress,// lzaddress
      // bytes memory _payload,
      // address payable _refundAddress,
      // address _zroPaymentAddress,//0x
      // bytes memory _adapterParams
      const adapterParams = ethers.utils.solidityPack(['uint16', 'uint256'], [1, 1500000]);
      // console.log({ avatar, account, userOperation });
      const payload = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'tuple(uint256 toChainId, address to, uint256 value, bytes data, address gasToken, uint256 gasTokenAmount, uint256 operation, uint8 v, bytes32 r, bytes32 s) d',
        ],
        [account, userOperation],
      );
      const FeeValue = await callWithEstimateGas(fromchain, lzEndpointContract, 'estimateFees', [
        dstChainId,
        LayerZeroUAAddress[fromchain],
        payload,
        false,
        adapterParams,
      ]);
      const nativeFee = chainkey === fromchain ? '0' : FeeValue[0].toString();
      // console.log({
      //   LayerZeroUAContract: LayerZeroUAContract.address,
      //   _dstChainId: dstChainId,
      //   _destAddress: LayerZeroUAAddress[fromchain],
      //   _payload: payload,
      //   _refundAddress: account,
      //   _zroPaymentAddress: ZERO_ADDRESS,
      //   _adapterParams: adapterParams,
      //   value: `${nativeFee}`,
      // });
      const res = await callWithEstimateGas(
        fromchain,
        LayerZeroUAContract,
        'lzSend',
        [dstChainId, LayerZeroUAAddress[fromchain], payload, account, ZERO_ADDRESS, adapterParams],
        {
          value: `${nativeFee}`,
        },
      );
      if (res && res.isOk) {
        return {
          isCrossChain: fromchain === tochain ? false : true,
          isSuccess: true,
          txHash: res?.receipt?.transactionHash,
          message: '',
        };
      } else {
        return {
          isCrossChain: fromchain === tochain ? false : true,
          isSuccess: false,
          message: res?.message,
        };
      }
      // console.log({
      //   chainkey: fromchain,
      //   sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
      //   avatar: avatar,
      //   srcAddress: account,
      //   uo: userOperation,
      // });
      // const result = await fetch(process.env.REACT_APP_GRAPH_API_PROFILE + '/api/v0/multi/execute', {
      //   method: 'post',
      //   body: JSON.stringify({
      //     chainkey: fromchain,
      //     sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
      //     avatar: avatar,
      //     srcAddress: account,
      //     uo: userOperation,
      //   }),
      //   headers: {
      //     'content-type': 'application/json',
      //   },
      // });
      // const res = await result.json();
      // if (res && res.data) {
      //   return {
      //     isCrossChain: true,
      //     isSuccess: true,
      //     txHash: res.data?.transactionHash,
      //     message: '',
      //   };
      // } else {
      //   return {
      //     isCrossChain: true,
      //     isSuccess: false,
      //     message: res?.msg,
      //   };
      // }
    } else {
      if (new BigNumber(value).gt(new BigNumber(fromtoken.balances[account.toLowerCase()]?.number || '0'))) {
        return {
          isCrossChain: false,
          isSuccess: false,
          message: 'Balance is too lower',
        };
      }
      let res;
      if (!toToken.address) {
        res = await callWithEstimateGas(fromchain, vaultContract, 'withdrawNative', [account, `${amountIn}`]);
      } else {
        res = await callWithEstimateGas(fromchain, vaultContract, 'withdraw', [account, `${amountIn}`]);
      }
      if (res && res.isOk) {
        return {
          isCrossChain: false,
          isSuccess: true,
          txHash: res?.receipt?.transactionHash,
          message: '',
        };
      } else {
        return {
          isCrossChain: false,
          isSuccess: false,
          message: res?.message,
        };
      }
    }
  } catch (e: any) {
    console.log(222, e);
    return {
      isCrossChain: true,
      isSuccess: false,
      message: e?.message,
    };
  }
};

export const onPressCallWithdrawERC20 = async ({
  chainkey,
  fromchain,
  fromtoken,
  toToken,
  tochain,
  value,
  library,
  account,
  priceVsBusdMapMulti,
  safeAddress,
  fromtokenList,
  lzEndpointContract,
  LayerZeroUAContract,
}: {
  chainkey: CHAINKEY;
  fromchain: CHAINKEY;
  fromtoken: IMultiTokenItem;
  toToken: IMultiTokenItem;
  tochain: CHAINKEY;
  value: string;
  library: any;
  account: string;
  priceVsBusdMapMulti: Record<string, string>;
  safeAddress: Record<string, Record<CHAINKEY, string>>;
  fromtokenList: IMultiTokenItem[];
  lzEndpointContract: Contract;
  LayerZeroUAContract: Contract;
}) => {
  try {
    const amountIn = new BigNumber(`${value}`).times(BIG_TEN.pow(fromtoken?.decimals || 18)).toFixed(0);
    const fromchainId = wallet_config[fromchain].chainIdInt;
    const tochainId = wallet_config[tochain].chainIdInt;
    // const fromtokensymbol = symbolUtils(fromtoken.symbol);
    // const fromtokenUSD = priceVsBusdMapMulti[fromtokensymbol];
    // const balancesUSD = priceVsBusdMapMulti
    // const gasTokenAmount = new BigNumber(BalancesAmount[fromchain].feeUSD)
    //   .dividedBy(fromtokenUSD)
    //   .times(BIG_TEN.pow(fromtoken.decimals))
    //   .toFixed(0);
    // gasToken for path first
    // bytes memory _path, uint _amount, uint _destChainId, address _destAddress, uint _bonderFee
    // if (!new BigNumber(amountIn).gt(gasTokenAmount)) {
    //   return;
    // }
    const avatar = safeAddress[account][fromchain];
    if (new BigNumber(value).gt(new BigNumber(fromtoken.balances[avatar]?.number || '0'))) {
      return {
        isCrossChain: true,
        isSuccess: false,
        message: 'Balance is too lower',
      };
    }
    // const toPath = '0x';
    const _toPath = fromtokenList.filter(
      (v) => v.symbol.toLowerCase().indexOf(symbolUtils(fromtoken.symbol)) > -1 && v.address,
    )[0];
    const toPath = _toPath.address;

    const signer = getSigner(library, account);
    const bridge = hop.connect(signer).bridge(symbolUtils(toToken.symbol).toUpperCase());
    const source = new Chain(replaceStr1(fromchain));
    const destination = new Chain(replaceStr1(tochain));
    const { totalFee: _totalFee } = await bridge.getSendData(amountIn, source, destination);
    const totalFee = fromchain === tochain ? BIG_ZERO : _totalFee;
    if (!new BigNumber(amountIn).gt(totalFee.toString())) {
      return {
        isCrossChain: true,
        isSuccess: false,
        message: `Amount is too lower, ${amountIn} < ${totalFee}`,
      };
    }
    const tochainParams = {
      _path: toPath,
      _amount: amountIn,
      _destChainId: tochainId,
      _destAddres: account,
      _bonderFee: totalFee.toString(),
    };
    // console.log({ tochainParams });

    const userOperation = await getUserOperation({
      abi: UOExecutorL2ABI,
      method: 'withdrawFromWallet',
      params: Object.values(tochainParams),
      tochainId: fromchainId,
      amountIn: '0',
      sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
      library,
      account,
      avatar,
      chainkey: fromchain,
      // gasToken: fromtoken?.address || ZERO_ADDRESS,
      // gasTokenAmount,
      gasToken: ZERO_ADDRESS,
      gasTokenAmount: '0',
      uOExecutorL2Address: uOExecutorL2Address[fromchain],
    });
    const dstChainId = chainIdsList[fromchain]; // Stargate/LayerZero chainId
    /**
     * @param _dstChainId: refer to https://layerzero.gitbook.io/docs/technical-reference/mainnet/supported-chain-ids
     * @param _payload: abi.encode(avatar, srcAddress, uo);
     * @param _zroPaymentAddress: set to address(0x0) if not paying in ZRO (LayerZero Token)
     * @param _adapterParams: refer to https://layerzero.gitbook.io/docs/evm-guides/advanced/relayer-adapter-parameters
     * @dev about the value: refer to how to estimate destination gas fee: https://layerzero.gitbook.io/docs/evm-guides/code-examples/estimating-message-fees#call-estimatefees-to-return-a-tuple-containing-the-cross-chain-message-fee.
     */
    // uint16 _dstChainId,
    // address _destAddress,// lzaddress
    // bytes memory _payload,
    // address payable _refundAddress,
    // address _zroPaymentAddress,//0x
    // bytes memory _adapterParams
    const adapterParams = ethers.utils.solidityPack(['uint16', 'uint256'], [1, 1500000]);
    // console.log({ avatar, account, userOperation });
    const payload = ethers.utils.defaultAbiCoder.encode(
      [
        'address',
        'tuple(uint256 toChainId, address to, uint256 value, bytes data, address gasToken, uint256 gasTokenAmount, uint256 operation, uint8 v, bytes32 r, bytes32 s) d',
      ],
      [account, userOperation],
    );
    const FeeValue = await callWithEstimateGas(fromchain, lzEndpointContract, 'estimateFees', [
      dstChainId,
      LayerZeroUAAddress[fromchain],
      payload,
      false,
      adapterParams,
    ]);
    const nativeFee = chainkey === fromchain ? '0' : FeeValue[0].toString();
    // console.log({
    //   LayerZeroUAContract: LayerZeroUAContract.address,
    //   _dstChainId: dstChainId,
    //   _destAddress: LayerZeroUAAddress[fromchain],
    //   _payload: payload,
    //   _refundAddress: account,
    //   _zroPaymentAddress: ZERO_ADDRESS,
    //   _adapterParams: adapterParams,
    //   value: `${nativeFee}`,
    // });
    const res = await callWithEstimateGas(
      fromchain,
      LayerZeroUAContract,
      'lzSend',
      [dstChainId, LayerZeroUAAddress[fromchain], payload, account, ZERO_ADDRESS, adapterParams],
      {
        value: `${nativeFee}`,
      },
    );
    if (res && res.isOk) {
      return {
        isCrossChain: fromchain === tochain ? false : true,
        isSuccess: true,
        txHash: res?.receipt?.transactionHash,
        message: '',
      };
    } else {
      return {
        isCrossChain: fromchain === tochain ? false : true,
        isSuccess: false,
        message: res?.message,
      };
    }
    // // console.log({
    // //   tochainParams,
    // //   chainkey: fromchain,
    // //   sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
    // //   avatar: avatar,
    // //   srcAddress: account,
    // //   uo: userOperation,
    // // });
    // const result = await fetch(process.env.REACT_APP_GRAPH_API_PROFILE + '/api/v0/multi/execute', {
    //   method: 'post',
    //   body: JSON.stringify({
    //     chainkey: fromchain,
    //     sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
    //     avatar: avatar,
    //     srcAddress: account,
    //     uo: userOperation,
    //   }),
    //   headers: {
    //     'content-type': 'application/json',
    //   },
    // });
    // const res = await result.json();
    // if (res && res.data) {
    //   return {
    //     isCrossChain: true,
    //     isSuccess: true,
    //     txHash: res.data?.transactionHash,
    //     message: '',
    //   };
    // } else {
    //   return {
    //     isCrossChain: true,
    //     isSuccess: false,
    //     message: res?.msg,
    //   };
    // }
  } catch (e: any) {
    console.log(e);
    return {
      isCrossChain: true,
      isSuccess: false,
      message: e?.message,
    };
  }
};
