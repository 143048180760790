import { createSlice } from '@reduxjs/toolkit';
import VaultHistoryReducers from './reducer';

export const VaultHistorySlice = createSlice({
  name: 'vaultHistory',
  initialState: {
    vaultHistoryIsLoading: false,
    vaultHistory: [],
  },
  reducers: {},
  extraReducers: VaultHistoryReducers,
});

export default VaultHistorySlice.reducer;
