import { Button, Flex, useMatchBreakpoints } from '@my/ui';
import DefaultImg from 'components/DefaultImg';
import { TokenPairImage } from 'components/TokenImage';
import { routePath } from 'config/constants/meta';
import { FC, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IVaultType } from 'state/vault/types';
import styled from 'styled-components';
import { getImageUrlFromToken } from 'utils';
import { IProtocol, IVaultRow, MultiRowProps } from './MultiTableBody';

interface IMultiRow {
  row: MultiRowProps;
}
const MultiRow: FC<IMultiRow> = ({ row }) => {
  const history = useHistory();
  const depositJump = useCallback(
    (e) => {
      if ('vault' in row) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        history.push(`${routePath.vault_trade_deposit.pathPre}/${row.protocol.chain}/${row.contractAddress}`);
      }
    },
    [history, row],
  );
  const withdrawJump = useCallback(
    (e) => {
      if ('vault' in row) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        history.push(`${routePath.vault_trade_withdraw.pathPre}/${row.protocol.chain}/${row.contractAddress}`);
      }
    },
    [history, row],
  );

  if ('vault' in row) {
    return (
      <tr onClick={depositJump}>
        {row.vault ? (
          <td>
            <VaultComp vault={row.vault} />
          </td>
        ) : null}
        <ProtocolComp protocol={row.protocol} />
        {row.reward_apy ? (
          <td>
            <p>{row.reward_apy}%</p>
          </td>
        ) : null}
        {row.base_apy ? (
          <td>
            <p>{row.base_apy}%</p>
          </td>
        ) : null}
        {row.total_apy ? (
          <td className="red">
            <p>{row.total_apy}%</p>
          </td>
        ) : null}
        {row.reward_token ? (
          <td>
            <p className="reward_token">{row.reward_token}</p>
          </td>
        ) : null}
        {row.tvl ? (
          <td>
            <p>${row.tvl}</p>
          </td>
        ) : null}
        {row.details ? (
          <BtnTd>
            <Button variant="primary">Deposit</Button>
            {row.details.vault.isbalance ? (
              <Button variant="tertiary" onClick={withdrawJump}>
                Withdraw
              </Button>
            ) : (
              <Button variant="text" disabled>
                Withdraw
              </Button>
            )}
          </BtnTd>
        ) : null}
      </tr>
    );
  }
  return null;
};
const BtnTd = styled.td`
  button {
    width: 80px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
      // padding-top: 3px;
      &:disabled {
        background-color: transparent;
        border: 1px solid #2e2d50;
      }
    }
  }
`;
const TokenWrapper = styled.div`
  margin-right: 6px;
  // max-width: 22px;
  // min-width: 22px;
  width: 28px;
  margin-right: 10px;
  // ${({ theme }) => theme.mediaQueries.md} {
  //   min-width: 32px;
  // }
  .img {
    display: block;
    //   // width: 80%;
    //   // margin: 0 auto;
    //   // width: 32px;
    //   // min-width: 22px;
  }
  // ${({ theme }) => theme.mediaQueries.sm} {
  //   padding-right: 10px;
  // }
`;
const ProtocolCompStyled = styled.td`
  h6 {
    font-size: 12px;
    padding-bottom: 4px;
    ${({ theme }) => theme.mediaQueries.sm} {
      padding-bottom: 8px;
    }
  }
  p {
    font-weight: 500;
    font-size: 12px;
    color: #6a6991;
  }
`;
const ProtocolComp = ({ protocol }: { protocol: IProtocol }) => {
  return (
    <ProtocolCompStyled>
      <h6>{protocol.dappname}</h6>
      <p>{protocol.chain}</p>
    </ProtocolCompStyled>
  );
};
export const VaultComp = ({ vault, width, height }: { vault: IVaultRow; width?: number; height?: number }) => {
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { wantaddresssymbol } = vault;
  return (
    <Flex alignItems="center">
      <TokenWrapperComp vault={vault} width={width} height={height} />
      {[isXs, isSm, isMd].some(Boolean) && wantaddresssymbol.indexOf('LP') > -1 ? null : <p>{wantaddresssymbol}</p>}
    </Flex>
  );
};
export const TokenWrapperComp = ({ vault, width, height }: { vault: IVaultRow; width?: number; height?: number }) => {
  const { token0address, token1address, wantaddress, chain, vaulttype } = vault;
  // console.log({ vault, vaulttype });
  return (
    <TokenWrapper>
      {vaulttype === IVaultType.single || vaulttype === IVaultType.stablecoin ? (
        <img src={getImageUrlFromToken(wantaddress, chain)} className="img" alt="" />
      ) : vaulttype === IVaultType.lp_token ? (
        <TokenPairImage
          variant="inverted"
          primaryToken={token0address}
          secondaryToken={token1address}
          chainkeyFromSource={chain}
          // dappFromSource={dappFromSource}
          width={width ?? 60}
          height={height ?? 60}
        />
      ) : (
        <DefaultImg />
      )}
    </TokenWrapper>
  );
};
export default memo(MultiRow);
