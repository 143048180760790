import { PancakeTheme } from '@my/ui';
import { createGlobalStyle } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
declare module 'styled-components' {
  export interface DefaultTheme extends PancakeTheme {}
}
const GlobalStyle = createGlobalStyle`
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translate(0, 54px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translate(-54px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translate(54px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}



  html {
    height: 100%;
  }
  h1,h2,h3,h4{
    font-weight: 800;
  }
  h5,h6,i,li,p,tr,td,a,input,button{
    font-weight: 600;
  }
  h1,h2,h3,h4,h5,h6,i,li,p,tr,td,a,input,button{
    font-family: 'Poppins', sans-serif;
  }
  ul,li{
    list-style: none;
  }
  *::-webkit-scrollbar {
    display: none;
  }
/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  /* background: ${({ theme }) => theme.colors.textSubtle};  */
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px ${({ theme }) => theme.colors.input};  */
  border-radius: 10px;
}
// input::-webkit-input-placeholder {
//   font-weight: 600;
//   color: #fff;
// }
// // input:-moz-input-placeholder {
// //   font-weight: 600;
// //   color: #fff;
// // }
// // input::moz-input-placeholder {
// //   font-weight: 600;
// //   color: #fff;
// // }
// // input::-ms-input-placeholder {
// //   font-weight: 600;
// //   color: #fff;
// // }

  /* Number */
  input{
    color: #fff;
    background-color: transparent;
    border: none;
    
      &:focus {
        outline: none;
      }
    
      &:active {
        text-decoration: none;
      }
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Slider */
input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

  body {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.background02};
    padding: 0;
    color: #fff;
    img {
      height: auto;
      max-width: 100%;
    }
    #root {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .w20{
    width: 20px;
  }



.back-top {
  position: fixed;
  bottom: 64px;
  right: 30px;
  width: 30px;
  height: 30px;

}
.back-top-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAM1BMVEUAAADx8fEAAADo6OgAAAAAAADNzc39/f37+/vd3d21tbWYmJhERET39/fq6upxcXH///+E7V3/AAAAEHRSTlMmwACoGQ958+aRX0wx160938kFbQAAAPJJREFUWMPt2UuugzAQRNECbPM3vf/VvpdE+ZBWIitVwz4LKIF0mTTor3ICKeXbEq5zkMj3wQSRdBl0e+Qi3PtS8v8gpHpkSGUkSCWET9Z9hdIy2rhAp9hFgcpsNzM0qt1VCGydPXUbWMdur/aDzWWys2llc3k3LmQuXiFz8WYyF6+SuXjdxuXi7Qebi7l8yFy4fIq1KEQuXD7VWlU6F58Pm4vPh83F58Pm4vMhc/FKey5kPoP9amjLhcynDGeTfTMNZw2fYff9kfAmBmMwBmMwBmMwBmMwBl8Hw0MSz+lPpj2k5Gdn9WFcf7rX/1yQ//74A251bnrBUaCPAAAAAElFTkSuQmCC) no-repeat;
      background-size: 100% 100%;
  }

  .back-top.hidden {
      display: none;
  }
`;

export default GlobalStyle;
