import BigNumber from 'bignumber.js';
import { ERC20_ABI } from 'config/abi/erc20';
import { CHAINKEY } from 'config/constants/chain_config';
import { useEffect, useState } from 'react';
import { RPCApi } from 'server/RPCApi';
import { BIG_ZERO } from 'utils/bigNumber';

export const useGetIsApprove = ({
  fromchain,
  tochain,
  routeraddress,
  fromTokenAddress,
  wantAddress,
  account,
  contractAddress,
  setIsApprove,
}: {
  fromchain: CHAINKEY;
  tochain: CHAINKEY;
  routeraddress: Record<string, string>;
  fromTokenAddress: string;
  wantAddress: string;
  account: string;
  contractAddress: string;
  setIsApprove: any;
}) => {
  const [isGetApprove, setIsGetApprove] = useState<boolean>(false);
  useEffect(() => {
    // console.log({ fromchain, tochain, wantAddress, account, contractAddress, setIsApprove });
    if (fromchain && tochain && wantAddress && account && contractAddress && setIsApprove) {
      // console.log({ chainkey, wantAddress, account, contractAddress });
      if (!fromTokenAddress) {
        // console.log(1111);
        setIsApprove(true);
        setIsGetApprove(true);
        return;
      }
      (async () => {
        const rpcApi = new RPCApi({
          chainkey: fromchain,
        });
        const call =
          fromchain !== tochain
            ? [
                {
                  address: fromTokenAddress,
                  name: 'allowance',
                  params: [account, routeraddress[fromchain]],
                },
              ]
            : [
                {
                  address: wantAddress,
                  name: 'allowance',
                  params: [account, contractAddress],
                },
              ];
        const [[result]] = await rpcApi.multicall(ERC20_ABI, call);
        const _b = result.toString();
        // console.log(9999, { fromTokenAddress, routeraddress, _b, tochain, fromchain });
        if (new BigNumber(_b).gt(BIG_ZERO)) {
          setIsApprove(true);
        } else {
          setIsApprove(false);
        }
        setIsGetApprove(true);
      })();
    }
  }, [fromchain, tochain, fromTokenAddress, routeraddress, wantAddress, account, contractAddress, setIsApprove]);
  return isGetApprove;
};
