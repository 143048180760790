export const tabText = ['Deposit', 'Withdraw'];
export const chosedTokenLocalStorageKey = 'Chosed_Token_Local_Storage_Key';
export const chosedTokenStorageKey = 'Chosed_Token_Storage_Key';

export enum ITradeType {
  'type_0' = '0', // [Bsc AToken] -> [Bsc AToken DApp]
  'type_1' = '1', // [Bsc BToken] -> Swap -> [Bsc AToken] -> [Bsc AToken DApp]
  'type_2' = '2', // [Ethereum AToken] -> Multichain -> [Bsc AToken DApp]
  'type_3' = '3', // [Ethereum BToken] -> Multichain -> [Bsc BToken] -> Swap -> [BSC AToken] -> [BSC AToken Dapp]
  'type_4' = '4', // [Ethereum BToken] -> swap -> [Ethereum AToken] -> Multichain -> [Bsc AToken]
}
export interface ITradeRouter {
  tradetype: ITradeType;
  swap: string;
  bridge: string;
}
// 1 deposit 2  avault withdraw  3 erc20 withdraw
export enum ITradeEventType {
  'Event_1' = 'event_1',
  'Event_2' = 'event_2',
  'Event_3' = 'event_3',
}
export const tradeRouter: Record<string, Record<string, Record<string, Record<string, ITradeRouter>>>> = {
  arbitrum: {
    arbitrum: {
      AAVE: {
        [ITradeEventType.Event_1]: {
          tradetype: ITradeType.type_1,
          swap: 'uniswap',
          bridge: 'stargate',
        },
        [ITradeEventType.Event_2]: {
          tradetype: ITradeType.type_1,
          swap: 'uniswap',
          bridge: 'hop',
        },
        [ITradeEventType.Event_3]: {
          tradetype: ITradeType.type_1,
          swap: 'uniswap',
          bridge: 'hop',
        },
      },
    },

    optimism: {
      AAVE: {
        [ITradeEventType.Event_1]: {
          tradetype: ITradeType.type_2,
          swap: 'uniswap',
          bridge: 'stargate',
        },
        [ITradeEventType.Event_2]: {
          tradetype: ITradeType.type_2,
          swap: 'uniswap',
          bridge: 'hop',
        },
        [ITradeEventType.Event_3]: {
          tradetype: ITradeType.type_2,
          swap: 'uniswap',
          bridge: 'hop',
        },
      },
    },
  },
  optimism: {
    optimism: {
      AAVE: {
        [ITradeEventType.Event_1]: {
          tradetype: ITradeType.type_1,
          swap: 'uniswap',
          bridge: 'stargate',
        },
        [ITradeEventType.Event_2]: {
          tradetype: ITradeType.type_1,
          swap: 'uniswap',
          bridge: 'hop',
        },
        [ITradeEventType.Event_3]: {
          tradetype: ITradeType.type_1,
          swap: 'uniswap',
          bridge: 'hop',
        },
      },
    },
    arbitrum: {
      AAVE: {
        [ITradeEventType.Event_1]: {
          tradetype: ITradeType.type_2,
          swap: 'uniswap',
          bridge: 'stargate',
        },
        [ITradeEventType.Event_2]: {
          tradetype: ITradeType.type_2,
          swap: 'uniswap',
          bridge: 'hop',
        },
        [ITradeEventType.Event_3]: {
          tradetype: ITradeType.type_2,
          swap: 'uniswap',
          bridge: 'hop',
        },
      },
    },
  },
};
