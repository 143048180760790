import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export const getUTCTime = () => {
  const d1 = new Date();
  const utc = `${dayjs.utc(d1.valueOf())}`;
  // const bb = zonedTimeToUtc(new Date(aa), tz);
  return utc;
};
