export function replaceStr1(str: string) {
  str = str.toLowerCase();
  var strTemp = ''; //新字符串
  for (var i = 0; i < str.length; i++) {
    if (i === 0) {
      strTemp += str[i].toUpperCase(); //第一个
      continue;
    }
    if (str[i] === ' ' && i < str.length - 1) {
      //空格后
      strTemp += ' ';
      strTemp += str[i + 1].toUpperCase();
      i++;
      continue;
    }
    strTemp += str[i];
  }
  return strTemp;
}
