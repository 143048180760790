import { CHAINKEY } from 'config/constants/chain_config';
import { wallet_config } from 'config/wallet';
import sample from 'lodash/sample';

const getNodeUrl = (chainkey: CHAINKEY) => {
  try {
    const nodes = wallet_config[chainkey].nodeUrl;
    return sample(nodes);
  } catch (e) {
    console.log(e, chainkey);
  }
};

export default getNodeUrl;
