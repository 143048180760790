import { SvgProps } from 'components/svg/types';
import React from 'react';

const ListIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.6666667,1.33333333 C12.5076158,1.33333333 14,2.8257175 14,4.66666667 L14,11.3333333 C14,13.1742825 12.5076158,14.6666667 10.6666667,14.6666667 L5.33333333,14.6666667 C3.49238417,14.6666667 2,13.1742825 2,11.3333333 L2,4.66666667 C2,2.8257175 3.49238417,1.33333333 5.33333333,1.33333333 L10.6666667,1.33333333 Z M10.6666667,2.66666667 L5.33333333,2.66666667 C4.26821274,2.66666667 3.39755941,3.49927998 3.33672846,4.54915147 L3.33333333,4.66666667 L3.33333333,11.3333333 C3.33333333,12.3984539 4.16594664,13.2691073 5.21581814,13.3299382 L5.33333333,13.3333333 L10.6666667,13.3333333 C11.7317873,13.3333333 12.6024406,12.50072 12.6632715,11.4508485 L12.6666667,11.3333333 L12.6666667,4.66666667 C12.6666667,3.60154608 11.8340534,2.73089275 10.7841819,2.6700618 L10.6666667,2.66666667 Z M10,9.33333333 C10.3681898,9.33333333 10.6666667,9.63181017 10.6666667,10 C10.6666667,10.3681898 10.3681898,10.6666667 10,10.6666667 L6,10.6666667 C5.63181017,10.6666667 5.33333333,10.3681898 5.33333333,10 C5.33333333,9.63181017 5.63181017,9.33333333 6,9.33333333 L10,9.33333333 Z M10,5.33333333 C10.3681898,5.33333333 10.6666667,5.63181017 10.6666667,6 C10.6666667,6.36818983 10.3681898,6.66666667 10,6.66666667 L6,6.66666667 C5.63181017,6.66666667 5.33333333,6.36818983 5.33333333,6 C5.33333333,5.63181017 5.63181017,5.33333333 6,5.33333333 L10,5.33333333 Z"
        // fill="#6A6991"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default ListIcon;
