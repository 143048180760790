import { CHAINKEY } from 'config/constants/chain_config';
import { routerContractAddress } from 'config/vault/address';
import { Contract, ethers } from 'ethers';
import { IMultiTokenItem } from 'state/types';
import { callWithEstimateGas } from 'wallet/estimateGas';
export const onPressCallApprove = async ({
  routerContract,
  erc20Contract,
  fromchain,
  fromtoken,
  toToken,
  tochain,
  value,
  library,
  account,
  wantAddressContract,
}: {
  erc20Contract: Contract;
  routerContract: Contract;
  wantAddressContract: Contract;
  fromchain: CHAINKEY;
  fromtoken: IMultiTokenItem;
  toToken: IMultiTokenItem;
  tochain: CHAINKEY;
  value: string;
  account: string;
  library: any;
}) => {
  // deposit
  // const dstChainId = chainIdsList[tochain]; // Stargate/LayerZero chainId
  // const _refundAddress = account; // message refund address if overpaid
  // console.log(333, fromchain, tochain);
  if (fromchain === tochain) {
    try {
      const res = await callWithEstimateGas(fromchain, wantAddressContract, 'approve', [
        toToken.address,
        ethers.constants.MaxUint256,
      ]);
      return {
        isCrossChain: false,
        txHash: res?.receipt?.transactionHash,
      };
    } catch (e) {
      return {
        isCrossChain: false,
        txHash: null,
      };
    }
  } else {
    try {
      const res = await callWithEstimateGas(fromchain, erc20Contract, 'approve', [
        routerContractAddress[fromchain],
        ethers.constants.MaxUint256,
      ]);
      return {
        isCrossChain: true,
        txHash: res?.receipt?.transactionHash,
      };
    } catch (e) {
      return {
        isCrossChain: true,
        txHash: null,
      };
    }
  }
};
