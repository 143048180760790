import { CHAINKEY } from 'config/constants/chain_config';
import { wallet_config } from 'config/wallet';

export function getLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainkey: CHAINKEY,
): string {
  // console.log({ chainkey });
  const pre = wallet_config[chainkey].blockExplorerUrl;
  switch (type) {
    case 'transaction': {
      return `${pre}/tx/${data}`;
    }
    case 'token': {
      return `${pre}/token/${data}`;
    }
    case 'block': {
      return `${pre}/block/${data}`;
    }
    case 'countdown': {
      return `${pre}/block/countdown/${data}`;
    }
    default: {
      return `${pre}/address/${data}`;
    }
  }
}
