import { ChosedChainAndDAppType, IDApp, VaultMatadateType } from 'state/types';
import { IVault } from './types';
export const isNaNString = (num: string | number) => {
  if (`${num}` === 'NaN') {
    return '';
  }
  return `${num}`;
};
export const getFromaterParmas = ({ obj, dapps, chain }: { obj: any; dapps: IDApp; chain: string }) => {
  let vaultsData: IVault[] = [];
  const len = [];
  if (!obj[chain]) {
    obj[chain] = {};
  }
  for (let i = 0; i < dapps.length; i++) {
    const dapp = dapps[i];
    const preDApp = {};
    if (Object.keys(obj[chain]).length) {
      Object.keys(obj[chain]).forEach((v: string) => {
        preDApp[v] = obj[chain][v];
      });
    }
    obj[chain] = {
      ...preDApp,
      [dapp.dappname]: {},
    };
    len.push([i, dapp.contract.length]);
    vaultsData = vaultsData.concat(dapp.contract);
  }
  return {
    obj,
    vaultsData,
    len,
  };
};
export const getVaultDataByChosedData = ({
  chosedChainAndDApp,
  allVault,
  init,
}: {
  chosedChainAndDApp: ChosedChainAndDAppType;
  allVault: VaultMatadateType;
  init?: boolean;
}): VaultMatadateType => {
  const data = {} as VaultMatadateType;
  for (const chainItem of chosedChainAndDApp) {
    if (allVault[chainItem.chain]) {
      const item = allVault[chainItem.chain];

      // data[chainItem.chain]['chain'] = item.chain;
      const chosedDApp = chainItem.dapp;
      const allDApp = allVault[chainItem.chain].dapp;
      const _dapp = [];
      for (const dappItem of allDApp) {
        // console.log(444222, { chosedDApp });
        if (chosedDApp.includes(dappItem.dappname)) {
          let contract = dappItem.contract;

          if (init) {
            contract = contract.map((v) => {
              return {
                ...v,
                contractAddress: v.contractAddress.toLowerCase(),
                vault: {
                  ...v.vault,
                  wantaddress: v.vault.wantaddress.toLowerCase(),
                  earnedaddress: v.vault.earnedaddress.toLowerCase(),
                  avaaddress: v.vault.avaaddress.toLowerCase(),
                  token0address: (v?.vault?.token0address ?? '').toLowerCase(),
                  token1address: (v?.vault?.token1address ?? '').toLowerCase(),
                  scale: '-',
                  // totalSupply: '-',
                  // balance: '-',
                  tvl: '-',
                  lpToCLpRate: '-',
                  total_apr: '-',
                  total_apy: '-',
                  base_apr: '-',
                  base_apy: '-',
                  reward_apr: '-',
                  reward_apy: '-',
                },
              };
            });
          }
          _dapp.push({
            contract,
            dappname: dappItem.dappname,
          });
        }
      }
      data[chainItem.chain] = {
        status: item.status,
        chain: item.chain,
        dapp: _dapp,
      };
      // data[chainItem.chain]['dapp'] = _dapp;
    }
  }
  return data;
};

export const initVaultData = ({ vaultsConfig }: { vaultsConfig: VaultMatadateType }): VaultMatadateType => {
  const data = {} as VaultMatadateType;
  const _vaultsConfig = Object.keys(vaultsConfig);
  for (const chain of _vaultsConfig) {
    const item = vaultsConfig[chain];
    // data[chain]['chain'] = item.chain;
    const allDApp = vaultsConfig[chain].dapp;
    const _dapp = [];
    for (const dappItem of allDApp) {
      let contract = dappItem.contract;
      contract = contract.map((v) => {
        return {
          ...v,
          contractAddress: v.contractAddress.toLowerCase(),
          vault: {
            ...v.vault,
            wantaddress: v.vault.wantaddress.toLowerCase(),
            earnedaddress: v.vault.earnedaddress.toLowerCase(),
            avaaddress: v.vault.avaaddress.toLowerCase(),
            token0address: (v?.vault?.token0address ?? '').toLowerCase(),
            token1address: (v?.vault?.token1address ?? '').toLowerCase(),
            scale: '-',
            // totalSupply: '-',
            // balance: '-',
            tvl: '-',
            lpToCLpRate: '-',
            total_apr: '-',
            total_apy: '-',
            base_apr: '-',
            base_apy: '-',
            reward_apr: '-',
            reward_apy: '-',
          },
        };
      });
      _dapp.push({
        contract,
        dappname: dappItem.dappname,
      });
    }
    data[chain] = {
      status: item.status,
      chain: item.chain,
      dapp: _dapp,
    };
    // data[chain]['dapp'] = _dapp;
  }
  return data;
};
