import { CHAINKEY } from 'config/constants/chain_config';

export const chainIdsList = {
  [CHAINKEY.ETH_MAINNET]: 101,
  [CHAINKEY.BSC_MAINNET]: 102,
  [CHAINKEY.AVALANCH_MAINNET]: 106,
  [CHAINKEY.POLYGON_MAINNET]: 109,
  [CHAINKEY.ARBITRUM_MAINNET]: 110,
  [CHAINKEY.OPTIMISTIC_MAINNET]: 111,
  [CHAINKEY.FANTOM_MAINNET]: 112,
};
