export const updateHistory = async ({ item, account }) => {
  let data = null;
  while (!data) {
    data = await fetchUpdateHistory({ item, account });
  }
  return data;
};

const fetchUpdateHistory = async ({ item, account }) => {
  const data = await fetch(
    process.env.REACT_APP_GRAPH_API_PROFILE +
      '/api/v1/multi/update/history?account=' +
      account +
      '&&item=' +
      JSON.stringify({ item }),
    {
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
    },
  );
  return data;
};
