import { CHAINKEY } from 'config/constants/chain_config';
import { useMemo } from 'react';
import { ITradeEventType, ITradeType, tradeRouter } from 'views/Trade/constants';

export const useTradeRouter = ({
  fromchainkey,
  tochainkey,
  dappname,
  setFromChain,
  settochain,
  tradeEventType,
}: {
  fromchainkey: CHAINKEY;
  tochainkey: CHAINKEY;
  dappname: string;
  setFromChain: any;
  settochain: any;
  tradeEventType: ITradeEventType; // 1 deposit 2  avault withdraw  3 erc20 withdraw
}) => {
  return useMemo(() => {
    if (!fromchainkey || !tochainkey) {
      return {
        obj: {
          swap: '',
          bridge: '',
          tradetype: ITradeType.type_0,
        },
      };
    }
    // console.log({ fromchainkey, tochainkey, dappname });
    const tradeRouterFromChainList = Object.keys(tradeRouter);

    const _fromchain = tradeRouterFromChainList.includes(fromchainkey) ? fromchainkey : tradeRouterFromChainList[0];
    if (_fromchain !== fromchainkey) {
      setFromChain(_fromchain);
    }
    const _fromObj = tradeRouter[_fromchain];
    const tradeRoutertochainList = Object.keys(_fromObj);
    const _tochain = tradeRoutertochainList.includes(tochainkey) ? tochainkey : tradeRoutertochainList[0];
    if (_tochain !== tochainkey) {
      settochain(_tochain);
    }
    // console.log({ _tochain, dappname });
    try {
      const obj = _fromObj[_tochain][dappname][tradeEventType];
      return {
        obj,
      };
    } catch (e) {
      const _obj = Object.values(tradeRouter[fromchainkey][tochainkey]);
      return {
        obj: _obj[0][tradeEventType],
      };
    }
    // console.log(tradeRouter, fromchainkey, tochainkey, dappname);
  }, [fromchainkey, tochainkey, dappname, setFromChain, settochain, tradeEventType]);
};
