import { useEffect } from 'react';
import { useListDetail } from '../hooks/useListDetail';

const VaultMultiTransactionListDetail = () => {
  const detail = useListDetail();
  useEffect(() => {
    if (detail) {
      console.log(detail);
    }
  }, [detail]);
  return <h1>ddd</h1>;
};
export default VaultMultiTransactionListDetail;
