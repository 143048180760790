import { STATIC_BASE_URL } from 'config';
import { FC, memo } from 'react';
import styled from 'styled-components';
const HeaderLogo: FC<{ collapsed: boolean }> = ({ collapsed }) => {
  return (
    <LogoStyle href="/">
      <img src={collapsed ? `${STATIC_BASE_URL}/images/logo_small.svg` : `${STATIC_BASE_URL}/images/logo.svg`} alt="" />
    </LogoStyle>
  );
};

const LogoStyle = styled.a`
  display: block;
  margin-right: 0;
  margin-top: 0;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 0;
    margin-right: 48px;
  }
  img {
    display: block;
    height: 34px;
    width: 34px;
    ${({ theme }) => theme.mediaQueries.md} {
      width: 120px;
      // height: 40px;
    }
  }
`;
function updateJSX(prevProps, nextProps) {
  if (prevProps.collapsed === nextProps.collapsed) {
    return true;
  }
  return false;
}
export default memo(HeaderLogo, updateJSX);
