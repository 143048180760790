import { CHAINKEY } from 'config/constants/chain_config';
import { useMatchBreakpoints, useModal } from '@my/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigItem from './components/BigItem';
import SmallItem from './components/SmallItem';
import ShowStatusModal, { IShowStatusModal } from './ShowStatusModal';
import { VaultMultiTransactionListStyled } from './styled';
import { ITradeType } from 'views/Trade/constants';
import { useHistoryMultiGet, useVaultHistoryState } from 'state/vaultHistory/hooks';
import PageLoader from 'components/Loader/PageLoader';
// import { useJumpPath } from 'hooks/useJumpPath';
// import { routePath } from 'config/constants/meta';

const tab = ['Deposit', 'Withdraw'];
const VaultMultiTransactionList = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tdIndex, setTdIndex] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { vaultHistory, vaultHistoryIsLoading } = useVaultHistoryState();
  const showData = useMemo(() => {
    return vaultHistory.filter((v) => (tabIndex === 0 ? Number(v.historytype) === 1 : Number(v.historytype) !== 1));
  }, [tabIndex, vaultHistory]);
  const vaultHistoryLen = useMemo(() => {
    if (vaultHistory && vaultHistory.length) {
      if (showData[0]) {
        return true;
      }
    }
    return false;
  }, [vaultHistory, showData]);
  useHistoryMultiGet();
  useEffect(() => {
    if ([isXs, isSm, isMd].some(Boolean)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isXs, isSm, isMd]);
  const modeData = useMemo(() => {
    if (vaultHistoryLen) {
      return showData[tdIndex];
    }
    return null;
  }, [showData, tdIndex, vaultHistoryLen]);
  console.log({ modeData });
  const [onPresetModal] = useModal(
    <ShowStatusModal
      collapsed={collapsed}
      status={modeData ? modeData?.status : 999}
      tradetype={modeData ? (modeData?.tradetype as ITradeType) : ITradeType.type_0}
      startvalue={modeData ? modeData?.startvalue : ''}
      fromtoken={modeData ? modeData?.fromtoken : ''}
      fromchain={modeData ? (modeData?.fromchain as CHAINKEY) : CHAINKEY.ARBITRUM_MAINNET}
      fromtokensymbol={modeData ? modeData?.fromtokensymbol : ''}
      totokenaddress={modeData ? modeData?.totokenaddress : ''}
      totokensymbol={modeData ? modeData?.totokensymbol : ''}
      toaddressusdc={modeData ? modeData?.toaddressusdc : ''}
      toaddressusdcsymbol={modeData ? modeData?.toaddressusdcsymbol : ''}
      tochain={modeData ? (modeData?.tochain as CHAINKEY) : CHAINKEY.ARBITRUM_MAINNET}
      todappname={modeData ? modeData?.todappname : ''}
      label={tab[tabIndex]}
      endvalue={modeData ? modeData?.endvalue : ''}
      swapname={modeData ? modeData?.swapname : ''}
      swapendvalue={modeData ? modeData?.swapendvalue : ''}
      bridgekey={modeData ? modeData?.bridgekey : ''}
      bridgename={modeData ? modeData?.bridgename : ''}
      bridgeendvalue={modeData ? modeData?.bridgeendvalue : ''}
      fromtx={modeData ? modeData?.fromtx : ''}
      totx={modeData ? modeData?.totx : ''}
      gastotalfee={modeData ? modeData?.gastotalfee : ''}
      arrivaltotaltime={modeData ? modeData?.arrivaltotaltime : ''}
      vaultaddress={modeData ? modeData?.vaultaddress : ''}
      chainkeytx={modeData?.chainkeytx}
      chainkey={modeData?.chainkey as CHAINKEY}
    />,
    true,
    true,
    'ShowStatusModal4444',
  );
  // const { jump } = useJumpPath(
  //   `${routePath.vault_transaction_list_detail.pathPre}/${vaultHistoryLen ? vaultHistory[tdIndex]?.fromtx : ''}`,
  // );

  const onPressModal = useCallback(
    (index: number, e) => {
      setTdIndex(index);
      onPresetModal();
      // jump(e);
    },
    [setTdIndex, onPresetModal],
  );
  return (
    <VaultMultiTransactionListStyled>
      <div className="tab_header">
        <h2>Transactions List</h2>
        <div className="tab">
          {['Deposit', 'Withdraw'].map((v, index: number) => (
            <p className={tabIndex === index ? 'on' : ''} key={v} onClick={() => setTabIndex(index)}>
              {v}
            </p>
          ))}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {['Source Token', 'Destination Token', 'Bridge', 'Status', 'Time'].map((v) => (
              <td key={v}>{v}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {!vaultHistoryLen && !vaultHistoryIsLoading ? (
            <tr>
              <td>NoDate</td>
            </tr>
          ) : (
            showData.map((item: IShowStatusModal, index: number) =>
              collapsed ? (
                <SmallItem key={index} onPressModal={onPressModal} index={index} item={item} />
              ) : (
                <BigItem key={index} onPressModal={onPressModal} index={index} item={item} />
              ),
            )
          )}
        </tbody>
      </table>
      {vaultHistoryIsLoading ? <PageLoader /> : null}
    </VaultMultiTransactionListStyled>
  );
};

export default VaultMultiTransactionList;
