import { JsonFragment } from '@ethersproject/abi';
import { ethers } from 'ethers';
import multiCallAbi from '../config/abi/Multicall.json';

import addresses from 'config/constants/contracts';
import { CHAINKEY } from 'config/constants/chain_config';
import getNodeUrl from 'wallet/getRpcUrl';

export interface Call {
  address: string;
  name: string;
  params?: any[];
}
export class RPCApi {
  id = 0;
  endpoint: string;
  chainkey: CHAINKEY;
  abi: ReadonlyArray<JsonFragment> | undefined;
  interface: ethers.utils.Interface | undefined;
  simpleRpcProvider: ethers.providers.JsonRpcProvider;
  constructor({ chainkey }: { chainkey: CHAINKEY }) {
    this.chainkey = chainkey;
    this.interface = new ethers.utils.Interface(multiCallAbi);
    this.endpoint = getNodeUrl(chainkey);
    this.simpleRpcProvider = new ethers.providers.JsonRpcProvider(this.endpoint);
  }
  private get nextId(): number {
    return ++this.id;
  }

  public async multicall(abi: ReadonlyArray<JsonFragment>, calls: Call[]) {
    try {
      const itf = new ethers.utils.Interface(abi);
      const calldata = calls.map((call) => [
        call.address.toLowerCase(),
        itf.encodeFunctionData(call.name, call.params),
      ]);
      // console.log(calldata, calls);
      const result = await this.fetch('eth_call', [
        {
          to: addresses.multiCall[this.chainkey],
          data: this.interface?.encodeFunctionData('aggregate', [calldata]),
        },
        'latest',
      ]);
      // const result =
      //   '0x0000000000000000000000000000000000000000000000000000000001553f980000000000000000000000000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000000';
      // @ts-ignore
      const { returnData } = this.interface?.decodeFunctionResult('aggregate', result);
      const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call));
      return res;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  private async fetch(method: string, params: unknown[] = []) {
    const res = await fetch(this.endpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        method,
        params,
        id: this.nextId,
      }),
    });
    const r = await res.json();
    const { result, error } = r;
    if (error) {
      console.error(error);
      throw new Error(`RPC error: ${error?.message}`);
    }
    return result;
  }
}
