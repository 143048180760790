import { CHAINKEY } from 'config/constants/chain_config';
import { Input } from '@my/ui';
import ArrowLeftIcon from 'components/svg/ArrowLeftIcon';
import { IMultiTokenItem } from 'state/types';
import { getChainImage, getImageUrlFromToken } from 'utils';
import SizedImg from './sizedImg';
import { ChosedModalStyled } from '../views/styled';
import { memo, useCallback, useState } from 'react';

const ChoseModal = ({
  showChainList,
  setShowChainList,
  chainList,
  setChosedChain,
  showTokenList,
  setShowTokenList,
  tokenList,
  setChosedToken,
  chosedChain,
  chosedToken,
  accountAddress,
  title,
}) => {
  const [value, setValue] = useState<string>('');
  const onChangeStateVaule = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const _value = e.currentTarget.value.replace(/,/g, '.');
    if (e.currentTarget.validity.valid) {
      setValue(_value);
    }
  }, []);
  return (
    <ChosedModalStyled>
      <h3
        onClick={() => {
          setShowChainList(false);
          setShowTokenList(false);
        }}
      >
        <ArrowLeftIcon />
        Select {title}
      </h3>
      <Input
        className="input"
        value={value}
        placeholder={`Search or enter ${title} adress`}
        onChange={onChangeStateVaule}
      />
      {showChainList ? (
        <div className="list" onClick={() => setShowChainList(false)}>
          {(chainList || [])
            .filter((v: string) => v.toLowerCase().indexOf(value) > -1)
            .map((v: string) => (
              <div
                key={v}
                className={`${v === chosedChain ? 'on' : ''} item chain`}
                onClick={() => setChosedChain(v as CHAINKEY)}
              >
                <SizedImg src={getChainImage(v)} alt={v} />
                <p>{v}</p>
              </div>
            ))}
        </div>
      ) : null}
      {showTokenList ? (
        <div className="list" onClick={() => setShowTokenList(false)}>
          {(tokenList || [])
            .filter((v: string) => v.toLowerCase().indexOf(value) > -1)
            .map((v: IMultiTokenItem) => (
              <div
                key={v.address}
                className={v.address === chosedToken.address ? 'on item' : 'item'}
                onClick={() => setChosedToken(v)}
              >
                <div className="fl">
                  <SizedImg src={getImageUrlFromToken(v.address, chosedChain)} alt={v.symbol} />
                  <p>{v.symbol}</p>
                </div>
                <p>{v?.balances[accountAddress]?.localNumber || '--'}</p>
              </div>
            ))}
        </div>
      ) : null}
    </ChosedModalStyled>
  );
};
export default memo(ChoseModal);
