import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import {
  NoEthereumProviderError as NoTalismanProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorTalisman,
} from '@talismn/web3react-v6-connector';
import { ConnectorNames, connectorLocalStorageKey } from '@my/ui';
import { setupNetwork } from 'config/wallet';
import useToast from 'hooks/useToast';
import { UAuthConnector } from '@uauth/web3-react';
import { SubWalletConnector } from '@subwallet/web3-react-subwallet-connector-v6';
import { connectorsByName } from 'wallet/web3React';
import { CHAINKEY } from 'config/constants/chain_config';
const useAuth = ({ chainkey }: { chainkey: CHAINKEY }) => {
  const { activate, deactivate } = useWeb3React();
  const { toastError } = useToast();

  const login = useCallback(
    (connectorID: ConnectorNames, clickBtn?: boolean) => {
      const connector = connectorsByName[connectorID];
      if (connector) {
        (async () => {
          const chainId = window?.ethereum?.networkVersion ?? '';

          if (connector instanceof InjectedConnector) {
            if (!window?.ethereum?.networkVersion) {
              if (clickBtn) {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn');
              }
              return;
            }
          }
          if (connector instanceof UAuthConnector) {
            if (!window?.ethereum?.networkVersion) {
              if (clickBtn) {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn');
              }
              return;
            }
            if (chainId && Number(chainId)) {
              await setupNetwork(connector, chainkey);
            }
          }
          if (connector instanceof SubWalletConnector) {
            if (window?.injectedWeb3 && window.injectedWeb3['subwallet-js']) {
            } else {
              if (clickBtn) {
                window.open(
                  'https://chrome.google.com/webstore/detail/subwallet-polkadot-extens/onhogfjeacnfoofkfgppdlbmlmnplgbn',
                );
              }
              return;
            }
            const activate = await connector.activate();
            if (!activate) {
              // await wait(5000);
            }
            const isAuthorized = await connector.isAuthorized();
            if (!isAuthorized) {
              // await wait(5000);
            }
          }
          activate(connector, async (error: Error) => {
            console.log({ error });
            if (error instanceof UnsupportedChainIdError) {
              const hasSetup = await setupNetwork(connector, chainkey);
              console.log({ hasSetup });
              if (hasSetup) {
                activate(connector);
              }
            } else {
              window.localStorage.removeItem(connectorLocalStorageKey);
              if (
                error instanceof NoEthereumProviderError ||
                error instanceof NoTalismanProviderError ||
                error instanceof NoBscProviderError
              ) {
                if (error instanceof NoTalismanProviderError) {
                  window.open(
                    'https://chrome.google.com/webstore/detail/talisman-wallet/fijngjgcjhjmmpcmkeiomlglpeiijkld',
                  );
                }
                toastError('Provider Error', 'No provider was found');
              } else if (
                error instanceof UserRejectedRequestErrorInjected ||
                error instanceof UserRejectedRequestErrorTalisman ||
                error instanceof UserRejectedRequestErrorWalletConnect
              ) {
                if (connector instanceof WalletConnectConnector) {
                  const walletConnector = connector as WalletConnectConnector;
                  walletConnector.walletConnectProvider = null;
                }
                toastError('Authorization Error', 'Please authorize to access your account');
              } else {
                toastError(error.name, error.message);
              }
            }
          });
        })();
        // }
      } else {
        toastError('Unable to find connector', 'The connector config is wrong');
      }
    },
    // eslint-disable-next-line
    [activate, window?.ethereum?.networkVersion],
  );

  const logout = useCallback(() => {
    deactivate();
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close();
      connectorsByName.walletconnect.walletConnectProvider = null;
    }
    window?.localStorage?.removeItem(connectorLocalStorageKey);
  }, [deactivate]);

  return { login, logout };
};
export default useAuth;
