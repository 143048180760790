import BigNumber from 'bignumber.js';
import { fetchVaultABIAmountMulti } from './multi/fetchVaultAddressMulti';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import { fetchApy, INetValueKeyItemItem, nowDate, preDate } from './multi/fetchApy';
import { VaultMatadateType } from 'state/types';
import { IEventType } from './types';
import { isNaNString } from './utils';
import { symbolUtils } from 'utils/symbolFormmat';

const fetchVaultsV2 = async (
  priceVsBusdMapMulti: Record<string, string>,
  vaultsData: VaultMatadateType,
): Promise<[VaultMatadateType, string]> => {
  // base
  // ----------------AVVATADDRESS Farm----------
  // const {
  //   masterChef,
  //   name,
  //   symbol,
  //   pid,
  //   wantaddress,
  //   token0address,
  //   token1address,
  //   earnedaddress,
  //   avaaddress,
  //   vaultDecimals,
  // } = await fetchVaultABIBaseMulti(vaultsData);
  // console.log({
  //   masterChef,
  //   name,
  //   symbol,
  //   pid,
  //   wantaddress,
  //   token0address,
  //   token1address,
  //   earnedaddress,
  //   avaaddress,
  //   vaultDecimals,
  // });
  // ----------------AVVATADDRESS SingleToken----------
  // const { name, symbol, wantaddress, earnedaddress, avaaddress, vaultDecimals } = await fetchVaultABIBaseSingleToken(
  //   vaultsData,
  // );
  // console.log({
  //   name,
  //   symbol,
  //   wantaddress,
  //   earnedaddress,
  //   avaaddress,
  //   vaultDecimals,
  // });

  // // pid: (22) [21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  const obj = {} as VaultMatadateType;
  const _fetchVaultABIAmount = await fetchVaultABIAmountMulti(vaultsData);
  // ----------------AVVATADDRESS  end----------
  // -------------MASTRETADDRESS--------
  // const { poolWeight, multiplier } = await fetchMasterChefABI(currentBlock, vaultsData);
  //-----------------Farm----------
  // const { lpAddressDecimals, tokenDecimals, quoteTokenDecimals, lpSymbol } = await fetchFarmDataABIBase(vaultsData);
  // const {
  //   // tokenAmountMc,
  //   // tokenAmountTotal,
  //   // quoteTokenAmountMc,
  //   // quoteTokenAmountTotal,
  //   // lpTotalSupply,
  //   // lpTotalInQuoteToken,
  //   liquidity,
  //   // tokenPriceVsQuote,
  //   // lpTokenPrice,
  // } = await fetchFarmDataABICalc(vaultsData, priceVsBusdMapMulti);
  // calc amount cant stable
  let _total = BIG_ZERO;
  const apyArr = await fetchApy();
  let time = nowDate();

  for (const chain of Object.keys(vaultsData)) {
    const chainObj = vaultsData[chain];
    for (let dappI = 0; dappI < chainObj.dapp.length; dappI++) {
      const dappname = chainObj.dapp[dappI].dappname;
      const { wantLockedTotal, vaultTotalSupply, scale = [] } = _fetchVaultABIAmount[chain][dappname];
      for (let i = 0; i < chainObj.dapp[dappI].contract.length; i++) {
        const __wantLockedTotal = wantLockedTotal[i];
        const __vaultTotalSupply = vaultTotalSupply[i];
        const __scale = scale[i];
        const item = chainObj.dapp[dappI].contract[i];
        let _apyItem: INetValueKeyItemItem = apyArr[chain][item.contractAddress.toLowerCase()][time];
        let ii = 1;
        while (!_apyItem) {
          time = preDate(ii);
          _apyItem = apyArr[chain][item.contractAddress.toLowerCase()][time];
          ii++;
        }
        const _wantaddress = symbolUtils(item.vault.wantaddresssymbol);
        // console.log(__wantLockedTotal, _wantaddress, priceVsBusdMapMulti[_wantaddress]);
        const _liquidity = new BigNumber(__wantLockedTotal)
          .div(BIG_TEN.pow(new BigNumber(item.vault.decimals)))
          .times(Number(priceVsBusdMapMulti[_wantaddress]))
          .toNumber();

        _total = _total.plus(_liquidity);
        const _scale = item.eventtype === IEventType.normal ? '1' : __scale || '1';
        const _lpToCLpRate =
          __wantLockedTotal && __vaultTotalSupply && __wantLockedTotal > 0 && __vaultTotalSupply > 0
            ? new BigNumber(__wantLockedTotal).div(__vaultTotalSupply).toFixed(18)
            : '1';
        const _item = {
          ...item,
          vault: {
            ...item.vault,
            total_apr: _apyItem.total_apr,
            total_apy: _apyItem.total_apy,
            base_apr: _apyItem.base_apr,
            base_apy: _apyItem.base_apy,
            reward_apr: _apyItem.reward_apr,
            reward_apy: _apyItem.reward_apy,
            wantLockedTotal: __wantLockedTotal,
            totalSupply: __vaultTotalSupply,
            scale: _scale,
            tvl: _liquidity.toLocaleString('en-US', {
              maximumFractionDigits: 2,
            }),
            lpToCLpRate: _lpToCLpRate,
          },
          farm: {
            ...item.farm,
          },
        };
        if (!obj[chain]) {
          obj[chain] = {
            chain: chain,
            status: 1,
            dapp: [
              {
                dappname: dappname,
                contract: [{ ...item }],
              },
            ],
          };
        }
        if (!obj[chain].dapp[dappI]) {
          obj[chain].dapp = [
            ...obj[chain].dapp,
            {
              dappname: dappname,
              contract: [{ ...item }],
            },
          ];
        }
        obj[chain].dapp[dappI].contract[i] = _item;
      }
    }

    // console.log({ _apyItem }, item.contractAddress.toLowerCase(), time);
    // const priceAddress =
    //   item.dappname === IDAppName.arthswap ? tokens[chainkey].arsw.address.toLowerCase() : '';
    // const { kacRewardsApr, kacRewardApy } = getFarmApr(
    //   new BigNumber(perBlock[i]),
    //   new BigNumber(poolWeight[i]),
    //   new BigNumber(priceVsBusdMap[priceAddress] ?? '1'),
    //   new BigNumber(liquidity[i]),
    //   item.lpDetail.address,
    // );
    // const feeApr: number = vault_fee_apr[`${item.vault.symbol}`];
    // const rewardApy = aprToApy(feeApr);
  }
  const total = isNaNString(_total.toString());
  return [obj, total];
};

export default fetchVaultsV2;
