import { CHAINKEY } from 'config/constants/chain_config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { wallet_config } from 'config/wallet';
import { useSafeContractStateAddress, getSafeProxyFactoryContractAddress } from './fetch/safeContractServices';
import { calculateUserAddress } from './utils/utils';
import { SafeContractState } from 'state/types';
import { sigValidationModuleAddress, ModuleEnablerAddress } from 'config/vault/address';

export const fetchSafeContractAsync = createAsyncThunk<{}, { chainList: CHAINKEY[]; account: string }>(
  'safeContract/fetchSafeContractAsync',
  async ({ chainList, account }) => {
    // async ({ chainList, account: _ }) => {
    //   const account = '0x41BA3387E1a5a592E27B9EE33935957CE5F872C1';
    const safeProxyFactoryContractAddresses = await getSafeProxyFactoryContractAddress();
    const safeContractAddresses = await useSafeContractStateAddress();
    const obj = {
      [account]: {},
    };
    for (const res of chainList) {
      const chain = wallet_config[res];
      const safeProxyFactoryContractAddress = safeProxyFactoryContractAddresses.networkAddresses[`${chain.chainIdInt}`];
      const safeContractAddress = safeContractAddresses.networkAddresses[`${chain.chainIdInt}`];
      // SAFE_FACTORY_ADDRESS
      const from = safeProxyFactoryContractAddress;
      // SAFE_ADDRESS
      const safeSingleton = safeContractAddress;
      const MODULE = sigValidationModuleAddress[res] || '0xcd399c703ad32f6790c41d617D078b66DD0A4C02';
      const MODULE_ENABLER = ModuleEnablerAddress[res] || '0x26E4c07dE59fc4f7e1B972721418aa5BD5F126F7';
      const address = calculateUserAddress({
        safeSingleton,
        from,
        account,
        MODULE,
        MODULE_ENABLER,
        chain: res,
      });
      obj[account] = {
        ...obj[account],
        [res]: address,
      };
    }
    return obj;
  },
);
export const SafeContractExtraReducers = (builder) => {
  builder.addCase(fetchSafeContractAsync.fulfilled, (state: SafeContractState, action) => {
    state.safeAddress = action.payload;
  });
};
