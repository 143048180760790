import { CHAINKEY } from 'config/constants/chain_config';

export const poolIdsList = {
  [CHAINKEY.ETH_MAINNET]: { USDC: 1, USDT: 2, ETH: 13 },
  [CHAINKEY.BSC_MAINNET]: {
    USDT: 2,
    BUSD: 5,
  },
  [CHAINKEY.AVALANCH_MAINNET]: {
    USDC: 1,
    USDT: 2,
  },
  [CHAINKEY.POLYGON_MAINNET]: {
    USDC: 1,
    USDT: 2,
  },
  [CHAINKEY.ARBITRUM_MAINNET]: {
    USDC: 1,
    USDT: 2,
    ETH: 13,
  },
  [CHAINKEY.OPTIMISTIC_MAINNET]: {
    USDC: 1,
    ETH: 13,
  },
  [CHAINKEY.FANTOM_MAINNET]: {
    USDC: 1,
  },
};
