import { createSlice } from '@reduxjs/toolkit';
import { CHAINKEY } from 'config/constants/chain_config';
import { wallet_config } from 'config/wallet';
import { chain_key_localstorage, getLocalStorage } from 'utils/localStorage';
import GlobalExtraReducers from './actions';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    chainkey: CHAINKEY.OPTIMISTIC_MAINNET,
  },
  reducers: {
    getChainKeyState: (state) => {
      state.chainkey = getChainKey();
      console.log('state.chainkey: ', state.chainkey);
    },
    setChainKeyState: (state, action) => {
      state.chainkey = action.payload;
    },
  },
  extraReducers: GlobalExtraReducers,
});
export const { getChainKeyState, setChainKeyState } = globalSlice.actions;
export const getChainKey = (): CHAINKEY => {
  const chainId = window.ethereum?.networkVersion || '';
  // console.log({ chainId });
  if (chainId) {
    const chainIndex = Object.values(wallet_config)
      .map((v) => v.chainIdInt)
      .indexOf(Number(chainId));
    // console.log(1111, { chainId, chainIndex });
    if (chainIndex > -1) {
      const chainkeyList = Object.keys(wallet_config);
      return chainkeyList[chainIndex] as CHAINKEY;
    }
  }
  const local = getLocalStorage(chain_key_localstorage);
  if (local) {
    return local;
  }
  return CHAINKEY.OPTIMISTIC_MAINNET;
};
export default globalSlice.reducer;
