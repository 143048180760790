export enum ChainId {
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 3,
  Ontology = 5851,
  // todo
  ETH_RINKEBY = 4,
  // ----
  OPTIMISTIC_MAINNET = 10,
  BSC_MAINNET = 56,
  Celo_MAINNET = 42220,
  OEC_MAINNET = 66,
  MoonbaseAlpha_MAINNET = 1287,
  Clover_Mainnet = 1024,
  OPTIMISTIC_TESTNET = 69,
  ASTR_TESTNET = 81,
  SDN_TESTNET = 82,
  BSC_TESTNET = 87,

  XDAI_MAIN = 100,

  HECO_MAINNET = 128,
  POLYGON_MAINNET = 137,
  FANTOM_MAINNET = 250,
  BOBA_MAINNET = 288,
  SHIDEN_MAINNET = 336,
  THETA_MAINNET = 361,
  THETA_TESTNET = 365,
  ASTR_MAINNET = 592,

  SDN_MAINNET = 336,

  FANTOM_TESTNET = 4_002,
  ARBITRUM_MAINNET = 42_161,
  AVAX_MAIN = 43_114,
  ARBITRUM_TESTNET = 421_611,
  POLYGON_TESTNET = 80_001,
  AVALANCH_MAINNET = 43_114,
  AVALANCH_TESTNET = 43_113,
}

export enum CHAINKEY {
  ETH_MAINNET = 'ethereum',
  ETH_ROPSTEN = 'ethereum_ropsten',
  ETH_RINKEBY = 'ethereum_rinkeby',
  OPTIMISTIC_MAINNET = 'optimism',
  BSC_MAINNET = 'bsc',
  // ONTOLOGY_MAINNET = 'Ontology',
  OEC_MAINNET = 'oec',
  XDAI_MAIN = 'xdai',
  HECO_MAINNET = 'heco',
  POLYGON_MAINNET = 'polygon',
  FANTOM_MAINNET = 'fantom',
  BOBA_MAINNET = 'boba',
  SHIDEN_MAINNET = 'shiden',
  THETA_MAINNET = 'theta_mainnet',
  ASTR_MAINNET = 'astar',
  SDN_MAINNET = 'sdn_mainnet',
  ARBITRUM_MAINNET = 'arbitrum',
  AVAX_MAIN = 'avalanche',
  AVALANCH_MAINNET = 'avalanch_mainnet',

  OPTIMISTIC_TESTNET = 'optimistic_testnet',
  ASTR_TESTNET = 'ASTR_TESTNET',
  SDN_TESTNET = 'SDN_TESTNET',
  BSC_TESTNET = 'BSC_TESTNET',
  THETA_TESTNET = 'THETA_TESTNET',
  FANTOM_TESTNET = 'FANTOM_TESTNET',
  ARBITRUM_TESTNET = 'ARBITRUM_TESTNET',
  POLYGON_TESTNET = 'POLYGON_TESTNET',
  AVALANCH_TESTNET = 'AVALANCH_TESTNET',

  Celo_MAINNET = 'celo',
  // 'OKExChain' = 'OKExChain',
  MoonbaseAlpha_MAINNET = 'moonbase alpha',
  // 'REI' = 'REI',
  // 'Emerald Paratime' = 'Emerald Paratime',
  Clover_Mainnet = 'clover Mainnet',
  // 'Flow' = 'Flow',
  // 'Terra Bombay-12' = 'Terra Bombay-12',
  Ontology = 'ontology',
  // 'Toronto SX' = 'Toronto SX',
}
