// Set of helper functions to facilitate wallet setup
import { TalismanConnector } from '@talismn/web3react-v6-connector';
import { BASE_BSC_SCAN_URL, STATIC_BASE_URL } from 'config';
import { ChainId, CHAINKEY } from './constants/chain_config';

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
type IWalletConfigItem = {
  chainId: string;
  chainIdInt: ChainId;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
    address?: string;
  };
  rpcUrls: string[];
  nodeUrl: string[];
  blockExplorerUrl: string;
};
export const wallet_config: Record<string, IWalletConfigItem> = {
  [CHAINKEY.ETH_MAINNET]: {
    chainName: 'Ethereum Mainnet',
    chainId: `0x${ChainId.ETH_MAINNET.toString(16)}`,
    chainIdInt: ChainId.ETH_MAINNET,
    blockExplorerUrl: 'https://etherscan.io',
    nativeCurrency: { name: 'ETH', symbol: 'eth', decimals: 18, address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nodeUrl: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  },
  [CHAINKEY.ETH_ROPSTEN]: {
    chainName: 'Ethereum Ropsten',
    chainId: `0x${ChainId.ETH_ROPSTEN.toString(16)}`,
    chainIdInt: ChainId.ETH_ROPSTEN,
    blockExplorerUrl: 'https://ropsten.etherscan.io',
    nativeCurrency: { name: 'ETH', symbol: 'eth', decimals: 18, address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nodeUrl: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  },
  [CHAINKEY.ETH_RINKEBY]: {
    chainName: 'Ethereum Rinkeby',
    chainId: `0x${ChainId.ETH_RINKEBY.toString(16)}`,
    chainIdInt: ChainId.ETH_RINKEBY,
    blockExplorerUrl: 'https://rinkeby.etherscan.io',
    nativeCurrency: { name: 'ETH', symbol: 'eth', decimals: 18, address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' },
    rpcUrls: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nodeUrl: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  },
  [CHAINKEY.OPTIMISTIC_MAINNET]: {
    chainName: 'optimism',
    chainId: `0x${ChainId.OPTIMISTIC_MAINNET.toString(16)}`,
    chainIdInt: ChainId.OPTIMISTIC_MAINNET,
    blockExplorerUrl: 'https://optimistic.etherscan.io',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
      address: '0x0000000000000000000000000000000000000000',
    },
    rpcUrls: ['https://opt-mainnet.g.alchemy.com/v2/WoRNbFRbbmBir0LS3rcghvKLuWDcK_vj'],
    nodeUrl: ['https://opt-mainnet.g.alchemy.com/v2/WoRNbFRbbmBir0LS3rcghvKLuWDcK_vj'],
  },
  [CHAINKEY.BSC_MAINNET]: {
    chainId: `0x${ChainId.BSC_MAINNET.toString(16)}`,
    chainIdInt: ChainId.BSC_MAINNET,
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    nodeUrl: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrl: `${BASE_BSC_SCAN_URL}`,
  },
  [CHAINKEY.Celo_MAINNET]: {
    chainId: `0x${ChainId.Celo_MAINNET.toString(16)}`,
    chainIdInt: ChainId.Celo_MAINNET,
    chainName: 'CELO Chain Mainnet',
    nativeCurrency: {
      name: 'CELO Token',
      symbol: 'CELO',
      decimals: 18,
    },
    blockExplorerUrl: 'https://explorer.celo.org',
    rpcUrls: ['https://forno.celo.org'],
    nodeUrl: ['https://forno.celo.org'],
  },
  [CHAINKEY.OEC_MAINNET]: {
    chainId: `0x${ChainId.OEC_MAINNET.toString(16)}`,
    chainIdInt: ChainId.OEC_MAINNET,
    chainName: 'OEC Mainnet',
    nativeCurrency: {
      name: 'OKT',
      symbol: 'okt',
      decimals: 18,
    },
    rpcUrls: ['https://exchainrpc.okex.org'],
    nodeUrl: ['https://exchainrpc.okex.org'],
    blockExplorerUrl: 'https://www.oklink.com/okexchain/',
  },
  [CHAINKEY.MoonbaseAlpha_MAINNET]: {
    chainId: `0x${ChainId.MoonbaseAlpha_MAINNET.toString(16)}`,
    chainIdInt: ChainId.MoonbaseAlpha_MAINNET,
    chainName: 'DEV Mainnet',
    nativeCurrency: {
      name: 'DEV',
      symbol: 'DEV',
      decimals: 18,
    },
    blockExplorerUrl: 'https://moonbase-blockscout.testnet.moonbeam.network/',
    rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
    nodeUrl: ['https://rpc.api.moonbase.moonbeam.network'],
  },
  [CHAINKEY.Clover_Mainnet]: {
    chainId: `0x${ChainId.Clover_Mainnet.toString(16)}`,
    chainIdInt: ChainId.Clover_Mainnet,
    chainName: 'CLV Mainnet',
    nativeCurrency: {
      name: 'CLV',
      symbol: 'CLV',
      decimals: 18,
    },
    blockExplorerUrl: 'https://clover.subscan.io',
    rpcUrls: ['https://api-para.clover.finance'],
    nodeUrl: ['https://api-para.clover.finance'],
  },
  [CHAINKEY.OPTIMISTIC_TESTNET]: {
    chainName: 'optimism TESTNET',
    chainId: `0x${ChainId.OPTIMISTIC_TESTNET.toString(16)}`,
    chainIdInt: ChainId.OPTIMISTIC_TESTNET,
    blockExplorerUrl: 'https://kovan.optimism.io',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
      address: '0x0000000000000000000000000000000000000000',
    },
    rpcUrls: ['https://rpc.testnet.fantom.network'],
    nodeUrl: ['https://rpc.testnet.fantom.network'],
  },
  [CHAINKEY.ASTR_TESTNET]: {
    chainId: `0x${ChainId.ASTR_TESTNET.toString(16)}`,
    chainIdInt: ChainId.ASTR_TESTNET,
    chainName: 'Shibuya Testnet',
    nativeCurrency: {
      name: 'SBY Token',
      symbol: 'SBY',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.shibuya.astar.network:8545'],
    nodeUrl: ['https://rpc.shibuya.astar.network:8545'],

    blockExplorerUrl: `${BASE_BSC_SCAN_URL}`,
  },

  [CHAINKEY.SDN_TESTNET]: {
    chainId: `0x${ChainId.ASTR_TESTNET.toString(16)}`,
    chainIdInt: ChainId.ASTR_TESTNET,
    chainName: 'Shibuya Testnet',
    nativeCurrency: {
      name: 'SBY Token',
      symbol: 'SBY',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.shibuya.astar.network:8545'],
    nodeUrl: ['https://rpc.shibuya.astar.network:8545'],

    blockExplorerUrl: `${BASE_BSC_SCAN_URL}`,
  },

  [CHAINKEY.BSC_TESTNET]: {
    chainId: `0x${ChainId.BSC_TESTNET.toString(16)}`,
    chainIdInt: ChainId.BSC_TESTNET,
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    nodeUrl: ['https://data-seed-prebsc-1-s1.binance.org:8545'],

    blockExplorerUrl: `${BASE_BSC_SCAN_URL}`,
  },

  [CHAINKEY.XDAI_MAIN]: {
    chainId: `0x${ChainId.XDAI_MAIN.toString(16)}`,
    chainIdInt: ChainId.XDAI_MAIN,
    chainName: 'Gnosis Mainnet',
    nativeCurrency: {
      name: 'xDai Token',
      symbol: 'xDai',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.xdaichain.com/'],
    nodeUrl: ['https://rpc.xdaichain.com/'],

    blockExplorerUrl: 'https://blockscout.com/poa/xdai/tx/',
  },

  [CHAINKEY.HECO_MAINNET]: {
    chainId: `0x${ChainId.HECO_MAINNET.toString(16)}`,
    chainIdInt: ChainId.HECO_MAINNET,
    chainName: 'Heco Mainnet',
    nativeCurrency: {
      name: 'HT Token',
      symbol: 'HT',
      decimals: 18,
    },
    rpcUrls: ['https://http-mainnet-node.huobichain.com'],
    nodeUrl: ['https://http-mainnet-node.huobichain.com'],

    blockExplorerUrl: 'https://hecoinfo.com/tx/',
  },
  [CHAINKEY.POLYGON_MAINNET]: {
    chainId: `0x137`,
    chainIdInt: 137,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'matic',
      decimals: 18,
      address: '0x0000000000000000000000000000000000001010',
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
    nodeUrl: ['https://rpc-mainnet.maticvigil.com'],

    blockExplorerUrl: `https://polygonscan.com`,
  },
  [CHAINKEY.FANTOM_MAINNET]: {
    chainId: `0xfa`, // 250
    chainIdInt: 250,
    chainName: 'Fantom Mainnet',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'ftm',
      decimals: 18,
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    nodeUrl: ['https://rpcapi.fantom.network'],

    blockExplorerUrl: 'https://ftmscan.com/',
  },
  [CHAINKEY.BOBA_MAINNET]: {
    chainId: `0x120`, // 288
    chainIdInt: 288,
    chainName: 'Boba Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.boba.network'],
    nodeUrl: ['https://mainnet.boba.network'],

    blockExplorerUrl: 'https://blockexplorer.boba.network',
  },
  [CHAINKEY.SHIDEN_MAINNET]: {
    chainId: `0x${ChainId.SHIDEN_MAINNET.toString(16)}`,
    chainIdInt: ChainId.SHIDEN_MAINNET,
    chainName: 'Shiden Network Mainnet',
    nativeCurrency: {
      name: 'SDN Token',
      symbol: 'SDN',
      decimals: 18,
    },
    rpcUrls: ['https://evm.shiden.astar.network'],
    nodeUrl: ['https://evm.shiden.astar.network'],
    // rpcUrls: ['https://shiden.api.onfinality.io/public'],
    blockExplorerUrl: `${BASE_BSC_SCAN_URL}`,
  },
  [CHAINKEY.THETA_MAINNET]: {
    chainId: `0x${ChainId.SDN_MAINNET.toString(16)}`,
    chainIdInt: ChainId.SDN_MAINNET,
    chainName: 'Theta Mainnett',
    nativeCurrency: {
      name: 'TFUEL Token',
      symbol: 'TFUEL',
      decimals: 18,
    },
    rpcUrls: ['https://eth-rpc-api.thetatoken.org/rpc'],
    nodeUrl: ['https://eth-rpc-api.thetatoken.org/rpc'],

    blockExplorerUrl: ``,
  },
  [CHAINKEY.ASTR_MAINNET]: {
    // [CHAINKEY.ASTR_MAINNET]: {
    chainId: `0x${ChainId.ASTR_MAINNET.toString(16)}`,
    chainIdInt: ChainId.ASTR_MAINNET,
    chainName: 'astar Network Mainnet',
    nativeCurrency: {
      name: 'ASTR Token',
      symbol: 'ASTR',
      decimals: 18,
    },
    rpcUrls: ['https://astar.api.onfinality.io/public'],
    nodeUrl: ['https://astar.api.onfinality.io/public'],

    blockExplorerUrl: `${BASE_BSC_SCAN_URL}`,
  },
  [CHAINKEY.ARBITRUM_MAINNET]: {
    chainId: `0x${ChainId.ARBITRUM_MAINNET.toString(16)}`,
    chainIdInt: ChainId.ARBITRUM_MAINNET,
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'ETH Token',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      // 'https://arb-mainnet.g.alchemy.com/v2/demo',
      'https://arb1.arbitrum.io/rpc',
    ],
    nodeUrl: [
      // 'https://arb-mainnet.g.alchemy.com/v2/demo',
      'https://arb1.arbitrum.io/rpc',
    ],

    blockExplorerUrl: `https://arbiscan.io`,
  },
  [CHAINKEY.AVAX_MAIN]: {
    chainId: `0xa86a`, // 43114
    chainIdInt: 43114,
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'avax',
      decimals: 18,
      address: '0x0000000000000000000000000000000000000000',
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nodeUrl: ['https://api.avax.network/ext/bc/C/rpc'],

    blockExplorerUrl: 'https://cchain.explorer.avax.network',
  },
  [CHAINKEY.AVALANCH_MAINNET]: {
    chainId: `0x${ChainId.AVALANCH_MAINNET.toString(16)}`,
    chainIdInt: ChainId.AVALANCH_MAINNET,
    chainName: 'Avalanche C-Chain',
    nativeCurrency: {
      name: 'AVAX Token',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nodeUrl: ['https://api.avax.network/ext/bc/C/rpc'],

    blockExplorerUrl: `https://snowtrace.io`,
  },

  [CHAINKEY.Ontology]: {
    chainId: `0x${ChainId.Ontology.toString(16)}`,
    chainIdInt: ChainId.Ontology,
    chainName: 'ONG C-Chain',
    nativeCurrency: {
      name: 'ONG Token',
      symbol: 'ONG',
      decimals: 18,
    },
    rpcUrls: ['https://dappnode1.ont.io:20339'],
    nodeUrl: ['https://dappnode1.ont.io:20339'],
    blockExplorerUrl: 'https://explorer.ont.io',
  },
  '245022934': {
    chainId: `0x245022934`,
    chainIdInt: 245022934,
    chainName: 'Solana Mainnet',
    nativeCurrency: {
      name: 'SOL Token',
      symbol: 'SOL',
      decimals: 18,
    },
    rpcUrls: ['https://proxy.mainnet.neonlabs.org/solana'],
    nodeUrl: ['https://proxy.mainnet.neonlabs.org/solana'],

    blockExplorerUrl: ``,
  },
};
// export const setupNetwork = async (connector: any, chainkey: CHAINKEY) => {
//   try {
//     let provider;
//     if (connector instanceof TalismanConnector) {
//       provider = await connector.getProvider();
//     } else {
//       provider = window.ethereum;
//     }
//     if (provider) {

//       await provider.request({
//         method: 'wallet_addEthereumChain',
//         params: [
//           {
//             chainName: wallet_config[chainkey].chainName,
//             chainId: wallet_config[chainkey].chainId,
//             nativeCurrency: wallet_config[chainkey].nativeCurrency,
//             rpcUrls: wallet_config[chainkey].rpcUrls,
//             blockExplorerUrls: [wallet_config[chainkey].blockExplorerUrl],
//           },
//         ],
//       });
//       return true;
//     } else {
//       console.error("Can't setup the network in wallet because provider is undefined");
//       return false;
//     }
//   } catch (err) {
//     console.error('Failed to setup network in wallet:', err);
//     return false;
//   }
// };

export const setupNetwork = async (connector: any, chainkey?: CHAINKEY) => {
  let provider;
  if (connector instanceof TalismanConnector) {
    provider = await connector.getProvider();
  } else {
    provider = window.ethereum;
  }
  if (!wallet_config[chainkey]) {
    console.error('Invalid chain id');
    return false;
  }
  if (provider) {
    const chainObj = wallet_config[chainkey];
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainObj.chainId }],
      });
      return true;
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: wallet_config[chainkey].chainName,
                chainId: wallet_config[chainkey].chainId,
                nativeCurrency: wallet_config[chainkey].nativeCurrency,
                rpcUrls: wallet_config[chainkey].rpcUrls,
                blockExplorerUrls: [wallet_config[chainkey].blockExplorerUrl],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      }
      return false;
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  provider: Window['ethereum'],
  chainkey: CHAINKEY,
) => {
  const tokenAdded = await provider.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${STATIC_BASE_URL}/images/tokens/${chainkey}/${tokenAddress}.svg`,
      },
    },
  });

  return tokenAdded;
};
