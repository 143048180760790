import { createAsyncThunk } from '@reduxjs/toolkit';
import { IShowStatusModal } from 'views/TransactionList/VaultMultiTransactionList/ShowStatusModal';
import { fetchHistoryMultiGet } from './fetch/fetchHistoryMultiGet';

export const fetchHistoryMultiGetHandle = createAsyncThunk<IShowStatusModal[], { account: string }>(
  'vaultHistory/fetchHistoryMultiGetHandle',
  async ({ account }) => {
    const history = await fetchHistoryMultiGet({ account });
    return history;
  },
);

const VaultHistoryReducers = (builder) => {
  builder.addCase(fetchHistoryMultiGetHandle.pending, (state, action) => {
    state.vaultHistoryIsLoading = true;
  });
  builder.addCase(fetchHistoryMultiGetHandle.fulfilled, (state, action) => {
    state.vaultHistory = action.payload;
    state.vaultHistoryIsLoading = false;
  });
};
export default VaultHistoryReducers;
