import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { State } from 'state/types';
import { fetchHistoryMultiGetHandle } from './reducer';
import { VaultHistoryState } from './types';

export const useHistoryMultiGet = () => {
  const { account } = useWeb3React();
  // const account = '0x41BA3387E1a5a592E27B9EE33935957CE5F872C1'
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dispatch && account) {
      dispatch(fetchHistoryMultiGetHandle({ account }));
    }
  }, [dispatch, account]);
};
export const useVaultHistoryState = (): VaultHistoryState => {
  const vaultHistory = useSelector((state: State) => state.vaultHistory);
  // console.log(4444, { vaultHistory });
  return vaultHistory;
};
