import { createAsyncThunk } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { CHAINKEY } from 'config/constants/chain_config';
import { IMultiToken, IMultiTokenItem, VaultMatadateType, VaultState } from 'state/types';
import { BIG_TEN } from 'utils/bigNumber';
import { symbolUtils } from 'utils/symbolFormmat';
import { IVaultAccountBalanceInfoItem } from 'views/VaultMulti/components/MultiVaultAccountBalanceInfo/MultiVaultAccountBalanceInfoItem';
import fetchTradeConfig from './fetchTradeConfig';
import fetchVaultBaseConfig from './fetchVaultBaseConfig';
import fetchVaultMultiToken from './fetchVaultMultiToken';
import { fetchVaultMultiTokenBalance } from './fetchVaultMultiTokenBalance';
import fetchVaultsV2 from './fetchVaultsV2';
import { fetchMultiUserVaultsBalances } from './multi/fetchVaultUserMulti';
import { IVault } from './types';

export const fetchVaultBaseConfigAsync = createAsyncThunk('vault/fetchVaultBaseConfigAsync', async () => {
  const vaultsConfig = await fetchVaultBaseConfig();
  return vaultsConfig;
});
export const fetchVaultMultiTokenAsync = createAsyncThunk('vault/fetchVaultMultiTokenAsync', async () => {
  const multiToken = await fetchVaultMultiToken();
  return multiToken;
});
export const fetchTradeConfigAsync = createAsyncThunk('vault/fetchTradeConfigAsync', async () => {
  const tradeConfig = await fetchTradeConfig();
  return tradeConfig;
});

export const fetchVaultMultiTokenBalanceAsync = createAsyncThunk<
  IMultiToken,
  { multiTokenConfig: IMultiToken; safeAddress: Record<string, Record<string, string>>; account: string }
>('vault/fetchVaultMultiTokenBalanceAsync', async ({ multiTokenConfig, account, safeAddress }) => {
  let obj = {};
  // console.log(1);
  const chainList = Object.keys(multiTokenConfig);
  const result = await Promise.all(
    chainList.map((chain) => {
      const chainkey = chain as CHAINKEY;
      const tokenListChain = multiTokenConfig[chainkey];
      const otherAccount = safeAddress[account][chainkey];
      return fetchVaultMultiTokenBalance(tokenListChain, [account, otherAccount], chainkey);
    }),
  ).catch((e) => {});
  // console.log(3);
  chainList.map((chain: string, index: number) => {
    const value = result[index];
    const chainkey = chain as CHAINKEY;
    let tokenListChain = multiTokenConfig[chainkey];

    const otherAccount = safeAddress[account][chainkey];
    if ('balanceOfChild' in value && 'balanceOfParent' in value) {
      const { balanceOfChild, balanceOfParent } = value;
      tokenListChain = tokenListChain.map((v: IMultiTokenItem, index: number) => ({
        ...v,
        balances: {
          [account.toLowerCase()]: balanceOfParent[index],
          [otherAccount.toLowerCase()]: balanceOfChild[index],
        },
      }));
    }
    obj = {
      ...obj,
      [chain]: tokenListChain,
    };
    return obj;
  });
  // console.log({ obj });
  return obj as IMultiToken;
});

export const fetchVaultsPublicDataAsync = createAsyncThunk<
  [VaultMatadateType, string],
  {
    priceVsBusdMapMulti: Record<string, string>;
    vaultsData: VaultMatadateType;
  }
>('vault/fetchVaultsPublicDataAsync', async ({ priceVsBusdMapMulti, vaultsData }) => {
  const vaults = await fetchVaultsV2(priceVsBusdMapMulti, vaultsData);
  return vaults;
});
// export const fetchChangeChainAndDappVaultsPublicDataAsync = createAsyncThunk<
//   [VaultMatadateType, string],
//   {
//     chosedChainAndDApp: ChosedChainAndDAppType,
//     vaultsConfig
//     currentBlock: number;
//     priceVsBusdMap: Record<string, string>;
//     vaultsData: VaultMatadateType;
//   }
//   >('vault/fetchChangeChainAndDappVaultsPublicDataAsync', async ({
//     chosedChainAndDApp,  currentBlock, priceVsBusdMap, vaultsData }) => {
//   const vaults = await fetchVaultsV2(currentBlock, priceVsBusdMap, vaultsData);
//   return vaults;
// });

export const fetchMultiVaultFarmUserDataAsync = createAsyncThunk<
  [IVaultAccountBalanceInfoItem[]],
  {
    account: string;
    vaults: VaultMatadateType;
    safeAddress: Record<string, Record<string, string>>;
    priceVsBusdMapMulti: Record<string, string>;
  }
>('vault/fetchMultiVaultFarmUserDataAsync', async ({ account, vaults, safeAddress, priceVsBusdMapMulti }) => {
  // >('vault/fetchMultiVaultFarmUserDataAsync', async ({ account: _, vaults, safeAddress, priceVsBusdMapMulti }) => {
  //   const account = '0x41BA3387E1a5a592E27B9EE33935957CE5F872C1';
  // // console.log({ account });
  const userVaultBalances = await fetchMultiUserVaultsBalances(account, safeAddress, vaults);
  // // console.log({ account, userVaultBalances });
  // {
  //   chain: {
  //     "dappname": {
  //       key: value
  //     }
  //   }
  // }
  const accountBalanceInfo: IVaultAccountBalanceInfoItem[] = [];
  for (const _chain of Object.keys(vaults)) {
    const chain = _chain as CHAINKEY;
    const chainObj = vaults[chain];
    for (let dappI = 0; dappI < chainObj.dapp.length; dappI++) {
      const dappname = chainObj.dapp[dappI].dappname;
      const balanceOf = userVaultBalances[chain][dappname];
      for (let i = 0; i < chainObj.dapp[dappI].contract.length; i++) {
        // const item = chainObj.dapp[dappI].contract[i];
        const isbalance = (Object.values(balanceOf)[i] as string[]).flat(2).filter((v) => Number(v) > 0).length > 0;
        // // console.log('2222----0000', i, chain, isbalance, Object.values(balanceOf)[i]);
        // // console.log(111, i, { chain, dappname, balanceOf });
        if (isbalance) {
          const balanceAccount = Object.keys(balanceOf);
          // // console.log(2.2, { balanceAccount, chain, dappname });
          for (let _ii = 0; _ii < balanceAccount.length; _ii++) {
            // // console.log(9999999, Object.values(balanceOf), balanceAccount, i, chain, dappname);
            for (let j = 0; j < 2; j++) {
              const _balanceValue = balanceOf[balanceAccount[_ii]][j]; // final balanceof number
              if (_balanceValue > 0) {
                const item = chainObj.dapp[dappI].contract[j];
                // // console.log(113331, { chain, dappname, balanceOf });
                const _symbol = symbolUtils(item.vault.vaultsymbol);
                const withdrawAbleUSD = Number(
                  new BigNumber(_balanceValue)
                    .div(BIG_TEN.pow(new BigNumber(item.vault.decimals)))
                    .times(Number(priceVsBusdMapMulti[_symbol]))
                    .toFixed(18, BigNumber.ROUND_DOWN),
                ).toLocaleString('en-US', {
                  maximumFractionDigits: 4,
                });
                if (withdrawAbleUSD !== '0') {
                  if (
                    accountBalanceInfo.filter(
                      (v) =>
                        v.chain === chain &&
                        v.contractAddress === item.contractAddress &&
                        v.account === balanceAccount[_ii],
                    ).length
                  ) {
                    // // console.log(99999);
                    break;
                  }
                  const __balanceitem: IVaultAccountBalanceInfoItem = {
                    vaulttype: item.vaulttype,
                    vaultSymbol: item.vault.vaultsymbol,
                    chain: chain,
                    dappname: dappname,
                    withdrawAble: _balanceValue,
                    vaultDecimals: item.vault.decimals,
                    withdrawAbleUSD,
                    wantaddress: item.vault.wantaddress,
                    wantaddresssymbol: item.vault.wantaddresssymbol,
                    token0address: item.vault.token0address,
                    token1address: item.vault.token1address,
                    contractAddress: item.contractAddress,
                    apy: item.vault.total_apy,
                    account: balanceAccount[_ii],
                  };
                  accountBalanceInfo.push(__balanceitem);
                }
              } else {
              }
            }
          }
        }
      }
    }
  }
  return [accountBalanceInfo];
});

// export const fetchVaultFarmUserDataAsync = createAsyncThunk<
//   {
//     userVaultsFarmAllowances: any;
//     userVaultsFarmTokenBalances: any;
//     userVaultUsers: any;
//   },
//   {
//     account: string;
//     vaults: VaultMatadateType;
//     index?: number;
//     chainkeyFromSource?: CHAINKEY;
//     dappFromSource?: string;
//     safeAddress: Record<string, Record<string, string>>;
//   }
// >(
//   'vault/fetchVaultFarmUserDataAsync',
//   async ({ account, vaults, index, chainkeyFromSource, dappFromSource, safeAddress }) => {
//     let dappIndex = null;
//     if (dappFromSource) {
//       dappIndex = vaults[chainkeyFromSource].dapp.map((v) => v.dappname).indexOf(dappFromSource);
//     }
//     // todo
//     const userVaultsFarmAllowances = await fetchVaultsFarmUserAllowances(
//       account,
//       vaults,
//       index,
//       chainkeyFromSource,
//       dappIndex,
//     );
//     const userVaultsFarmTokenBalances = await fetchVaultsFarmUserTokenBalances(
//       account,
//       safeAddress,
//       vaults,
//       index,
//       chainkeyFromSource,
//       dappIndex,
//     );
//     const userVaultUsers = await fetchVaultsUsersV2(account, vaults, index, chainkeyFromSource, dappIndex);
//     return {
//       userVaultsFarmAllowances,
//       userVaultsFarmTokenBalances,
//       userVaultUsers,
//     };
//   },
// );
const vaultExtraReducers = (builder) => {
  builder.addCase(fetchVaultBaseConfigAsync.fulfilled, (state: VaultState, action) => {
    state.vaultsConfig = action.payload;
    // state.chosedChainAndDApp = {
    //   chain: Object.keys(action.payload).map(v=>v as CHAINKEY) ,
    //   dapp: Object.values(action.payload)["dapp"].map(v => v.dappname)
    // };
    state.chosedChainAndDApp = Object.keys(state.vaultsConfig).map((v) => {
      return {
        chain: v,
        dapp: state.vaultsConfig[v].dapp.map((vv) => vv.dappname),
      };
    });
    state.chosedData = state.vaultsConfig;
  });
  builder.addCase(fetchVaultMultiTokenAsync.pending, (state: VaultState, action) => {
    // state.fetchVaultMultiTokenAsyncLoading = true;
  });
  builder.addCase(fetchVaultMultiTokenAsync.fulfilled, (state: VaultState, action) => {
    // state.fetchVaultMultiTokenAsyncLoading = false;
    state.multiTokenConfig = action.payload;
  });
  builder.addCase(fetchTradeConfigAsync.fulfilled, (state: VaultState, action) => {
    state.tradeConfig = action.payload;
  });

  builder.addCase(fetchVaultsPublicDataAsync.fulfilled, (state: VaultState, action) => {
    state.userDataLoaded = true;
    state.chosedData = action.payload[0];
    state.vaultsConfig = action.payload[0];
    state.tvlTotal = action.payload[1];
  });
  // builder.addCase(fetchVaultMultiTokenBalanceAsync.pending, (state: VaultState, action) => {
  //   state.fetchVaultMultiTokenBalanceAsyncLoading = true;
  // });
  builder.addCase(fetchVaultMultiTokenBalanceAsync.fulfilled, (state: VaultState, action) => {
    // state.fetchVaultMultiTokenBalanceAsyncLoading = false;
    if (action.payload && Object.keys(action.payload)) {
      state.multiToken = action.payload;
    }
  });
  builder.addCase(fetchMultiVaultFarmUserDataAsync.fulfilled, (state: VaultState, action) => {
    // state.chosedData = action.payload[0];
    let accountBalanceInfo: IVaultAccountBalanceInfoItem[] = action.payload[0];
    const vaults = state.chosedData;
    const _balance = accountBalanceInfo || [];
    const _vaultData = {} as VaultMatadateType;
    for (const _chain of Object.keys(vaults)) {
      const chain = _chain as CHAINKEY;
      const chainObj = vaults[chain];
      for (let dappI = 0; dappI < chainObj.dapp.length; dappI++) {
        const dappname = chainObj.dapp[dappI].dappname;
        for (let i = 0; i < chainObj.dapp[dappI].contract.length; i++) {
          const item = chainObj.dapp[dappI].contract[i];
          const isbalance = _balance.filter(
            (v) =>
              v.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() &&
              v.dappname.toLowerCase() === item.dappname.toLowerCase() &&
              v.chain === item.chainkey,
          );
          const _item: IVault = {
            ...item,
            vault: {
              ...item.vault,
              isbalance: isbalance && isbalance.length > 0 ? true : false,
            },
          };
          if (!_vaultData[chain]) {
            _vaultData[chain] = {
              chain: chain,
              status: 1,
              dapp: [
                {
                  dappname: dappname,
                  contract: [{ ...item }],
                },
              ],
            };
          }
          if (!_vaultData[chain].dapp[dappI]) {
            _vaultData[chain].dapp = [
              ..._vaultData[chain].dapp,
              {
                dappname: dappname,
                contract: [{ ...item }],
              },
            ];
          }
          _vaultData[chain].dapp[dappI].contract[i] = _item;
        }
      }
    }
    if (accountBalanceInfo && accountBalanceInfo.length) {
      accountBalanceInfo = accountBalanceInfo.map((v) => {
        const _dapp = _vaultData[v.chain].dapp.filter((vv) => vv.dappname === v.dappname)[0];
        const _vault = _dapp.contract.filter(
          (vvv) => vvv.contractAddress.toLowerCase() === v.contractAddress.toLowerCase(),
        )[0];
        return {
          ...v,
          apy: _vault.vault.total_apy !== '-' ? _vault.vault.total_apy : v.apy,
        };
      });
    }

    state.chosedData = _vaultData;
    state.accountBalanceInfo = accountBalanceInfo;
  });
};
export default vaultExtraReducers;
