import BigNumber from 'bignumber.js';
import { CHAINKEY } from 'config/constants/chain_config';
import { UOExecutorL2ABI } from 'config/vault/abi';
import { sigValidationModuleAddress, uOExecutorL2Address } from 'config/vault/address';
import { chainIdsList } from 'config/vault/chainIdsList';
import { poolIdsList } from 'config/vault/poolIdsList';
import { wallet_config } from 'config/wallet';
import { Contract, ethers } from 'ethers';
import { ZERO_ADDRESS } from 'state/safeContract/utils/utils';
import { IMultiToken, IMultiTokenItem } from 'state/types';
import { BIG_TEN } from 'utils/bigNumber';
import { callWithEstimateGas } from 'wallet/estimateGas';
import { getCrossChainSwapFee, IGetFeeFunctionType } from './get/getCrossChainSwapFee';
// import { getUniswapRouterByFromChain } from './get/getUniswapRouter';
import { getUserApprove } from './get/getUserApprove';
import { getUserOperation } from './get/getUserOperation';
export const dstGasForCall = '1000000'; // gas for destination Stargate Router (including sgReceive)
export const onPressCallDeposit = async ({
  routerContract,
  fromchain,
  fromtoken,
  toToken,
  tochain,
  value,
  library,
  account,
  vaultContract,
  isApprove,
  multiToken,
  safeAddress,
  wantaddress,
}: {
  routerContract: Contract;
  vaultContract: Contract;
  fromchain: CHAINKEY;
  fromtoken: IMultiTokenItem;
  toToken: IMultiTokenItem;
  tochain: CHAINKEY;
  value: string;
  account: string;
  library: any;
  isApprove: boolean;
  multiToken: IMultiToken;
  safeAddress: Record<string, Record<CHAINKEY, string>>;
  wantaddress: string;
}) => {
  // return {
  //   isCrossChain: false,
  //   isSuccess: true,
  //   txHash: 'xxx',
  // };
  try {
    // deposit
    const dstChainId = chainIdsList[tochain]; // Stargate/LayerZero chainId
    const _refundAddress = account; // message refund address if overpaid
    const amountIn = new BigNumber(`${value}`).times(BIG_TEN.pow(fromtoken?.decimals || 18)).toFixed(0);
    if (fromchain !== tochain) {
      let res;
      // const toPath = await getUniswapRouterByFromChain({
      //   chainkey: tochain,
      //   value: value,
      //   account,
      //   fromtoken,
      //   toToken,
      //   isFrom: false,
      //   multiToken: multiToken,
      // });
      // console.log({ wantaddress });
      // return;
      const toPath = wantaddress;
      const tochainId = wallet_config[tochain].chainIdInt;
      const tochainParams = {
        _path: toPath,
        _amount: amountIn,
      };

      const avatar = safeAddress[account][tochain];
      const userOperation = await getUserOperation({
        abi: UOExecutorL2ABI,
        method: 'depositToAVault',
        // bytes memory _path, uint _amount
        params: Object.values(tochainParams),
        tochainId,
        amountIn: '0',
        sigValidationModuleAddress: sigValidationModuleAddress[tochain],
        library,
        account,
        chainkey: tochain,

        gasToken: ZERO_ADDRESS,
        gasTokenAmount: '0',
        avatar,
        uOExecutorL2Address: uOExecutorL2Address[tochain],
      });
      const payload = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'tuple(uint256 toChainId, address to, uint256 value, bytes data, address gasToken, uint256 gasTokenAmount, uint256 operation, uint8 v, bytes32 r, bytes32 s) d',
        ],
        [account, userOperation],
      );
      if (fromtoken && fromtoken.address) {
        // console.log(3, { userOperation });
        const srcPoolId = poolIdsList[fromchain]['USDC']; // stargate poolId, scrToken should be USDC or USDT...
        const dstPoolId = poolIdsList[tochain]['USDC']; // stargate destination poolId, destToken could be USDC, USDT, BUSD or other stablecoin.
        const fromPath = fromtoken.address;
        // const fromPath = await getUniswapRouterByFromChain({
        //   chainkey: fromchain,
        //   value: value,
        //   account,
        //   fromtoken,
        //   toToken,
        //   isFrom: true,
        //   multiToken: multiToken,
        // });
        // fromchain   fromtoken-usdc  -> path
        // usdt
        // weth
        const crossAssetCallParams = {
          _path: fromPath, // bytes calldata _path,             //uniswap exactIn path
          dstChainId, // uint dstChainId,                      // Stargate/LayerZero chainId
          srcPoolId, // uint srcPoolId,                       // stargate poolId, scrToken should be USDC or USDT...
          dstPoolId, // uint dstPoolId,                       // stargate destination poolId, destToken could be USDC, USDT, BUSD or other stablecoin.
          _refundAddress, // address payable _refundAddress,                     // message refund address if overpaid
          amountIn, // uint amountIn,                    // exact amount of native token coming in on source
          _maxSlippagePercent: 100, // 1 = 0.01%，// uint _maxSlippagePercent,     //  bridge slippage, 1 = 0.01%. Could be set arbitrary
          _dstGasForCall: dstGasForCall, // uint _dstGasForCall:dstGasForCall,             // gas for destination Stargate Router (including sgReceive)
          payload, // bytes memory payload,        // (address _srcAddress, UserOperation memory _uo) = abi.decode(_payload, (address, UserOperation));
        };

        const crossChainSwapFee = await getCrossChainSwapFee({
          fromchain,
          dstChainId,
          getFeeFunctionType: IGetFeeFunctionType.TYPE_SWAP_REMOTE,
          toAddress: toToken.address,
          payload,
          _dstGasForCall: dstGasForCall,
        });
        const nativeValue = new BigNumber(crossChainSwapFee).toFixed(0);
        // console.log({ crossAssetCallParams, nativeValue });
        if (isApprove) {
          res = await callWithEstimateGas(
            fromchain,
            routerContract,
            'crossAssetCall',
            Object.values(crossAssetCallParams),
            {
              value: nativeValue,
            },
          );
        } else {
          // approve
          const fromchainId = wallet_config[fromchain].chainIdInt;
          const _permit = await getUserApprove({
            library: library,
            fromtokenaddress: fromtoken.address,
            sigValidationModuleAddress: sigValidationModuleAddress[fromchain],
            account,
            fromchain,
            fromchainId: fromchainId,
            amountIn: amountIn,
          });

          res = await callWithEstimateGas(
            fromchain,
            routerContract,
            'crossAssetCallWithPermit',
            Object.values({
              ...crossAssetCallParams,
              _permit,
            }),
            {
              value: nativeValue,
            },
          );
        }
      } else {
        // native
        const crossAssetCallNativeParams = {
          dstChainId,
          _refundAddress,
          amountIn,
          _maxSlippagePercent: 100,
          _dstGasForCall: dstGasForCall,
          payload,
        };
        // console.log(111, { userOperation });
        const crossChainSwapFee = await getCrossChainSwapFee({
          fromchain,
          dstChainId,
          getFeeFunctionType: IGetFeeFunctionType.TYPE_SWAP_REMOTE,
          toAddress: toToken.address,
          payload,
          _dstGasForCall: dstGasForCall,
        });
        const nativeValue = new BigNumber(amountIn).plus(crossChainSwapFee).toFixed(0);

        // console.log({ crossAssetCallNativeParams, nativeValue });
        const maxBalance = new BigNumber(fromtoken.balances[account.toLowerCase()].number).times(
          BIG_TEN.pow(fromtoken?.decimals || 18),
        );
        if (maxBalance.lt(nativeValue)) {
          throw Error(`No enough Balance, You balance is ${maxBalance.toString()} wei, need ${nativeValue} wei`);
        }
        res = await callWithEstimateGas(
          fromchain,
          routerContract,
          'crossAssetCallNative',
          Object.values(crossAssetCallNativeParams),
          {
            value: `${nativeValue}`,
          },
        );
      }
      if (res && res.isOk) {
        console.log(23332323);
        return {
          isCrossChain: true,
          isSuccess: true,
          txHash: res?.receipt?.transactionHash,
          message: '',
        };
      } else {
        console.log(3333222);
        return {
          isCrossChain: true,
          isSuccess: false,
          message: res?.message,
        };
      }
    } else {
      let res;
      if (!fromtoken.address) {
        res = await callWithEstimateGas(fromchain, vaultContract, 'depositNative', [account], {
          value: `${amountIn}`,
        });
      } else {
        res = await callWithEstimateGas(fromchain, vaultContract, 'deposit', [account, `${amountIn}`]);
      }
      if (res && res.isOk) {
        console.log(3333);
        return {
          isCrossChain: false,
          isSuccess: true,
          txHash: res?.receipt?.transactionHash,
          message: '',
        };
      } else {
        console.log(111);
        return {
          isCrossChain: false,
          isSuccess: false,
          message: res?.message,
        };
      }
    }
  } catch (e: any) {
    console.log(999999, e);
    return {
      isCrossChain: false,
      isSuccess: false,
      message: e?.message,
    };
  }
};
