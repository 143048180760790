export enum Operation {
  Call,
  DelegateCall,
}
export interface IUserOperation {
  tochainId: number;
  to: string; // address to;
  value: number; // uint256 value;
  data: string; // bytes data;
  gasToken: string; // address gasToken;
  gasTokenAmount: number; // uint256 gasTokenAmount;
  operation: Operation; // Enum.Operation operation;

  v: number; // uint8 v;
  r: number; // bytes32 r;
  s: number; // bytes32 s;

  // safeTxGas: number; // uint256 safeTxGas;
  // baseGas: number; // uint256 baseGas;
  // payable: string; // address payable refundReceiver;
  // signatures: string; // bytes signatures;
}
