import { STATIC_BASE_URL } from 'config';
import { initVaultData } from './utils';

const fetchVaultBaseConfig = async () => {
  try {
    const apiUrl = `${STATIC_BASE_URL}/api/vault_base_config.json`;
    const r = await fetch(apiUrl);
    const body = await r.json();
    return initVaultData({ vaultsConfig: body });
  } catch (e: any) {
    return {};
  }
};
export default fetchVaultBaseConfig;
