import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IShowStatusModal } from 'views/TransactionList/VaultMultiTransactionList/ShowStatusModal';
import { getTransactionListDetail } from './get/getTransactionListDetail';

export const useListDetail = () => {
  const [detail, setDetail] = useState<IShowStatusModal>();
  const { account } = useWeb3React();
  const { fromtx, time } = useParams<{ fromtx: string; time: string }>();
  // console.log({ fromtx });
  useEffect(() => {
    if (account && fromtx) {
      (async () => {
        const _detail = await getTransactionListDetail({
          account,
          fromtx,
        });
        setDetail(_detail);
      })();
    }
  }, [account, fromtx, time]);
  return detail;
};
