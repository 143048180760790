import { STATIC_BASE_URL } from 'config';

export const haveNumber = (num: number) => {
  return num || num === 0;
};

export const getChainImage = (chain: string) => {
  return `${STATIC_BASE_URL}/images/chain/${chain}.png`;
};

export const getDappImage = (dapp: string) => {
  return `${STATIC_BASE_URL}/images/dapp/${dapp}.png`;
};

export const getBridgeImage = (bridge: string) => {
  return `${STATIC_BASE_URL}/images/bridge/${bridge}.png`;
};
export const getImageUrlFromToken = (token: any, chainkeyFromSource: string) => {
  if (!token || !token.length) {
    return `${STATIC_BASE_URL}/images/tokens/${chainkeyFromSource}/-.svg`;
  }
  if (typeof token === 'string') {
    return `${STATIC_BASE_URL}/images/tokens/${chainkeyFromSource}/${token.toLocaleLowerCase()}.svg`;
  }
  if (typeof token.address === 'string') {
    return `${STATIC_BASE_URL}/images/tokens/${chainkeyFromSource}/${token.address.toLocaleLowerCase()}.svg`;
  }
  return `${STATIC_BASE_URL}/images/tokens/${chainkeyFromSource}/${token.address.toLocaleLowerCase()}.svg`;
};
