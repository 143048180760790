import { FC } from 'react';
import styled from 'styled-components';
import UserWidget from './UserWidget';
import Logo from './Logo';
import Nav from './Nav';
import { Flex } from '@my/ui';
const Header: FC<{ className?: string; setCollapsed: (collapsed: boolean) => void; collapsed: boolean }> = ({
  className,
  setCollapsed,
  collapsed,
}) => {
  return (
    <div className={className}>
      <div className="inner">
        <FlFlex>
          <Logo collapsed={collapsed} />
          <Nav collapsed={collapsed} />
        </FlFlex>
        <div className="right">
          <UserWidget collapsed={collapsed} />
        </div>
      </div>
    </div>
  );
};
const FlFlex = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
`;
export default styled(Header)`
  // background-color: rgba(3, 2, 34, 0.7);
  background-color: #010011;
  position: fixed;
  z-index: 98;
  width: 100%;
  .inner {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #232237;
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
    ${({ theme }) => theme.mediaQueries.md} {
      height: 72px;
      padding-left: 24px;
      padding-right: 24px;
    }
    > img {
      width: 25px;
      height: 20px;
    }

    > .right {
      padding-top: 0;
      padding-bottom: 0;
      ${({ theme }) => theme.mediaQueries.md} {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }
`;
