import BigNumber from 'bignumber.js';
import { CHAINKEY } from 'config/constants/chain_config';
import { useMemo } from 'react';
import { IMultiToken, IMultiTokenItem } from 'state/types';
import { IVault } from 'state/vault/types';
import { BIG_TEN } from 'utils/bigNumber';
import { symbolUtils } from 'utils/symbolFormmat';
import { IVaultAccountBalanceInfoItem } from 'views/VaultMulti/components/MultiVaultAccountBalanceInfo/MultiVaultAccountBalanceInfoItem';
export function useGetChainAndToken({
  account,
  multiToken,
  multiTokenConfig,
  fromchain,
  tochain,
  chosedTokenAddress,
  chosedVault,
  accountBalanceInfo,
  vaultIsTo = false,
  safeAddress,
}: {
  account: string;
  multiToken: IMultiToken;
  multiTokenConfig: IMultiToken;
  fromchain: CHAINKEY;
  tochain: CHAINKEY;
  chosedTokenAddress?: string;
  chosedVault?: IVault;
  vaultIsTo?: boolean;
  accountBalanceInfo?: IVaultAccountBalanceInfoItem[];
  safeAddress?: Record<string, Record<CHAINKEY, string>>;
}) {
  return useMemo(() => {
    const _multiToken =
      account && multiToken && Object.keys(multiToken).length ? multiToken || multiTokenConfig : multiTokenConfig;
    // console.log(223333, { _multiToken, tochain });
    if (!_multiToken) {
      return {};
    }
    const chainList = Object.keys(_multiToken);
    let _fromchain = vaultIsTo ? fromchain : chosedVault?.chainkey || fromchain;
    let _tochain = vaultIsTo ? chosedVault?.chainkey || tochain : tochain;
    if (!chainList.includes(_fromchain)) {
      _fromchain = chainList[0] as CHAINKEY;
    }
    if (!chainList.includes(_tochain)) {
      _tochain = chainList[0] as CHAINKEY;
    }
    const fromtokenList: IMultiTokenItem[] = _multiToken[_fromchain];
    const toTokenList: IMultiTokenItem[] = _multiToken[_tochain];
    // console.log(333, 2, { fromtokenList, toTokenList, _fromchain, vaultIsTo, chosedVault, _tochain });
    if (!fromtokenList) {
      return {};
    }
    let fromtoken: IMultiTokenItem = null;
    let toToken: IMultiTokenItem = null;
    if (chosedTokenAddress) {
      fromtoken = fromtokenList.filter(
        (v) => (v?.address || '-').toLocaleLowerCase() === chosedTokenAddress.toLocaleLowerCase(),
      )[0];
      // console.log(fromtoken, chosedTokenAddress);
      // const chosedTokenLocalStorage = getLocalStorage(chosedTokenLocalStorageKey) || {};
      const chosedTokenKey = symbolUtils(fromtoken?.symbol ?? 'USDC');
      const _toToken = toTokenList.filter((v) => v.symbol.toLowerCase().indexOf(chosedTokenKey.toLowerCase()) > -1)[0];
      const _wtoken = {
        ...toTokenList.filter((v) => v.symbol.toLowerCase().indexOf('weth') > -1)[0],
        symbol: 'ETH',
      };
      toToken = _toToken.address ? _toToken : _wtoken;
    }
    // else if (_fromchain === _tochain && chosedVault) {
    // console.log(3333, { fromtokenList, chosedVault });
    if (chosedVault && (vaultIsTo || safeAddress || !account)) {
      // } else {
      //   const chosedTokenLocalStorage = getLocalStorage(chosedTokenLocalStorageKey) || {};
      //   const chosedTokenKey = chosedTokenLocalStorage[fromchain] ?? 'USDC';
      //   fromtoken = fromtokenList.filter((v) => v.symbol === chosedTokenKey)[0];
      //   toToken = toTokenList.filter((v) => v.symbol === chosedTokenKey)[0];
      // }
      if (vaultIsTo) {
        toToken = {
          symbol: chosedVault.vault.vaultsymbol,
          address: chosedVault.contractAddress,
          decimals: chosedVault.vault.decimals,
          balances: {
            [account]: {
              number: '0',
              localNumber: '0',
            },
          },
        };
        const _symbol = symbolUtils(toToken.symbol);
        const _token = fromtokenList.filter((v) => v.symbol.toLowerCase().indexOf(_symbol) > -1)[0];
        // const wantAddress = chosedVault.vault.wantaddress.toLocaleLowerCase();
        // console.log(333, { fromtokenList, _token });
        fromtoken = _token || fromtokenList[0];
      } else {
        // const accountKey = vaultIsTo ? account.toLowerCase() : _fromchain === _tochain ? account.toLowerCase() : 'xx';
        // console.log({ keyIndex });
        const ___balance = (accountBalanceInfo ?? []).filter(
          (v) =>
            v.contractAddress.toLowerCase() === chosedVault.contractAddress.toLowerCase() &&
            v.chain === chosedVault.chainkey,
        );
        // console.log({ accountBalanceInfo });
        const __balance = ___balance.map((v) => v.account.toLowerCase());
        const _accountIndex = __balance.indexOf(account?.toLowerCase());
        // console.log({ __balance, _accountIndex });
        let _childIndex = 0;
        let _childAccount;
        if (_accountIndex > -1) {
          const copyArr = __balance.slice();
          copyArr.splice(_accountIndex, 1);
          _childAccount = copyArr[0];
          _childIndex = __balance.indexOf(_childAccount);
        }

        // console.log({ accountBalanceInfo, _balance }, chosedVault.contractAddress, chosedVault.chainkey);
        const decimals = chosedVault.vault.decimals;
        const bgAccount = new BigNumber(___balance[_accountIndex]?.withdrawAble || '0')
          .div(BIG_TEN.pow(new BigNumber(decimals)))
          .toFixed(8, BigNumber.ROUND_DOWN);
        const bgStrAccount = Number(
          new BigNumber(___balance[_accountIndex]?.withdrawAble || '0')
            .div(BIG_TEN.pow(new BigNumber(decimals)))
            .toFixed(18, BigNumber.ROUND_DOWN),
        ).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });
        const bgChild = new BigNumber(___balance[_childIndex]?.withdrawAble || '0')
          .div(BIG_TEN.pow(new BigNumber(decimals)))
          .toFixed(8, BigNumber.ROUND_DOWN);
        const bgStrChild = Number(
          new BigNumber(___balance[_childIndex]?.withdrawAble || '0')
            .div(BIG_TEN.pow(new BigNumber(decimals)))
            .toFixed(18, BigNumber.ROUND_DOWN),
        ).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });

        fromtoken = {
          symbol: chosedVault.vault.vaultsymbol,
          address: chosedVault.contractAddress,
          decimals: chosedVault.vault.decimals,
          balances: {
            [_accountIndex > -1 ? ___balance[_accountIndex]?.account.toLowerCase() : '-']: {
              number: bgAccount,
              localNumber: bgStrAccount,
            },
            [_childIndex > -1 ? ___balance[_childIndex]?.account.toLowerCase() : '-']: {
              number: bgChild,
              localNumber: bgStrChild,
            },
          },
        };

        // console.log(333, { fromtoken });
        const _symbol = symbolUtils(fromtoken.symbol);
        const _token = toTokenList.filter((v) => v.symbol.toLowerCase().indexOf(_symbol) > -1)[0];
        const _wtoken = {
          ...toTokenList.filter((v) => v.symbol.toLowerCase().indexOf('weth') > -1)[0],
          symbol: 'ETH',
        };

        // const wantAddress = chosedVault.vault.wantaddress.toLocaleLowerCase();
        // console.log(333, { _token, _wtoken });
        toToken = _token.address ? _token : _wtoken;
      }
      // console.log({ toToken, fromtoken });
    }

    return {
      chainList,
      fromtokenList,
      fromtoken,
      fromchain: _fromchain,
      toTokenList,
      toToken,
      tochain: _tochain,
    };
  }, [
    multiToken,
    vaultIsTo,
    fromchain,
    multiTokenConfig,
    account,
    chosedTokenAddress,
    tochain,
    chosedVault,
    accountBalanceInfo,
    safeAddress,
  ]);
}
