import { CHAINKEY } from 'config/constants/chain_config';

export const LiquidityBridgeTokens = {
  USDT: {
    [CHAINKEY.ARBITRUM_MAINNET]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    aurora: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
    avax: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    [CHAINKEY.BSC_MAINNET]: '0x55d398326f99059ff775485246999027b3197955',
    [CHAINKEY.ETH_MAINNET]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    [CHAINKEY.FANTOM_MAINNET]: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
    heco: '0xa71edc38d189767582c38a3145b5873052c3e47a',
    okexchain: '0x382bb369d343125bfb2117af9c149795c6c65c50',
    [CHAINKEY.OPTIMISTIC_MAINNET]: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
    [CHAINKEY.POLYGON_MAINNET]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    xdai: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
  },
  USDC: {
    [CHAINKEY.ARBITRUM_MAINNET]: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    aurora: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
    avax: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    boba: '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
    [CHAINKEY.BSC_MAINNET]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    [CHAINKEY.ETH_MAINNET]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    [CHAINKEY.FANTOM_MAINNET]: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    harmony: '0x985458e523db3d53125813ed68c274899e9dfab4',
    heco: '0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b',
    okexchain: '0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
    [CHAINKEY.OPTIMISTIC_MAINNET]: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    [CHAINKEY.POLYGON_MAINNET]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    xdai: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
  },
  BUSD: {
    [CHAINKEY.ETH_MAINNET]: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    [CHAINKEY.BSC_MAINNET]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },

  ETH: {
    [CHAINKEY.ARBITRUM_MAINNET]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    avax: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    [CHAINKEY.BSC_MAINNET]: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    [CHAINKEY.ETH_MAINNET]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    [CHAINKEY.FANTOM_MAINNET]: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    [CHAINKEY.OPTIMISTIC_MAINNET]: '0x4200000000000000000000000000000000000006',
    [CHAINKEY.POLYGON_MAINNET]: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  },
  // DAI: {
  //   avax: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
  //   [CHAINKEY.BSC_MAINNET]: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   [CHAINKEY.ETH_MAINNET]: '0x6b175474e89094c44da98b954eedeac495271d0f',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  // },
  // BTC: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
  //   avax: '0x50b7545627a5162F82A992c33b87aDc75187B218',
  //   [CHAINKEY.ETH_MAINNET]: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  //   [CHAINKEY.FANTOM_MAINNET]: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  // },
  // DODO: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x69eb4fa4a2fbd498c257c57ea8b7655a2559a581',
  //   [CHAINKEY.BSC_MAINNET]: '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
  //   [CHAINKEY.ETH_MAINNET]: '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',
  // },
  // MCB: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
  //   [CHAINKEY.ETH_MAINNET]: '0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42',
  // },
  // CELR: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x3a8B787f78D775AECFEEa15706D4221B40F345AB',
  //   [CHAINKEY.BSC_MAINNET]: '0x1f9f6a696c6fd109cd3956f45dc709d2b3902163',
  //   [CHAINKEY.ETH_MAINNET]: '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667',
  // },
  // IF: {
  //   [CHAINKEY.BSC_MAINNET]: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
  //   [CHAINKEY.ETH_MAINNET]: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
  // },
  // LYRA: {
  //   [CHAINKEY.ETH_MAINNET]: '0x01ba67aac7f75f647d94220cc98fb30fcc5105bf',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
  // },
  // IMX: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x9c67ee39e3c4954396b9142010653f17257dd39c',
  //   avax: '0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef',
  //   [CHAINKEY.ETH_MAINNET]: '0x7b35ce522cb72e4077baeb96cb923a5529764a00',
  //   harmony: '0xbd8064cdb96c00a73540922504f989c64b7b8b96',
  //   moonriver: '0x900f1Ec5819FA087d368877cD03B265Bf1802667',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x60bb3d364b765c497c8ce50ae0ae3f0882c5bd05',
  // },
  // DOMI: {
  //   [CHAINKEY.ETH_MAINNET]: '0x45C2F8c9B4c0bDC76200448cc26C48ab6ffef83F',
  // },
  // BOBA: {
  //   boba: '0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7',
  //   [CHAINKEY.ETH_MAINNET]: '0x42bbfa2e77757c645eeaad1655e0911a7553efbc',
  // },
  // XTK: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0xF0A5717Ec0883eE56438932b0fe4A20822735fBa',
  //   [CHAINKEY.ETH_MAINNET]: '0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb',
  // },
  // xXTKa: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0xd8083e393985530b7cf6798d44a2f1536e211ab6',
  //   [CHAINKEY.ETH_MAINNET]: '0x314022E24ceD941781DC295682634B37Bd0d9cFc',
  // },
  // METIS: {
  //   [CHAINKEY.ETH_MAINNET]: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
  //   metis: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  // },
  // CVP: {
  //   [CHAINKEY.BSC_MAINNET]: '0x5ec3adbdae549dce842e24480eb2434769e22b2e',
  //   [CHAINKEY.ETH_MAINNET]: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1',
  // },
  // CEC: {
  //   [CHAINKEY.BSC_MAINNET]: '0x957c49a76b3e008637ca1cce23188a8ce884911e',
  //   [CHAINKEY.ETH_MAINNET]: '0x9e564eb5550E1A9b1448D916fd85a8d876661bdC',
  // },
  // STND: {
  //   [CHAINKEY.ETH_MAINNET]: '0x9040e237c3bf18347bb00957dc22167d0f2b999d',
  //   metis: '0xc12caC7090baa48Ec750CceeC57C80768F6ee58E',
  // },
  // WOO: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0xcafcd85d8ca7ad1e1c6f82f651fa15e33aefd07b',
  //   avax: '0xabc9547b534519ff73921b1fba6e672b5f58d083',
  //   [CHAINKEY.BSC_MAINNET]: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
  //   [CHAINKEY.ETH_MAINNET]: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
  //   [CHAINKEY.FANTOM_MAINNET]: '0x6626c47c00f1d87902fc13eecfac3ed06d5e8d8a',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
  // },
  // DF: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
  //   [CHAINKEY.BSC_MAINNET]: '0x4a9a2b2b04549c3927dd2c9668a5ef3fca473623',
  //   [CHAINKEY.ETH_MAINNET]: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x08C15FA26E519A78a666D19CE5C646D55047e0a3',
  // },
  // USX: {
  //   coingecko: '[CHAINKEY.BSC_MAINNET]:0xb5102cee1528ce2c760893034a4603663495fd72',
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
  //   [CHAINKEY.BSC_MAINNET]: '0xb5102cee1528ce2c760893034a4603663495fd72',
  //   [CHAINKEY.ETH_MAINNET]: '0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9',
  //   [CHAINKEY.POLYGON_MAINNET]: '0xCf66EB3D546F0415b368d98A95EAF56DeD7aA752',
  // },
  // PERP: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x753d224bcf9aafacd81558c32341416df61d3dac',
  //   [CHAINKEY.BSC_MAINNET]: '0x4e7f408be2d4e9d60f49a64b89bb619c84c7c6f5',
  //   [CHAINKEY.ETH_MAINNET]: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0x9e1028f5f1d5ede59748ffcee5532509976840e0',
  // },
  // KROM: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x55ff62567f09906a85183b866df84bf599a4bf70',
  //   [CHAINKEY.ETH_MAINNET]: '0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0xf98dcd95217e15e05d8638da4c91125e59590b07',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x14Af1F2f02DCcB1e43402339099A05a5E363b83c',
  // },
  // TCR: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0xa72159fc390f0e3c6d415e658264c7c4051e9b87',
  //   [CHAINKEY.ETH_MAINNET]: '0x9c4a4204b79dd291d6b6571c5be8bbcd0622f050',
  // },
  // PKEX: {
  //   coingecko: '[CHAINKEY.ETH_MAINNET]:0xe6f143a0e0a8f24f6294ce3432ea10fad0206920',
  //   shiden: '0xdc42728b0ea910349ed3c6e1c9dc06b5fb591f98',
  // },
  // ZLK: {
  //   coingecko: 'moonriver:0x0f47ba9d9bde3442b42175e51d6a367928a1173b',
  //   moonbeam: '0x3fd9b6c9a24e09f67b7b706d72864aebb439100c',
  //   moonriver: '0x0f47ba9d9bde3442b42175e51d6a367928a1173b',
  // },
  // PERL: {
  //   [CHAINKEY.BSC_MAINNET]: '0x0f9e4d49f25de22c2202af916b681fbb3790497b',
  //   [CHAINKEY.ETH_MAINNET]: '0xeca82185adce47f39c684352b0439f030f860318',
  // },
  // BMI: {
  //   [CHAINKEY.BSC_MAINNET]: '0x3e1b4Ff4AE3Ab8f0Cb40a34a6ad3fC817F7dA2b6',
  //   [CHAINKEY.ETH_MAINNET]: '0x725c263e32c72ddc3a19bea12c5a0479a81ee688',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x3e1b4Ff4AE3Ab8f0Cb40a34a6ad3fC817F7dA2b6',
  // },
  // JPEG: {
  //   avax: '0x6241af3817Db48a7F9E19FD9446d78E50936d275',
  //   [CHAINKEY.BSC_MAINNET]: '0x4bfce5a1acc3b847afa9579ba91da33b08e66fb7',
  //   [CHAINKEY.ETH_MAINNET]: '0x4bfce5a1acc3b847afa9579ba91da33b08e66fb7',
  // },
  // MASK: {
  //   [CHAINKEY.BSC_MAINNET]: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
  //   [CHAINKEY.ETH_MAINNET]: '0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074',
  // },
  // REEF: {
  //   [CHAINKEY.BSC_MAINNET]: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
  //   [CHAINKEY.ETH_MAINNET]: '0xFE3E6a25e6b192A42a44ecDDCd13796471735ACf',
  // },
  // THALES: {
  //   [CHAINKEY.ETH_MAINNET]: '0x8947da500Eb47F82df21143D0C01A29862a8C3c5',
  //   [CHAINKEY.OPTIMISTIC_MAINNET]: '0x217D47011b23BB961eB6D93cA9945B7501a5BB11',
  // },
  // TORN: {
  //   [CHAINKEY.BSC_MAINNET]: '0x1ba8d3c4c219b124d351f603060663bd1bcd9bbf',
  //   [CHAINKEY.ETH_MAINNET]: '0x77777FeDdddFfC19Ff86DB637967013e6C6A116C',
  // },
  // HUH: {
  //   [CHAINKEY.BSC_MAINNET]: '0xc15e89f2149bCC0cBd5FB204C9e77fe878f1e9b2',
  //   [CHAINKEY.ETH_MAINNET]: '0x86D49fbD3B6f989d641E700a15599d3b165002AB',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x08648471B5AAd25fEEeb853d6829048f3Fc37786',
  // },
  iZi: {
    [CHAINKEY.ARBITRUM_MAINNET]: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
    [CHAINKEY.BSC_MAINNET]: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
    [CHAINKEY.ETH_MAINNET]: '0x9ad37205d608B8b219e6a2573f922094CEc5c200',
  },
  // ESW: {
  //   astar: '0xb361DAD0Cc1a03404b650A69d9a5ADB5aF8A531F',
  //   aurora: '0xd2Fa7C9386040f260e3Ec934601982aD4Cd7902B',
  //   [CHAINKEY.ETH_MAINNET]: '0x5a75A093747b72a0e14056352751eDF03518031d',
  //   [CHAINKEY.POLYGON_MAINNET]: '0xd2A2a353D28e4833FAFfC882f6649c9c884a7D8f',
  //   shiden: '0xb4BcA5955F26d2fA6B57842655d7aCf2380Ac854',
  // },
  // ASVA: {
  //   [CHAINKEY.BSC_MAINNET]: '0xF7b6d7E3434cB9441982F9534E6998C43eEF144a',
  //   [CHAINKEY.POLYGON_MAINNET]: '0xE7E0bA6f84D843d17Cb8410810Bf3E8Bcda0caA1',
  // },
  // ANML: {
  //   [CHAINKEY.ETH_MAINNET]: '0x38B0e3A59183814957D83dF2a97492AED1F003e2',
  //   [CHAINKEY.POLYGON_MAINNET]: '0xEcc4176B90613Ed78185f01bd1E42C5640C4F09d',
  // },
  // GOVI: {
  //   [CHAINKEY.ARBITRUM_MAINNET]: '0x07E49d5dE43DDA6162Fa28D24d5935C151875283',
  //   [CHAINKEY.ETH_MAINNET]: '0xeEAA40B28A2d1b0B08f6f97bB1DD4B75316c6107',
  //   [CHAINKEY.POLYGON_MAINNET]: '0x43Df9c0a1156c96cEa98737b511ac89D0e2A1F46',
  // },
};
