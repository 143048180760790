import styled from 'styled-components';
import { Flex } from '@my/ui';
import WalletAccountInfo from './WalletAccount';
// import Balance from 'components/Balance';
import { useMemo } from 'react';
import SmallNav from '../Nav/SmallNav';
import { menuItemsDefault } from '../config';
import Asset from './Asset';
import { useVault } from 'state/vault/hooks';
import { useWeb3React } from '@web3-react/core';
import { useSafeContractState } from 'state/safeContract/hooks';

const UserWidget = ({ collapsed }) => {
  const { multiTokenConfig, multiToken } = useVault();
  const { account } = useWeb3React();
  const { safeAddress } = useSafeContractState();

  return useMemo(() => {
    return (
      <User>
        {collapsed ? (
          <SmallNav
            menuItems={menuItemsDefault}
            safeAddress={safeAddress}
            account={account}
            multiToken={multiToken}
            multiTokenConfig={multiTokenConfig}
            collapsed={collapsed}
          />
        ) : (
          <Flex alignItems="center">
            <Asset
              safeAddress={safeAddress}
              account={account}
              multiToken={multiToken}
              multiTokenConfig={multiTokenConfig}
              collapsed={false}
            />
            <WalletAccountInfo />
          </Flex>
        )}
      </User>
    );
  }, [collapsed, account, multiToken, multiTokenConfig, safeAddress]);
};
const User = styled(Flex)`
  flex-direction: column;
  align-items: end;
`;

export default UserWidget;
