import BigNumber from 'bignumber.js';
import AVaultPCS from 'config/abi/AVaultPCS.json';
import { VaultMatadateType } from 'state/types';
import { RPCApi } from 'server/RPCApi';
import { CHAINKEY } from 'config/constants/chain_config';
import { IVault } from '../types';
import { getFromaterParmas } from '../utils';

export const fetchMultiUserVaultsBalances = async (
  account: string,
  safeAddress: Record<string, Record<string, string>>,
  allVaultsData: VaultMatadateType,
) => {
  let obj = {};
  // for (const chain of Object.keys(allVaultsData)) {
  //   const _chainkey = allVaultsData[chain];
  //   const _dapp = _chainkey.dapp;
  //   const vaultsData: IVault[] = [];
  //   for (const dapp of _dapp) {
  //     vaultsData.concat(dapp.contract);
  //   }
  //   const otherAccount = safeAddress[account][_chainkey];
  //   const chainObj = await fetchMultiUserVaultsBalancesItem([account, otherAccount], vaultsData, chainkeyFromSource);
  //   for (let i = 0; i < _dapp.length; i++) {
  //     const contract = _dapp[i].contract;
  //     const start = i * contract.length;
  //     const end = start + contract.length;
  //     for (const key of Object.keys(chainObj)) {
  //       obj[chain].dapp[i][key] = chainObj[key].slice(start, end);
  //     }
  //   }
  // }
  const result = await Promise.all(
    Object.keys(allVaultsData).map((chain) => {
      const chainkey = chain as CHAINKEY;
      const _chain = allVaultsData[chain];
      const _dapp = _chain.dapp;
      const { obj: _obj, vaultsData } = getFromaterParmas({ obj, dapps: _dapp, chain });
      obj = _obj;
      const otherAccount = safeAddress[account][chainkey];
      return fetchMultiUserVaultsBalancesItem([account, otherAccount], vaultsData, chainkey);
    }),
  );
  Object.keys(allVaultsData).map((chain: string, index: number) => {
    const chainObj = result[index];
    const _chain = allVaultsData[chain];
    const _dapp = _chain.dapp;
    let start = 0;
    let end = 0;
    for (let i = 0; i < _dapp.length; i++) {
      const dappname = _dapp[i].dappname;
      start = end;
      const { len } = getFromaterParmas({ obj, dapps: _dapp, chain });
      end = start + len[i][1];
      const keyArr = Object.keys(chainObj);
      for (let ei = 0; ei < keyArr.length; ei++) {
        const key = keyArr[ei];
        const preItem = obj[chain][dappname] && obj[chain][dappname] ? obj[chain][dappname] : {};
        obj[chain][dappname] = {
          ...preItem,
          [key]: chainObj[key].slice(start, end),
        };
      }
    }
    return obj;
  });
  return obj;
};
const fetchMultiUserVaultsBalancesItem = async (account: string[], vaults: IVault[], chain: CHAINKEY) => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCallsArr = {};
  for (let i = 0; i < account.length; i++) {
    vaultCallsArr[account[i]] = vaults.map((vault: IVault) => {
      return {
        address: vault.contractAddress,
        name: 'balanceOf',
        params: [account[i]],
      };
    });
  }
  const vaultCall = [...Object.values(vaultCallsArr)].flat(2);

  // console.log({ vaultCall, chain });
  // @ts-ignore
  const result = await rpcApi.multicall(AVaultPCS, vaultCall);
  const obj = {};
  //
  for (let i = 0; i < account.length; i++) {
    const start = i * vaults.length;
    const end = start + vaults.length;
    obj[`${account[i]}`] = result.slice(start, end).map((v) => new BigNumber(v).toJSON());
  }
  // {
  //     "userVaultBalances": {
  //       "arbitrum": {
  //           "AAVE": {
  //               "0x2E1C9Adc548963273d9e767413403719019bd639": [
  //                   "0",
  //                   "0"
  //               ],
  //               "0x4cE4CAc899b8B33eA75D93e780FA4dD1a74954b4": [
  //                   "0",
  //                   "0"
  //               ]
  //           }
  //       }
  //   }
  // }
  return obj;
};
