import { Button, useWalletModal } from '@my/ui';
import useAuth from 'hooks/useAuth';
import { useGlobalState } from 'state/global/hooks';
import styled from 'styled-components';
const ButtonStyled = styled(Button)`
  border: none;
  height: 28px;
  background-image: linear-gradient(90deg, #a428d0 0%, #20d4a9 100%);
  color: ${({ theme }) => theme.colors.text};
  border-radius: 8px;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 36px;
    width: 134px;
    border-radius: 12px;
    font-size: 14px;
  }
`;
const ConnectWalletButton = (props) => {
  const { chainkey } = useGlobalState();
  const { login, logout } = useAuth({ chainkey });
  const { onPresentConnectModal } = useWalletModal(login, logout);
  // const { toastError } = useToast();
  // const ConnectWallet = useCallback(() => {
  //   const chainId = window.ethereum.networkVersion;
  //   const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
  //   if (!connectorId) {
  //     if (chainId && Number(chainId) !== myChainId) {
  //       toastError('Wrong Network', 'Please change to astar Network');
  //       return;
  //     }
  //     onPresentConnectModal();
  //   }
  //   // eslint-disable-next-line
  // }, [onPresentConnectModal]);
  return (
    <ButtonStyled variant="tertiary" onClick={onPresentConnectModal} {...props}>
      Connect Wallet
    </ButtonStyled>
  );
};

export default ConnectWalletButton;
