import { getBridgeImage, getImageUrlFromToken } from 'utils';
import { IShowStatusModal } from '../ShowStatusModal';
import { format } from 'date-fns';
import { memo } from 'react';
import LoadingIcon from 'components/svg/loading';
import SuccessIcon from 'components/svg/SuccessIcon';
import { ErrorIcon } from '@my/ui';
const BigItem = ({ index, onPressModal, item }: { index: number; onPressModal: any; item: IShowStatusModal }) => {
  return (
    <tr key={index} onClick={(e) => onPressModal(index, e)}>
      <td>
        <div className="flex">
          <img src={getImageUrlFromToken(item.fromtoken, item.fromchain)} alt={item.fromtoken} />
          <div className="fl">
            <h3>
              {item.startvalue}
              {item.fromtokensymbol}
            </h3>
            <p>On {item.fromchain}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="flex">
          <img src={getImageUrlFromToken(item.totokenaddress, item.tochain)} alt={item.totokenaddress} />
          {/* <TokenWrapperComp
            vault={{
              token0address: item.token0address,
              token1address: item.token1address,
              wantaddress: item.totokenaddress,
              wantaddresssymbol: item.totokensymbol,
              vaulttype: item.vaulttype as IVaultType,
              chain: item.tochain as CHAINKEY,
            }}
          /> */}
          <div className="fl">
            <h3>
              {/* {item.endvalue} -*/}
              {item.toaddressusdcsymbol}
            </h3>
            <p>On {item.tochain}</p>
          </div>
        </div>
      </td>
      <td>
        {item.fromchain !== item.tochain ? (
          <div className="flex bridge">
            <img src={getBridgeImage(item.bridgekey)} alt={item.bridgekey} />
            <h4>{item.bridgename}</h4>
          </div>
        ) : (
          '-'
        )}
      </td>
      <td>
        {item.fromtx === 'FAILED' || Number(item.status) === 1000 ? (
          <div className="flex card card_grey">
            <ErrorIcon width={16} className="grey_err_path" />
            <p>Error</p>
          </div>
        ) : item.fromchain !== item.tochain && Number(item.status) !== 999 ? (
          <div className="flex card card_green">
            <LoadingIcon width={20} />
            <p>Pending</p>
          </div>
        ) : (
          <div className="flex card card_grey">
            <SuccessIcon width={16} />
            <p>Completed</p>
          </div>
        )}
      </td>
      {/* <td>
        <div className="tx_list">
          <a
            href={getLink(item.fromtx || item.from_tx, 'transaction', item.fromchain)}
            title={item.fromchain}
            target="_blank"
            rel="noreferrer"
          >
            {subStringAccount(item.fromtx)}
            <IconLink />
          </a>
        </div>
      </td>
      <td>
        <div className="tx_list">
          {item.totx && item.totx !== item.fromtx ? (
            <a
              href={getLink(item.totx, 'transaction', item.tochain)}
              title={item.tochain}
              target="_blank"
              rel="noreferrer"
            >
              {subStringAccount(item.totx)}
              <IconLink />
            </a>
          ) : (
            '-'
          )}
        </div>
      </td> */}
      <td>
        <p>{format(new Date(item.createatutctime), 'HH:mm')}</p>
        <p>{format(new Date(item.createatutctime), 'yyyy-MM-dd')}</p>
      </td>
    </tr>
  );
};
export default memo(BigItem);
