import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { State } from 'state/types';
import { getChainKeyState } from '.';
import { GlobalState } from './types';

export const useGlobal = () => {
  const _e = window.ethereum?.networkVersion;
  const [chainId, setChainId] = useState(window.ethereum?.networkVersion || '');
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setChainId(_e);
  }, [_e]);
  useEffect(() => {
    if (dispatch && (account || chainId)) {
      dispatch(getChainKeyState());
    }
    // else {
    //   setTimeout(() => {
    //     setChainId(chainId);
    //   }, 0);
    // }
  }, [dispatch, account, chainId]);
};
export const useGlobalState = (): GlobalState => {
  const global = useSelector((state: State) => state.global);
  // console.log(global);
  return global;
};
