import { CHAINKEY } from 'config/constants/chain_config';
import { IMultiTokenItem } from 'state/types';
import { ITradeConfigItem } from 'state/types';
import { FC, memo, useEffect, useMemo } from 'react';
import { getBridgeImage, getChainImage, getDappImage, getImageUrlFromToken } from 'utils';
import BigNumber from 'bignumber.js';
import SizedImg from './sizedImg';
import { ITradeType } from '../constants';
import { IShowStatusModal } from 'views/TransactionList/VaultMultiTransactionList/ShowStatusModal';
import { STATIC_BASE_URL } from 'config';
interface ILineType {
  fromchain: CHAINKEY;
  fromtoken: IMultiTokenItem;
  // chosedVault: IVault;
  totokenaddress: string;
  totokensymbol: string;
  tochain: CHAINKEY;
  todappname?: string;
  fromdappname?: string;
  swapConfig: ITradeConfigItem;
  bridgeConfig: ITradeConfigItem;
  tradetype: ITradeType;
  value: string;
  toTokenList: IMultiTokenItem[];
  label: string;
  collapsed: boolean;

  startvalue?: string;
  type?: ITradeType;
  endvalue?: string;
  swapendvalue?: string;
  bridgeendvalue?: string;
  toaddressusdc?: string;
  gasfee: string;
  bridgeItem: IShowStatusModal;
  setBridgeItemCal: any;
}
const LineType: FC<ILineType> = ({
  value,
  fromchain,
  fromtoken,
  // chosedVault,
  swapConfig,
  bridgeConfig,
  tradetype,
  toTokenList,
  label,
  collapsed,
  tochain,
  totokenaddress,
  totokensymbol,
  todappname,
  fromdappname,
  gasfee,
  bridgeItem,
  setBridgeItemCal,
}) => {
  const mTradeType = useMemo(() => {
    // return ITradeType.type_3;
    if (!fromchain || !fromtoken || !tochain || !tradetype) {
      return null;
    }
    if (fromchain === tochain) {
      if (
        fromtoken.address === totokenaddress ||
        (fromtoken.symbol === 'ETH' && totokensymbol === 'WETH') ||
        (fromtoken.symbol === 'ETH' && totokensymbol === 'ETH') ||
        (fromtoken.symbol === 'USDC' && totokensymbol === 'USDC')
      ) {
        return ITradeType.type_0;
      }
      return ITradeType.type_1;
    }
    if (fromtoken.address === totokenaddress) {
      return ITradeType.type_2;
    }
    return tradetype as ITradeType;
  }, [fromchain, fromtoken, tradetype, tochain, totokenaddress, totokensymbol]);
  // console.log({ tradetype, mTradeType });
  const { endvalue, swapendvalue, bridgeendvalue } = useMemo(() => {
    const persent = 0.99;
    let endvalue = '0';
    let swapendvalue = '0';
    let bridgeendvalue = '0';
    if (!value) {
      return { endvalue, swapendvalue, bridgeendvalue };
    }
    endvalue = new BigNumber(value).times(persent).toString();
    if (mTradeType === ITradeType.type_0) {
      return { endvalue, swapendvalue, bridgeendvalue };
    }
    const localSwapFee = new BigNumber(value).times(swapConfig.percent).plus(swapConfig.base_fee).toNumber();
    const swapFee = Math.max(localSwapFee, Number(swapConfig.min_fee));

    const localBridgeFee = new BigNumber(value).times(bridgeConfig.percent).plus(bridgeConfig.base_fee).toNumber();
    const bridgeFee = Math.max(localBridgeFee, Number(bridgeConfig.min_fee));

    if (mTradeType === ITradeType.type_2) {
      endvalue = bridgeendvalue = new BigNumber(endvalue).minus(bridgeFee).toString();
      endvalue = swapendvalue = new BigNumber(endvalue).minus(swapFee).toString();
    } else {
      endvalue = swapendvalue = new BigNumber(endvalue).minus(swapFee).toString();
      endvalue = bridgeendvalue = new BigNumber(endvalue).minus(bridgeFee).toString();
    }
    if (new BigNumber(endvalue).lt(0)) {
      endvalue = '0';
    }
    if (new BigNumber(swapendvalue).lt(0)) {
      swapendvalue = '0';
    }
    if (new BigNumber(bridgeendvalue).lt(0)) {
      bridgeendvalue = '0';
    }
    return { endvalue, swapendvalue, bridgeendvalue };
  }, [value, bridgeConfig, swapConfig, mTradeType]);
  const toaddressusdcsymbol = useMemo(() => {
    return fromtoken.symbol === 'ETH' ? 'ETH' : 'USDC';
  }, [fromtoken?.symbol]);
  const toaddressusdc = useMemo(() => {
    return toTokenList.filter((v) => v.symbol === toaddressusdcsymbol)[0].address;
  }, [toTokenList, toaddressusdcsymbol]);
  useEffect(() => {
    if (
      !(
        mTradeType ||
        bridgeConfig ||
        bridgeendvalue ||
        endvalue ||
        fromchain ||
        fromtoken ||
        gasfee ||
        setBridgeItemCal ||
        swapConfig.name ||
        swapendvalue ||
        toaddressusdc ||
        tochain ||
        todappname ||
        totokenaddress ||
        totokensymbol ||
        tradetype ||
        value
      ) ||
      (bridgeItem &&
        bridgeItem.tradetype === mTradeType &&
        bridgeItem.fromchain === fromchain &&
        bridgeItem.tochain === tochain)
    ) {
      return null;
    }
    if (setBridgeItemCal) {
      setBridgeItemCal(
        {
          fromchain: fromchain,
          tochain: tochain,
          fromtoken: fromtoken.address,
          fromtokensymbol: fromtoken.symbol,
          toaddressusdc: toaddressusdc,
          toaddressusdcsymbol: toaddressusdcsymbol,
          toDapp: todappname,
          totokensymbol: totokensymbol,
          totokenaddress: totokenaddress,
          startvalue: value,
          endvalue: endvalue,
          swapendvalue: swapendvalue,
          bridgeendvalue,
          // createatutctime: ,
          bridgekey: bridgeConfig.key,
          bridgename: bridgeConfig.name,
          swapname: swapConfig.name,
          // token0address: '0xaeaaf0e2c81af264101b9129c00f4440ccf0f720',
          // token1address: '0xaeaaf0e2c81af264101b9129c00f4440ccf0f720',
          tradetype: mTradeType,
          // vaulttype: 1,
          vaultaddress: totokenaddress,
          // status: 0, //  0 start  1 step   2 st...   999 end
          fromtx: '',
          totx: '',
          gastotalfee: gasfee,
          arrivaltotaltime: '-',
          gasfee: gasfee,
        },
        true,
      );
    }
  }, [
    toaddressusdcsymbol,
    bridgeItem,
    mTradeType,
    bridgeConfig,
    bridgeendvalue,
    endvalue,
    fromchain,
    fromtoken,
    gasfee,
    setBridgeItemCal,
    swapConfig.name,
    swapendvalue,
    toaddressusdc,
    tochain,
    todappname,
    totokenaddress,
    totokensymbol,
    tradetype,
    value,
  ]);
  if (!mTradeType) {
    return null;
  }
  // const gasfee = '12';
  // const time = '2';
  return (
    <>
      <div className="line_bg">
        <LineComp
          collapsed={collapsed}
          tradetype={mTradeType}
          startvalue={value}
          fromchain={fromchain}
          fromtokensymbol={fromtoken.symbol}
          fromtoken={fromtoken.address}
          totokenaddress={totokenaddress}
          totokensymbol={totokensymbol}
          toaddressusdc={toaddressusdc}
          toaddressusdcsymbol={toaddressusdcsymbol}
          tochain={tochain}
          todappname={todappname}
          fromdappname={fromdappname}
          label={label}
          endvalue={endvalue}
          swapname={swapConfig.name}
          swapendvalue={swapendvalue}
          bridgekey={bridgeConfig.key}
          bridgename={bridgeConfig.name}
          bridgeendvalue={bridgeendvalue}
        />
        {/* {collapsed ? (
          <div className="card_label small">
            <p>Gas Fee: ${gasfee}</p>
            <p>Arrival time: ~{time}m</p>
          </div>
        ) : null} */}
      </div>
      {/* {collapsed ? null : (
        <div className="card_label">
          <p>Gas Fee: ${gasfee}</p>
          <p>Arrival time: ~{time}m</p>
        </div>
      )} */}
    </>
  );
};
export interface ILineCompProps {
  tradetype: ITradeType;
  startvalue: string;
  fromtoken: string;
  fromchain: CHAINKEY;
  fromtokensymbol: string;
  totokenaddress: string;
  totokensymbol: string;
  toaddressusdc: string;
  toaddressusdcsymbol: string;
  tochain: CHAINKEY;
  todappname?: string;
  fromdappname?: string;
  label: string;
  endvalue: string;
  swapname: string;
  swapendvalue: string;
  bridgekey: string;
  bridgename: string;
  bridgeendvalue: string;
  collapsed: boolean;
}
export const LineComp: FC<ILineCompProps> = ({
  tradetype,
  startvalue,
  fromtoken,
  fromchain,
  fromtokensymbol,
  totokenaddress,
  totokensymbol,
  toaddressusdc,
  toaddressusdcsymbol,
  tochain,
  todappname,
  fromdappname,
  label,
  endvalue,
  swapname,
  swapendvalue,
  bridgekey,
  bridgename,
  bridgeendvalue,
  collapsed,
}) => {
  // console.log({ tradetype, todappname, fromdappname });

  if (tradetype === ITradeType.type_0) {
    if (!collapsed) {
      return (
        <div className="type_0">
          <div className="type_inner">
            <ChainCard value={startvalue} currency={fromtokensymbol} chain={fromchain} dapp={fromdappname} />
            <LabelCard label={label} />
            <ChainCard value={endvalue} currency={totokensymbol} chain={tochain} dapp={todappname} />
          </div>
          <div className="bg">
            <div className="bg_line">
              <div className="bg_line_inner"></div>
            </div>
            <div className="bg_arrow_right"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="type">
          <div className="type_inner_big_top">
            <div className="type_inner_big_top_bg">
              <ChainCard value={startvalue} currency={fromtokensymbol} chain={fromchain} dapp={fromdappname} />
              <ChainCard value={endvalue} currency={totokensymbol} chain={tochain} dapp={todappname} />
            </div>
            {collapsed ? null : <div className="bg_arrow_left"></div>}
          </div>
          <div className="type_inner_big_bottom center">
            <LabelCard label={label} />
          </div>
          <div className="bg center">
            <div className="bg_line">
              <div className="bg_line_inner"></div>
            </div>
            {collapsed ? <div className="bg_arrow_top"></div> : null}
          </div>
        </div>
      );
    }
  }
  if (tradetype === ITradeType.type_1) {
    return (
      <div className="type">
        <div className="type_inner_big_top">
          <div className="type_inner_big_top_bg">
            <ChainCard value={startvalue} currency={fromtokensymbol} chain={fromchain} dapp={fromdappname} />
            <ChainCard value={endvalue} currency={totokensymbol} chain={tochain} dapp={todappname} />
          </div>
          {collapsed ? null : <div className="bg_arrow_left marginleft20"></div>}
        </div>
        <div className="type_inner_big_bottom 11">
          <SwapCard
            fromchain={fromchain}
            fromAddress={fromtoken}
            tochain={tochain}
            toaddressusdc={toaddressusdc}
            label={swapname}
          />
          <TokenCard chain={tochain} address={toaddressusdc} value={swapendvalue} currency={toaddressusdcsymbol} />
        </div>
        <div className="bg">
          <div className="bg_line">
            <div className="bg_line_inner"></div>
          </div>
          {collapsed ? <div className="bg_arrow_top"></div> : null}
        </div>
      </div>
    );
  }
  if (tradetype === ITradeType.type_2) {
    return (
      <div className="type">
        <div className="type_inner_big_top">
          <div
            className={`type_inner_big_top_bg ${
              label.toLowerCase() === 'withdraw' ? 'type_inner_big_top_bg_layZero' : ''
            }`}
          >
            <ChainCard value={startvalue} currency={fromtokensymbol} chain={fromchain} dapp={fromdappname} />
            {label.toLowerCase() === 'withdraw' ? (
              <div className="layZero">
                <div className="layZero_bg">
                  <div className="layZero_bg_inner">
                    <SizedImg src={getChainImage('layerzero')} alt="layerzero" />
                  </div>
                </div>
                <SizedImg src={`${STATIC_BASE_URL}/images/arrow01.svg`} alt="arrow" />
              </div>
            ) : null}
            <ChainCard value={endvalue} currency={totokensymbol} chain={tochain} dapp={todappname} />
          </div>
          {collapsed ? null : <div className="bg_arrow_left"></div>}
        </div>
        <div className="type_inner_big_bottom 22">
          <BridgeCard bridgekey={bridgekey} bridgename={bridgename} />
          <TokenCard chain={tochain} address={toaddressusdc} value={bridgeendvalue} currency={toaddressusdcsymbol} />
        </div>
        <div className="bg">
          <div className="bg_line">
            <div className="bg_line_inner"></div>
          </div>
          {collapsed ? <div className="bg_arrow_top"></div> : null}
        </div>
      </div>
    );
  }

  if (tradetype === ITradeType.type_3) {
    return (
      <div className="type">
        <div className="type_inner_big_top">
          <div className="type_inner_big_top_bg">
            <ChainCard value={startvalue} currency={fromtokensymbol} chain={fromchain} dapp={fromdappname} />
            <ChainCard value={endvalue} currency={totokensymbol} chain={tochain} dapp={todappname} />
          </div>
          {collapsed ? null : <div className="bg_arrow_left"></div>}
          {collapsed ? null : (
            <TokenCard chain={tochain} address={toaddressusdc} value={swapendvalue} currency={toaddressusdcsymbol} />
          )}
        </div>
        <div className="type_inner_big_bottom 333">
          <BridgeCard bridgekey={bridgekey} bridgename={bridgename} />
          {collapsed ? null : (
            <TokenCard chain={tochain} address={fromtoken} value={bridgeendvalue} currency={fromtokensymbol} />
          )}
          {collapsed ? null : (
            <SwapCard
              fromchain={tochain}
              fromAddress={totokenaddress}
              tochain={tochain}
              toaddressusdc={toaddressusdc}
              label={swapname}
            />
          )}
          {collapsed ? (
            <TokenCard chain={tochain} address={toaddressusdc} value={swapendvalue} currency={toaddressusdcsymbol} />
          ) : null}
        </div>
        {collapsed ? (
          <div className="type_inner_big_bottom bottom2">
            <TokenCard chain={tochain} address={fromtoken} value={bridgeendvalue} currency={fromtokensymbol} />
            <SwapCard
              fromchain={fromchain}
              fromAddress={totokenaddress}
              tochain={tochain}
              toaddressusdc={toaddressusdc}
              label={swapname}
            />
          </div>
        ) : null}
        <div className="bg">
          <div className="bg_line">
            <div className="bg_line_inner"></div>
          </div>
          {collapsed ? <div className="bg_arrow_top"></div> : null}
        </div>
      </div>
    );
  }
  if (tradetype === ITradeType.type_4) {
    return (
      <div className="type">
        <div className="type_inner_big_top">
          <div className="type_inner_big_top_bg">
            <ChainCard value={startvalue} currency={fromtokensymbol} chain={fromchain} dapp={fromdappname} />
            <ChainCard value={endvalue} currency={totokensymbol} chain={tochain} dapp={todappname} />
          </div>
          {collapsed ? null : <div className="bg_arrow_left"></div>}
          {collapsed ? null : (
            <TokenCard chain={tochain} address={toaddressusdc} value={bridgeendvalue} currency={fromtokensymbol} />
          )}
        </div>
        <div className="type_inner_big_bottom 44">
          <SwapCard
            fromchain={fromchain}
            fromAddress={fromtoken}
            tochain={tochain}
            toaddressusdc={toaddressusdc}
            label={swapname}
          />
          <TokenCard chain={tochain} address={toaddressusdc} value={swapendvalue} currency={toaddressusdcsymbol} />
          {collapsed ? (
            <TokenCard chain={tochain} address={toaddressusdc} value={bridgeendvalue} currency={fromtokensymbol} />
          ) : null}
        </div>
        {collapsed ? (
          <div className="type_inner_big_bottom bottom2">
            <BridgeCard bridgekey={bridgekey} bridgename={bridgename} />
          </div>
        ) : null}
        <div className="bg">
          <div className="bg_line">
            <div className="bg_line_inner"></div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const ChainCard = ({
  value,
  currency,
  chain,
  dapp,
}: {
  value: string;
  currency: string;
  chain: string;
  dapp?: string;
}) => {
  return (
    <div className="chain_card">
      <SizedImg src={getChainImage(chain)} alt={chain} />
      <div className="fr">
        <h4>
          {/* {value} */}
          {currency}
        </h4>
        <p>
          On {chain}
          {dapp ? <SizedImg src={getDappImage(dapp)} alt={dapp} /> : null}
        </p>
      </div>
    </div>
  );
};

const LabelCard = ({ label }) => {
  return <p className="label_card">{label}</p>;
};
const SwapCard = ({
  fromchain,
  fromAddress,
  tochain,
  toaddressusdc,
  label,
}: {
  fromchain: CHAINKEY;
  fromAddress: string;
  tochain: CHAINKEY;
  toaddressusdc: string;
  label: string;
}) => {
  return (
    <div className="label_card ">
      <SizedImg src={getImageUrlFromToken(fromAddress, fromchain)} alt={fromAddress} />
      <SizedImg src={getImageUrlFromToken(toaddressusdc, tochain)} alt={toaddressusdc} />
      <p>{label}</p>
    </div>
  );
};
const TokenCard = ({
  chain,
  address,
  value,
  currency,
}: {
  chain: string;
  address: string;
  value: string;
  currency: string;
}) => {
  return (
    <div className="label_card">
      <SizedImg src={getImageUrlFromToken(address, chain)} alt={address} />
      <div>
        <p>
          {/* {value} */}
          {currency}
        </p>
        <p>On {chain}</p>
      </div>
    </div>
  );
};
const BridgeCard = ({ bridgekey, bridgename }: { bridgekey: string; bridgename: string }) => {
  return (
    <div className="label_card">
      <SizedImg src={getBridgeImage(bridgekey)} alt={bridgename} />
      <p>{bridgename}</p>
    </div>
  );
};

export default memo(LineType);
