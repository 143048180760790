import BigNumber from 'bignumber.js';
import { CHAINKEY } from 'config/constants/chain_config';
import { IStargateRouterABI } from 'config/vault/abi';
import { stargateRouterAddress } from 'config/vault/address';
import { RPCApi } from 'server/RPCApi';

export enum IGetFeeFunctionType {
  TYPE_SWAP_REMOTE = 1,
  TYPE_ADD_LIQUIDITY = 2,
  TYPE_REDEEM_LOCAL_CALL_BACK = 3,
  TYPE_WITHDRAW_REMOTE = 4,
}
export async function getCrossChainSwapFee({
  fromchain,
  dstChainId,
  getFeeFunctionType,
  toAddress,
  payload,
  _dstGasForCall,
}: {
  fromchain: CHAINKEY;
  dstChainId: number;
  getFeeFunctionType: IGetFeeFunctionType;
  toAddress: string;
  payload: string;
  _dstGasForCall: string;
}) {
  const rpcApi = new RPCApi({
    chainkey: fromchain,
  });
  // console.log(1, fromchain, stargateRouterAddress[fromchain], dstChainId);
  if (stargateRouterAddress[fromchain] && dstChainId) {
    // console.log(3);
    const vaultCall = [
      {
        address: stargateRouterAddress[fromchain],
        name: 'quoteLayerZeroFee',
        params: [
          dstChainId, // destination chainId
          getFeeFunctionType, // function type: see Bridge.sol for all types
          toAddress, // destination of tokens
          payload, // payload, using abi.encode()
          {
            dstGasForCall: _dstGasForCall, // extra gas, if calling smart contract,
            dstNativeAmount: 0, // amount of dust dropped in destination wallet
            dstNativeAddr: '0x00', // destination wallet for dust
          },
        ],
      },
    ];
    // console.log({ vaultCall });
    const result = await rpcApi.multicall(IStargateRouterABI, vaultCall);
    const zeroFee = result[0][0].toString();
    const rs = new BigNumber(zeroFee).toString();
    // console.log({ rs });
    return rs;
  } else {
    return '0';
  }
}
